import React from 'react';
import { SHOPPING_METADATA_LIST, SHOPPING_ADMIN_LIST, UPDATE_SHOPPING_AREA, GET_CONFIG, UPDATE_CONFIG } from "../../urlConstants";
import restUtils from "../../utils/restUtils";
import { getSelect, getTextNameField, getDatePicker, getTableRow, getTable, getButton } from "../common/ComponentUtilities";
import {getExcelUploadField} from '../common/CustomComponents';
import "../Orders/PromoShip/styles.css";
import { Loader } from "semantic-ui-react";
import Bin from '../../images/bin.svg';
import renderImages from '../common/RenderImages';
import "./admin.css";
import _ from 'lodash'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import utilities from '../../utils/utilities';
import { downloadExcel, readExcelData } from '../common/GenericExcelExport/exportUtils';
export const SHOPPING_AREA_REQ_FIELDS = ['shoppingName','endDate','isAttendanceReq','shoppingMetadataId','showTimer','startDate','timeZone'];
export const SHOPPING_AREA_TEMPLATE_NAME = 'ShoppingAreaTemplate';
export const SHOPPING_AREA_TEMPLATE = [{'Event Name':'', 'Display Name':'', 'Start Date':'','End Date':'','Time Zone':'','Attendance Required':'','Show Timer':'','Logo':''}];
export const columnDefs = {0:'shoppingMetadataId', 1: 'shoppingName', 2:'startDate', 3:'endDate', 4:'timeZone' , 5:'isAttendanceReq', 6:'showTimer',7:'imgUrl'};

class ShoppingArea extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      metadata: [],
      rows: [],
      loading: false,
      reunion:{},
      fileName:''
    };
    this.file = '';
  }

  componentDidMount() {
    this.setState({ loading: true,disableSave : true });
    restUtils.getData(SHOPPING_METADATA_LIST)
      .then(response => {
        const metadata = response.data.map(item => ({ text: item.shoppingMetaName, props: { value: item.shoppingMetaId, id: item.shoppingMetaId } }));
        metadata.unshift({ text: "Select...", props: { value: undefined, id: "default" } });
        this.setState({ metadata }, () => this.getShoppingAreas());
      })
      .catch(error => console.log(error))
      .finally(this.setState({ loading: false }));
      this.getReunion();
  }

  getReunion = () => {
    this.setState({ loading: true });
    const url = GET_CONFIG + "?configKey=REUNION";
    restUtils.getData(url)
    .then(response => {
      const reunionData = Array.isArray(response.data) ? response.data[0] : ''
      this.setState({reunion: reunionData })
    })
    .catch(error => console.log(error))
    .finally(this.setState({ loading: false }))
  }

  getShoppingAreas = () => {
    this.setState({ loading: true, disableSave : true });
    restUtils.getData(SHOPPING_ADMIN_LIST)
      .then(response => {
        const rows = response.data;
        const {metadata} = this.state;
        rows.forEach(element => {
          element["metadata"] = metadata;
        });
        this.setState({ rows,loading: false, disableSave : false });
      })
      .catch(error => console.log(error))
      .finally(console.log("done"));
  }

  getShoppingAreaHeaders = () => {
    const tableHeader = [
      { props: { className: 'allOdrHeader', id: 'no_border' }, text: 'Event Name' },
      { props: { className: 'allOdrHeader', id: 'no_border' }, text: 'Display Name' },
      { props: { className: 'allOdrHeader', id: 'no_border' }, text: 'Start Date' },
      { props: { className: 'allOdrHeader', id: 'no_border' }, text: 'End Date' },
      { props: { className: 'allOdrHeader', id: 'no_border' }, text: 'Time Zone' },
      { props: { className: 'allOdrHeader', id: 'no_border' }, text: 'Attendance Required' },
      { props: { className: 'allOdrHeader', id: 'no_border' }, text: 'Show Timer' },
      { props: { className: 'allOdrHeader', id: 'title_width_logo' }, text: 'Logo' },
      { props: { className: 'allOdrHeader', id: 'title_width' }, text: '' },
      //{ props: { className: 'allOdrHeader', id: 'title_width' }, text: '' }
    ];
    return (getTableRow({}, tableHeader, true))
  }

  getShopingAreaRow = (clas,row, index) => {
    if(row.status != "D"){
      const tableDataCols = this.buildRowData(row, index);
      //let promoClassname = (promoShipOrderList?.orderList?.length === index+1) ? '' : 'promoRowdisable';
      return (
        <>        
          {getTableRow({}, tableDataCols, false, 'tableRow')}
        </>
      )
    }
  }

  textOnChange = (e) => {
    this.updateRowState(e.target);
  }

  datePickerOnChange = (id,value) => {
    if(!value || value.length === 0) {
      return;
    }    
    if(id === 'startDate' || id === 'endDate'){
      value = new Date(value).toLocaleString().replace(/[^ -~]/g,'');
      this.updateReunionState({id,value});
    }else{
      this.updateRowState({id,value});
    }
  }

  updateRowState = (target) => {
    const dat = target.id.split("-");
    const {rows} = this.state; 
    let value =  target.value;   
    if(dat[1] === 'startDate' || dat[1] === 'endDate')
    {
      value = new Date(target.value).toLocaleString().replace(/[^ -~]/g,'');
    }
    rows[parseInt(dat[0])][dat[1]] = value;
    if (rows[parseInt(dat[0])]["status"] !== "I") {
      rows[parseInt(dat[0])]["status"] = "U";
    }
    this.setState({ rows });
  }

  updateReunionState = (target) => {
    const {reunion} = this.state;    
    reunion[target.id] = target.value;
    this.setState({ reunion });
  }

  rowTextComp = (id, value, fieldClass, loadMore) => {    
    return getTextNameField(id, id, value, this.textOnChange, undefined, fieldClass, false, loadMore);
  }

  rowDatePickerComp = (id, value,fieldClass, isLabel,fieldName) => {   
    return getDatePicker(fieldName, id, value ? new Date(value) : null, this.datePickerOnChange, undefined, fieldClass, isLabel);
  }

  loadMore = (e) => {
    const dat = e.target.id.split("-");
    const { rows, metadata } = this.state;
    if (parseInt(dat[0]) === rows.length - 1 && e.keyCode === 9) {      
      rows.push({metadata, status:'I'});
      this.setState({ rows });
    }
  };

  downloadTemplate = () => {
    downloadExcel(SHOPPING_AREA_TEMPLATE, `${SHOPPING_AREA_TEMPLATE_NAME}.xlsx`);
  }

  chooseFile = (e)=>{
    const files = e.target.files[0];
    let fileReader;
    const reader = new FileReader();
    reader.onload = (e) => { 
      this.file = reader.result;
    };
    reader.readAsBinaryString(files);
    this.setState({
      fileName: files.name
    });
  }

  uploadFile = (e) => {
    let fileType = this.state.fileName.split(/\.(?=[^.]+$)/);
    let {rows, metadata} = this.state;
    if(fileType[1] !== 'xlsx') {
      utilities.showToast('Kindly upload .xlsx file');
    }else {      
      this.setState({disableSave: true, loading: true});
      let data = readExcelData(this.file,{header:1,blankrows:false,raw:false,dateNF:'MM/dd/yy hh:mm:ss'});
      data.forEach((row,index) => {
        let newRow = {};
        if(index !== 0){
          Object.keys(row).forEach(key => {         
            let value = columnDefs[key] === 'showTimer' || columnDefs[key] === 'isAttendanceReq' ? row[key].toUpperCase() === 'YES' ? true : false : row[key];
            if(columnDefs[key] === 'shoppingMetadataId'){
              let shoppingMetaData = metadata.filter( meta => { return meta.text.toUpperCase() === row[key].toUpperCase()})
              value = shoppingMetaData.length > 0 ? shoppingMetaData[0].props.value : '';
            }
            newRow = {...newRow, ...{[columnDefs[key]] : columnDefs[key] === 'timeZone' ? value.toUpperCase() : value.toString().trim() }};
          });
          rows.push({...newRow, ...{metadata, status:'I'}});
        }
      });
      this.setState({
        rows,
        disableSave: false, 
        loading: false
      });
    }
  };

  buildRowData = (row, index) => {
    return (
      [
        { columnProps: { className: 't-center', id: 'field_width' }, columnText: this.getMetadataFields(`${index}-shoppingMetadataId`, row.shoppingMetadataId, row.metadata) },
        { columnProps: { className: 't-center', id: 'field_width' }, columnText: this.rowTextComp(`${index}-shoppingName`, row.shoppingName,'t-center text-admin') },
        { columnProps: { className: 't-center', id: 'field_width' }, columnText: this.rowDatePickerComp(`${index}-startDate`, row.startDate,'t-center date-admin') },
        { columnProps: { className: 't-center', id: 'field_width' }, columnText: this.rowDatePickerComp(`${index}-endDate`, row.endDate, 't-center date-admin') },
        { columnProps: { className: 't-center', id: 'field_width' }, columnText: this.getTimeZoneFields(`${index}-timeZone`, row.timeZone) },
        { columnProps: { className: 't-center', id: 'field_width' }, columnText: this.getAttendanceFields(`${index}-isAttendanceReq`, row.isAttendanceReq) },
        { columnProps: { className: 't-center', id: 'field_width' }, columnText: this.getTimer(`${index}-showTimer`, row.showTimer) },
        { columnProps: { className: 't-center', id: 'field_width' }, columnText: this.rowTextComp(`${index}-imgUrl`, row.imgUrl,'t-center text-admin', this.loadMore) },
        { columnProps: { className: 't-center', id: 'field_width' }, columnText: this.getDeleteIcon(`${index}-del`)}
      ]
    );
  }

  deleteShopping = (e) => {   
    const index = parseInt(e.target.id.split("-")[0]);
    const {rows} = this.state;
    if(rows[index]["status"] === "I") {
      rows.splice(index,1);
    } else {
      rows[index]["status"] = "D";
    }
    this.setState({ rows });    
  }

  getDeleteIcon = (id) => {
    return renderImages.getImage({ 
      id,
      src: Bin, 
      alt: 'bin', 
      className: 'delete-icon', 
      onClick: this.deleteShopping
    });
  }

  changeTimer = (e) => {
    this.updateRowState(e.target);
  }

  getTimer = (id, value) => {   
    const selectProps = { className: 'sort-criteria combo select-admin', onChange: this.changeTimer, id: id, value: value };
    const data = [
      { text: "Select...", props: { value: '', id: "default" } },
      { text: "Yes", props: { value: true, id: "Yes" } },
      { text: "No", props: { value: false, id: "No" } }
    ];
    return getSelect(selectProps, data);
  }

  changeAttendance = (e) => {
    this.updateRowState(e.target);
  }

  getAttendanceFields = (id, value) => {    
    const selectProps = { className: 'sort-criteria combo select-admin', onChange: this.changeAttendance, id: id, value: value };
    const data = [
      { text: "Select...", props: { value: '', id: "default" } },
      { text: "Yes", props: { value: true, id: "Yes" } },
      { text: "No", props: { value: false, id: "No" } }
    ];
    return getSelect(selectProps, data);
  }

  changeTimeZone = (e) => {
    this.updateRowState(e.target);
  }

  getTimeZoneFields = (id, value) => {    
    const selectProps = { className: 'sort-criteria combo select-admin', onChange: this.changeTimeZone, id: id, value: value };
    const data = [
      { text: "Select...", props: { value: '', id: "default" } },
      { text: "CST", props: { value: "CST", id: "CST" } }
    ];
    return getSelect(selectProps, data);
  }

  getMetadataFields = (id, value, metadata) => {    
    const selectProps = { className: 'sort-criteria combo select-admin', onChange: this.changeMetadata, id: id, value: value };
    return getSelect(selectProps, metadata);
  }

  changeMetadata = (e) => {
    this.updateRowState(e.target);
  }

  clickButton = () => {
    const { rows, metadata } = this.state;
    rows.push({metadata, status:"I"});
    this.setState({ rows });
  }

  validateRequiredField = (row) => {
    return SHOPPING_AREA_REQ_FIELDS.every(field=> _.has(row, field) &&
                  !utilities.isEmptyOrNullString(row[field]) && row[field] !== 'Select...');
  }

  saveShopping = () => {    
    const {rows} = this.state;
    let isValid = true;
    rows.forEach(row => {
      if((row.status === "I" || row.status === "U") &&
          !this.validateRequiredField(row)) {
        isValid = false;
        return;
      }       
    })
    if(!isValid){
      utilities.showToast(`Please enter all required fields`, true);
      return;
    }
    const saveData = rows.filter(row => {
      //if (row.status === "I" || row.status === "D" || row.status === "U") {
        //delete row["metadata"];
        return row.status === "I" || row.status === "D" || row.status === "U"
      //}
    });

    this.setState({ loading: true, disableSave : true });
    restUtils.putData(UPDATE_SHOPPING_AREA, saveData)
    .then(response => {
      utilities.showToast(`Shopping area definition updated successfully`);
      this.getShoppingAreas();
    })
    .catch(error => console.log(error))
    .finally(this.setState({ loading: false, disableSave : false }));
  }

  reunionChange = (e) => {
    console.log(e.target);
  }

  updateTimeZone = (e) => {
    console.log(e.target);
  }

  getTimeZone = (reunion) => {    
    const selectProps = { className: 'sort-criteria combo', onChange: this.updateTimeZone, id: "reTimeZone", value: reunion.timeZone };
    const data = [
      { text: "Select...", props: { value: undefined, id: "default" } },
      { text: "CST", props: { value: "CST", id: "CST" } }
    ];
    return getSelect(selectProps, data, true, undefined, "Time Zone");
  }

  saveReunion = () => {
    this.setState({ loading: true, disableSave : true });
    const { reunion } = this.state;
    reunion.lastUpdateBy = this.props.userName;
    restUtils.putData(UPDATE_CONFIG, this.state.reunion)
    .then(response => {
      utilities.showToast(`Market link setup updated successfully`);
      this.setState({loading: false,disableSave : false})})
    .catch(error => console.log(error))
    .finally(this.setState({ loading: false , disableSave : false }));
  }

  reunionLinkSetUp = (reunion) => {
    return (
    <div>
      <div className='displayFlex page-header mb-2'>
          Market Link Setup
      </div>
      <div className='displayFlex'>
        {this.rowDatePickerComp('startDate',reunion.startDate,'t-center datePicker', true,'Start Date')}
        {this.rowDatePickerComp('endDate',reunion.endDate,'t-center datePicker',true, 'End Date')}
      </div>
      <br/>
      <div className='displayFlex'> 
      {this.getTimeZone(reunion)}
      {getButton({type:'button', className:'ui red button place-order-btn reunionButton', name:'saveReunion', onClick:this.saveReunion, disabled:this.state.disableSave}, 'Save')} 
      </div>
    </div>
    );
  }

  uploadShoppingAreaList = (reunion) => {
    return (
    <div className='upload-link'>
      <div className='displayFlex page-header mb-2'>
        Shopping Area Setup
      </div>
      <div className='displayFlex'>
        {getExcelUploadField("Choose File", "upload-file", this.state.fileName , this.chooseFile, 'custom-input-btn', 'custom-file-input',this.downloadTemplate)}       
      </div>
      <div className='displayFlex pt-3'>
        <div className='pl-15'>
          {getButton({type:'button', className:'fluid ui red button submit', name:'uploadFile', onClick:this.uploadFile, disabled:this.state.disableSave}, 'Submit')}
        </div>
      </div>
    </div>
    );
  }

  render() {
    const { rows, loading, reunion } = this.state;

    return (
      <>       
        <div className='displayFlex'>          
          <div>
            {this.uploadShoppingAreaList(reunion)}
          </div>
          <div className='renunion-link'>
            {this.reunionLinkSetUp(reunion)}
          </div>
        </div>
        {loading && (
          <div style={{"padding":"2rem"}}>
            <Loader active />
          </div>
        )}
        <br/>
        <div className='overFlow'>
          {getTable({ className: "ui celled table all-wh-table shoppingArea-table", id: window.innerWidth > 1024 ? 'font-size-orders': 'admin_tbl' }, this.getShoppingAreaHeaders(), rows, this.getShopingAreaRow, { className: 'wh-row' })}
          {getButton({type:'button', className:'ui red button place-order-btn saveShoppingArea', name:'saveShp', onClick:this.saveShopping, disabled:this.state.disableSave}, 'Save')} 
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    storeId: state.SessionReducer.storeId,
    userName: state.SessionReducer.userName
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({}, dispatch)
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingArea);