import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Segment, Grid, Loader } from 'semantic-ui-react';
import {RETAILASSORTMENT} from '../../urlConstants';
import {restUtils} from '../../utils/restUtils';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ReunionOrdering } from '../Cart/actions';
import InternalServerError from '../ErrorPages/500_error_page';
import {  CTB_ASSORTMENT} from '../common/constants'

class RetailAssortmentLinks extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading : false,
      reatilAssortMentData : [],
      serverError : false,
      gridBucketSize : 5,
      ctbAssortment: this.props.match.path === CTB_ASSORTMENT ? true : false
    }

  }

  componentDidMount() {
    this.setState({ loading: true });
    localStorage.removeItem("isGlobal");
    if(this.state.ctbAssortment) {
      restUtils
    .getData(RETAILASSORTMENT+ `/flyout?type=CTB`)
    .then((response) => {
      const { data } = response;
      const bucketData = data || [];
    
      let ctbAllData = {
        "departmentId": "",
        "departmentName": "ALL"
      }
      bucketData.unshift(ctbAllData);
      const buckets = this.createBucketForRetailAssortMents(bucketData);
      this.setState({...this.state,
        reatilAssortMentData:buckets||[],
        loading: false,
        serverError: false
      })
    })
    .catch((error) => {
      console.log(error);
      this.setState({ loading: false, serverError: true});
    });
    }else {
    restUtils
    .getData(RETAILASSORTMENT+ `/flyout`)
    .then((response) => {
      const { data } = response;
      const bucketData = data || [];
      const buckets = this.createBucketForRetailAssortMents(bucketData);
      this.setState({...this.state,
        reatilAssortMentData:buckets||[],
        loading: false,
        serverError: false
      })
    })
    .catch((error) => {
      console.log(error);
      this.setState({ loading: false, serverError: true});
    });
  }
    this.props.ReunionOrdering();
  }

  createBucketForRetailAssortMents = ( bucketData )=>{
    let i = 0;
    const bucket = [];
    while(i<bucketData.length)
    {
      if(i+this.state.gridBucketSize <= bucketData.length )
      {
         bucket.push(bucketData.slice(i,i+this.state.gridBucketSize));
         i = i+this.state.gridBucketSize
      }
      else{
        bucket.push(bucketData.slice(i));
        i = i+this.state.gridBucketSize;
      }
    }
    return bucket;
  }

  render() {
    const { ctbAssortment } = this.state
    let showAssortmentList = true;
    if(ctbAssortment && !this.props.isCtbAssortmentsEnable){
      showAssortmentList = false
    } else if (!ctbAssortment && !this.props.isRetailAssortmentsEnable ){
      showAssortmentList = false
    }

    return (
      <Segment className='carousel_main' vertical>
        <Grid verticalAlign='middle' stackable columns={3}>        
            <Grid.Row columns={8}>
            <Grid.Column  width={1}></Grid.Column>
            <Grid.Column className="assortment_link_title text-align-left"  width={6}>
             <h2>{!ctbAssortment ? 'Retail Assortments': 'Customized True Blue Assortments'}</h2>
            {this.state.loading && (
              <div>
                <Loader active  className="loaderCenter"/>
              </div>
            )}
            </Grid.Column>
            </Grid.Row>
        </Grid>
        {
          showAssortmentList ?
          this.state.reatilAssortMentData.map((bucket)=>{
            return (
              <Grid verticalAlign='middle' className="assortment_padding_top" stackable columns={3}>
                 <Grid.Row columns={this.state.gridBucketSize} >
                 <Grid.Column  width={1}></Grid.Column>
                 <Grid.Column  width={14}>
                 <Grid verticalAlign='middle' stackable columns={this.state.gridBucketSize}>
                   {
                      bucket.map((bucketItem,index)=>{
                        let ctbItem = bucketItem.departmentName ==="ALL"? "" :  `(${bucketItem.departmentId})`;
                        return (
                          <Grid.Column className="assortment_link_border_box">
                          <Segment className='link-segment-height'>
                          {!ctbAssortment ? 
                           <Link to={`/retailAssortments/${encodeURI(bucketItem.departmentName)}`} className="assortment_link_color">{bucketItem.departmentName + ` (${bucketItem.departmentId})`}</Link>:
                           <Link to={{pathname : `/ctbAssortments/${encodeURI(bucketItem.departmentName)}`, state:{
                            "ctbAssortment" : ctbAssortment,
                            "isGlobal" : bucketItem.departmentName ==="ALL" ? "Y" : "N"
                            
                            }}} className="assortment_link_color">{bucketItem.departmentName + ctbItem}</Link>}
                          </Segment>
                          </Grid.Column >
                        )
                     })
                   }
                   </Grid>
                   </Grid.Column>
                 </Grid.Row>
              </Grid>
            )
          })
          :
          ( 
          <div className='no_prdcts_display'>
            <h1>{ctbAssortment ? 'CTB true blue assortments are not enabled' : 'Retail assortments are not enabled'}</h1>
          </div>
          )
        }
        
      </Segment>
    );
  }
}

const mapStateToProps = state => {
  const { cart } = state;
    return {
    isRetailAssortmentsEnable: cart?.orders?.isRetailAssortmentsEnable,
    isCtbAssortmentsEnable: cart?.orders?.isCtbAssortmentsEnable,
  }
}

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ ReunionOrdering }, dispatch)
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RetailAssortmentLinks);

