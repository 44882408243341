import React from 'react';
import './styles.css';
import { Loader, } from 'semantic-ui-react';
import InternalServerError from '../ErrorPages/500_error_page';
import renderImages from '../common/RenderImages';
import search_img from '../images/Search.svg';
import ReunionOfferListing from './ReunionOfferListing';
import ReunionEventVendors from './ReunionEventVendors';
import { connect } from 'react-redux'
import Categories from '../Categories';
import utilities from '../../utils/utilities';
import ReunionItemListing from './ReunionItemListing';
import BreadcrumbComp from '../../components/BreadcrumbComp';
import MonthlySpecialsEventListing from './MonthlySpecials/MonthlySpecialsEventListing';
import MonthlySpecialEventVendors from './MonthlySpecials/MonthlySpecialEventVendors';
import history from '../../history';

class ReunionEvent extends React.Component {
    constructor(props){
        super(props);
        const { path } = this.props.match;
        const listType = path === '/reunionEvent' ? 0
                                            :1;
        const eventId = (listType === 1) ? this.props.match.params.eventId : '';
        const event = props.reunionEvents && props.reunionEvents.find(event=>{
            return parseInt(event.promo_nbr)===parseInt(eventId);
        })
        this.state={
            listType:listType,
            eventId ,
            searchby : "itemnbr",
            searchvalue : '',
            dealDetails: false,
            eventIdReunion: event?event.relay_nbr:'',
            promoNmbr: event?event.promo_nbr:'',
            promoName: event?event.PrmoName:'',
            serverError: false,
            isLoading:false
        }
        this.searchParam = "itemnbr";
        this.searchValue = '';
        this.listTypes = {
            0:"Market Offerings",
            1:"Market Offering Vendors",
            2:"Items",
            3:"Search Market"
        }
    }

    componentWillReceiveProps(newProps){
        if( newProps.location && 
            newProps.location.state && 
            newProps.location.state.listType &&
            parseInt(newProps.location.state.listType) !== parseInt(this.state.listType)){
                const eventId =   newProps.location && 
                            newProps.location.state && 
                            newProps.location.state.eventId &&
                            parseInt(newProps.location.state.eventId) !==parseInt(this.state.eventId) ?
                            newProps.location.state.eventId
                            :this.state.eventId;
                this.setState({listType:newProps.location.state.listType,eventId})
            }
    }
    handleSearchValueChange = (e) =>{
        this.searchValue = e.target.value;
    }
    
    handleSearchParamChange = (e) =>{
        this.searchParam = e.target.value
    }

    handleEnterKey = (e) =>{
        if (e.keyCode === 13) {
            this.search();
            return;
        }
    }
    search = () =>{
        this.setState({
            searchby:this.searchParam,
            searchvalue : this.searchValue
        })
    }
    showDealDetails = () => this.setState({ dealDetails: true});

    DialogClose = () => this.setState({ dealDetails: false });

    setListType = (listType,vendorDetails=null) =>{
        let eventId = listType===1 || listType===5?
                        vendorDetails.eventId
                        :listType===2? vendorDetails.relayNbr:'';
        
        this.setState({
            listType,eventId,vendorDetails
        })
    }

    getHeader = () =>{
        const { vendorDetails } = this.state;
        switch (this.state.listType){
            case 0: return "Market Offerings";
            case 1: return "Market Offering Vendors";
            case 2: return "Market Offering - #"+utilities.emptyForNull(vendorDetails.relayNbr)+" - "+utilities.emptyForNull(vendorDetails.promoName);
            case 3: return "Market Offerings - Search";
            default : return "Market Offerings";
        }
    }

    getContent(){
        const { listType, searchby,searchvalue, } = this.state;
        switch(listType){
            case 0:
                    return <ReunionOfferListing setListType={this.setListType} searchby={searchby} searchvalue={searchvalue}/>
            case 1:
                return <ReunionEventVendors setListType={this.setListType} eventId={this.state.eventId}  searchby={searchby} searchvalue={searchvalue}/>
            case 2:
                return <Categories 
                            vendorDetails={this.state.vendorDetails} 
                            className='margin-top' 
                            isReunionListing={true} 
                            searchby={searchby} searchvalue={searchvalue}
                        />
            case 3:
                return <ReunionItemListing searchDetails={this.state.searchDetails}/>

            default : return <ReunionOfferListing setListType={this.setListType}/>
        }
    }
    render() {
        const content = this.getContent();
        const { listType, vendorDetails,eventIdReunion,promoName,promoNmbr } = this.state;
        let breadcrumbPath = [
            { link: '/home', text: 'HOME' },
            { link: '/reunionEvent', text: 'MARKET OFFERINGS' }
        ];
        if(listType===1){
            breadcrumbPath.push({
                link:'#',
                text : 'MARKET OFFERINGS VENDORS'
            })
        }else if(listType===2){
            breadcrumbPath=[ ...breadcrumbPath,
                {link:`/reunionEventVendors/${vendorDetails.promoNbr}`,onClick:()=>this.setListType(1,{eventId:vendorDetails.promoNbr}),text:'REUNION OFFERINGS VENDORS'},
                {link:'#',text:`${vendorDetails.promoName}`},
            ]
        }
        return (
            this.state.serverError ?
                <InternalServerError />
                :
                <div className="page-wrap">
                    {this.state.isLoading && (
                        <div>
                            <Loader active />
                        </div>
                    )}

                    <div className='page-header'>
                        {this.getHeader()}
                    </div>
                    <BreadcrumbComp path={breadcrumbPath}/>
                    {listType ===2 &&
                        <div className='monthlyFields'>
                            {vendorDetails.vendorName}&nbsp;&nbsp;
                            {`Order Deadline: ${vendorDetails.deadline}`}
                        </div>
                    }
                    <div className='ui input' id='input_search'>
                        {listType === 0 || listType === 1 || listType === 2 ?
                        <>
                            <span>
                                {window.innerWidth < 768 ? 'Search' : 'Market Search'}
                            </span>
                            <select
                                className='reunion-sort-criteria combo'
                                onChange={this.handleSearchParamChange}
                            >
                                <option value='itemnbr'>Item #</option>
                                <option value='vendor'>Vendor Name</option>
                                <option value='promo'>Promo Number</option>
                            </select> 
                            <input
                                id='search_reunion_offers'
                                onChange = {this.handleSearchValueChange}
                                onKeyDown={this.handleEnterKey}
                                type='text'
                            />
                            {renderImages.getImage({
                                src: search_img,
                                className: 'search_icon_address cursor_p',
                                onClick: this.search
                            })}
                        </>
                        : null
                        }
                    </div>
                    {content}
                </div>
        )
    }
}

const mapStateToProps = (state) =>{
    return {
        reunionEvents : state.ReunionReducer.events
    }
}

export default connect(mapStateToProps, null)(ReunionEvent);
