import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import renderImages from '../common/RenderImages';
import search_img from '../images/Search.svg';
import {
  Loader,
  Responsive,
  Pagination,
  Button,
  Icon,
  Segment,
  Popup,
  Grid
} from 'semantic-ui-react';
import MultistoreToCartModal from '../Cart/MultistoreToCartModal';
import {showItemCount, getPagination } from '../common/CustomComponents';
import VendorDetailsPopup from '../Vendors/component/vendor-details-popup';
import { fetchCategories } from './actions';
import { addToFavorites, removeFromFavorites, getFavoriteItems } from '../Favorites/actions';
import {
  addVendorToFavorites,
  removeVendorFromFavorites,
  getAllFavoriteVendors
} from '../Favorites/vendors/actions';
import { Link } from 'react-router-dom';
import {
  createNAddMany,
  addProduct,
  createNAddCart,
  createDirectNAddCart,
  addManyProducts,
  chooseCart,
  addItemstoCartPlanogram,
  addReunionCartProduct,
  addAssortmentProduct,
  addMonthlySpecialCart,
  classicCreateNAddCart,
  classicAddProduct,
  addDirectShipProduct,
  classicDirectShipAddToCart,
  updateQty,
  updateDirectShipCartDetails
} from '../Cart/actions';
import {
  getCategories,
  getCategoriesFetching,
  categoryPropType,
  getCategoriesHasMore
} from './reducer';
import {
  handleChange,
  handleChangeValue,
  handleChangeblur,
  hideToastMessage,
  addToCart,
  addPlanogramToCart,
  addImToCart,
  addDomProduct,
  addRetailAssortmentToCart,
  validateProductQuantity,
  changeStateForValue,
  changeStateForRAValue,
  addReunionOfferingToCart,
  addMonthlySpecialToCart,
  classicHandleChangeblur,
  classicAddToCart,
  getClassicViewPayload,
  getDirectShipClassicViewPayload,
  classicAddDomProduct,
  addDirectShipToCart
} from '../common/productHandler';
import {
  domSectionChange,
  changeDomSearchBy,
  changeDomSearchValue,
  handleEnterKey,
  searchDom,
  fetchItemsForDOM
} from '../DealsOfTheMonth/domHandler';
import {
  fetchReunionProductsList,
  searchReunionItems,
  getReunionFilterString
} from '../ReunionEvent/reunionOfferingsHandler';
import {
  fetchMonthlyProductsList,
  searchMonthlyItems,
  getMonthlyFilterString
} from '../ReunionEvent/MonthlySpecials/MonthlyOfferingsHandler';
import history from '../../history';
import ProductListing from '../common/ProductListing';
import ProductListingGrid from '../common/ProductListingGrid';
import ProductClassicView from '../common/ProductClassicView'
import SubCategoryList from '../common/SubCategoryList';
import { closeSearch } from '../../components/NavBar/actions';
import { isSearchVisible } from '../../components/NavBar/reducer';
import restUtils from '../../utils/restUtils';
import BreadcrumbComp from '../../components/BreadcrumbComp';
import ExcelExport from '../common/ExcelExport';
import InternalServerError from '../../views/ErrorPages/500_error_page';
import { listingItems } from '../../constants/productMenu';
import _, { fromPairs } from 'lodash';
import { SelectedProductView } from './SelectedProductView';
import CompareProducts from './CompareProducts';
import utilities from '../../utils/utilities';
import './category.css';
import { listingUrls } from './listingUrls.js';
import classnames from 'classnames';
import '../common/snackbar.css';
import {
  ALL_WAREHOUSE,
  MY_WAREHOUSE,
  filters as filterKeys
} from '../common/constants';
import {
  PLANOGRAM_LISTING,
  RETAILASSORTMENT,
  PLANOGRAM_FILTER,
  deals
} from '../../urlConstants';
import { isArray } from 'util';
import Filter from '../common/Filter';
import { CUSTOMER_VIEW, MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import { fetchItemsForImpulseMerchadising } from '../ImpulseMerchandising/impulseMerchandisingHandler';
import {
  CLOSEOUTS,
  IMPULSE_MERCHANDISING,
  DISCONTINUED,
  RETAIL_ASSORTMENTS,
  REUNION_RETAIL_ASSORTMENTS,
  PLANOGRAM_ITEMS,
  HOTDEALS,
  FLASH_SALES,
  MONTHLY_SPECIALS,
  CTB_ASSORTMENTS,
  DIRECTSHIP
} from './constants';
class Categories extends Component {
  constructor(props) {
    super(props);
    const { favVendors, match } = props;
    const vendorName =
      match && match.params ? match.params.vendorName : '';
    this.vendorId = props.location && props.location.state &&
      props.location.state.vendorId ?
      props.location.state.vendorId : match && match.params ?
        match.params.vendorId : '';
    this.vendorCount = props.location && props.location.state && props.location.state.vendorCount ?
      props.location.state.vendorCount : match && match.params ?
        match.params.vendorCount : '';
    const isDOMListing = props.assortmentType && props.assortmentType === 'DOM';
    const isImListing =
      props.assortmentType && props.assortmentType === IMPULSE_MERCHANDISING;
    const vendorExist =
      props.location &&
        props.location.state &&
        props.location.state.vendorExists
        ? true
        : false;
    this.handleChange = handleChange.bind(this);
    this.handleChangeblur = handleChangeblur.bind(this);
    this.hideToastMessage = hideToastMessage.bind(this);
    this.addToCart = addToCart.bind(this);
    this.addDirectShipToCart = addDirectShipToCart.bind(this)
    this.handleChangeValue = handleChangeValue.bind(this);
    this.classicHandleChangeblur = classicHandleChangeblur.bind(this);
     this.getClassicViewPayload = getClassicViewPayload.bind(this);
     this.getDirectShipClassicViewPayload = getDirectShipClassicViewPayload.bind(this)
     this.classicAddToCart = classicAddToCart.bind(this)
     this.classicAddDomProduct = classicAddDomProduct.bind(this)
    this.addPlanogramToCart = addPlanogramToCart.bind(this);
    this.addImToCart = addImToCart.bind(this);
    this.addDomProduct = addDomProduct.bind(this);
    this.addRetailAssortmentToCart = addRetailAssortmentToCart.bind(this);
    this.addReunionOfferingToCart = addReunionOfferingToCart.bind(this);
    this.addMonthlySpecialToCart = addMonthlySpecialToCart.bind(this);
    this.validateProductQuantity = validateProductQuantity.bind(this);
    this.domSectionChange = domSectionChange.bind(this);
    this.changeDomSearchBy = changeDomSearchBy.bind(this);
    this.changeStateForValue = changeStateForValue.bind(this);
    this.changeStateForRAValue = changeStateForRAValue.bind(this);
    this.changeDomSearchValue = changeDomSearchValue.bind(this);
    this.handleEnterKey = handleEnterKey.bind(this);
    this.searchDom = searchDom.bind(this);
    this.getReunionFilterString = getReunionFilterString.bind(this);
    this.fetchReunionProductsList = fetchReunionProductsList.bind(this);
    this.fetchMonthlyProductsList = fetchMonthlyProductsList.bind(this);
    this.getMonthlyFilterString = getMonthlyFilterString.bind(this);
    this.searchMonthlyItems = searchMonthlyItems.bind(this);
    this.fetchItemsForImpulseMerchadising = fetchItemsForImpulseMerchadising.bind(this);
    this.fetchItemsForDOM = fetchItemsForDOM.bind(this);
    this.searchReunionItems = searchReunionItems.bind(this);
    this.handleWareHouseSelect = this.handleWareHouseSelect.bind(this);
    const countPerPage =
      props.getprefer && props.getprefer.resultPerPage
        ? props.getprefer.resultPerPage
        : '50';
    this.isShowAllWarehouse =
      props.getprefer &&
        props.getprefer.warehouseProducts &&
        props.getprefer.warehouseProducts === 'A'
        ? true
        : false;
    this.state = {
      viewType:
        vendorExist === false
          ? props.getprefer &&
            props.getprefer?.prdctListlayoutFormat && 
            props.getprefer?.prdctListlayoutFormat === 'G' ? 'grid'
            : props.getprefer?.prdctListlayoutFormat === 'L' ? 'list'
            : props.getprefer?.prdctListlayoutFormat === 'C' ? 'classic' :'list'
          : props.getprefer &&
            props.getprefer?.vendorPrdctLayoutFmt &&
            props.getprefer?.vendorPrdctLayoutFmt === 'G'   ? 'grid'
            : props.getprefer?.vendorPrdctLayoutFmt === 'L' ? 'list'
            : props.getprefer?.vendorPrdctLayoutFmt === 'C' ? 'classic' : 'list',
      categoryData: [],
      quantities: [],
      dataSetValue:[],
      inputFocus: [],
      overrideRetails: [],
      qtyValidation:[],
      loading: false,
      cartSubmit:[],
      shipObj:[],
      deptId: '',
      categoryId: '',
      subCategoryId: '',
      selectedProducts: [],
      comparedItems: [],
      isCompare: false,
      totalItems: 0,
      firstItem: 0,
      lastItem: 0,
      favorites: [],
      clickcheck: false,
      selectedProductsVisible: true,
      isCtbAssortmentRestrictCart: false,
      currentPage: 0,
      totalPages: 0,
      filters: [],
      isNoProducts: false,
      img_txt_to_display:
        this.props.getprefer &&
          this.props.getprefer.thumbnailImage &&
          this.props.getprefer.thumbnailImage !== 'Y'
          ? 'Show Images'
          : 'Hide Images',
      searchKey: '',
      searchValue: '',
      listType: '',
      vendorName: '',
      vendorId: '',
      attributes: [],
      serverError: false,
      url: '',
      appliedFilters: {},
      clearFilters: false,
      planogramMemberCost: [],
      raMemberCost: [],
      showFilters:
        this.props.getprefer && this.props.getprefer.filterView
          ? (this.props.getprefer.filterView === 'E' && window.innerWidth > 1025)
            ? true
            : false
          : window.innerWidth <= 1025 || isImListing
            ? false
            : true,
            isFavorite: favVendors && this.vendorId
            ? favVendors.find(vendor => Number(this.vendorId) === Number(vendor.VendorId))
            : false,
      assortmentType: this.props.assortmentType, //can take values, DOM,Retailor Planogram
      assortmentId: this.props.assortmentId,
      assortmentName: this.props.assortmentName,
      isDOMListing,
      sectionPages:[],
      domSearchValue : '',
      domSearchBy : 'itemnbr',
      toastMessage: '',
      toastError: false,
      isPlanogram:
        this.props.assortmentType && this.props.assortmentType === 'Planograms',
      isRetailAssortment:
        this.props.assortmentType &&
        this.props.assortmentType === 'RetailAssortments',
      isHotdeals:'',
      isMonthlyListing:'',
      retailAssortmentQty: [],
      ctbAssortmentQty:[],
      isCloseouts: '',
      isDirectship:'',
      selectedWareHouse: this.isShowAllWarehouse ? ALL_WAREHOUSE : MY_WAREHOUSE,
      pageSize: countPerPage,
      urlqueryParam: '',
      sortBy: '',
      sortDir: 'asc',
      qty: [],
      clsSortDir: false,
      correctedWord: '',
      originalWord: '',
      noProductMsg: '',
      missing: this.props?.isMissing && this.props?.isMissing ? this.props?.isMissing : null,
      ctbShipOpt: '',
      ctbShipDate: '',
      isDirectShipPage: false,
      directShipTotalCartItems: null,
      directShipTotalCartQty:null,
      directShipTotalCartValue: '',
      Moq:'',
      Mov:'',
      isVendor: false,
      searchPage:'',
      DOMheaderFixed: this.props?.headerFixed === 'DOM'
    };
    this.pathname = '';
    this.sortParam = isDOMListing ? 'item_nbr' : '';
    this.sortDirection = isDOMListing ? 'asc' : '';
    this.loadCategories = this.loadCategories.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.filterString =props.isReunionListing
      ? []
      : props.isMonthlyListing
      ? []
      : '';
    this.sectionPage = '';
    this.domSearchBy = 'itemnbr';
    this.domSearchValue = '';
    this.isShowAllWarehouse =
      props.getprefer &&
        props.getprefer.warehouseProducts &&
        props.getprefer.warehouseProducts === 'A'
        ? true
        : false;
    this.scrollPosition = 0;
    this.patterns = {
      itemnbr: '[0-9]+'
    };
  }

  componentDidMount() {
    this.props.updateQty(false)
    if (this.props.searchVisible) {
      this.props.closeSearch();
    }
    const {
      deptId,
      categoryId,
      subCategoryId,
      searchKey,
      searchValue,
      listType,
      vendorName,
    } = this.props.match ? this.props.match.params : this.state;
    if (vendorName && listType !== DIRECTSHIP) {
      this.props.getAllFavoriteVendors();
    }
    this.props.getFavoriteItems();
    const { assortmentType, assortmentId } = this.props;
    this.setState({
      deptId,
      categoryId,
      subCategoryId,
      searchKey,
      searchValue,
      listType,
      vendorName,
      assortmentType,
      assortmentId,
      isCloseouts: listType === CLOSEOUTS,
      isDirectship: listType === DIRECTSHIP,
      isHotdeals: listType === HOTDEALS,
      isMonthlyListing : this.props.isMonthlyListing,
      urlqueryParam: window.location.href
    });
    this.pathname = this.props.location ? this.props.location.pathname : this.props.pathName;
    const countPerPage =
      this.props.getprefer && this.props.getprefer.resultPerPage
        ? this.props.getprefer.resultPerPage
        : '50';
    this.pageSize =
      listType === CLOSEOUTS || listType === DIRECTSHIP || listType === HOTDEALS ||  this.props.isMonthlyListing || this.state.isDOMListing
        ? countPerPage
        : `&ResultsPerPage=${countPerPage}`;
    this.populatePageWithQueryParam(window.location.href, listType);
    if(this?.props.isCtbAssortment) {
      this.setState({
        missing: this.props?.isMissing
      })
    }
  }
  componentDidUpdate(prevProps, prevState) {
    // after 3 sec hide toast message
   
    if (this.state.toastMessage) {
      this.hideToastMessage();
    }
    const { favVendors } = this.props;
    const { vendorName } = this.props?.match?.params || ""; 
  if (prevProps.favVendors !== favVendors) {
    const isFavorite = favVendors && this.vendorId
    ? favVendors.find(vendor => Number(this.vendorId) === Number(vendor.VendorId))

    : false;
    this.setState({ favVendors, isFavorite, });
  }
    if(this.state.isRetailAssortment && !_.isEmpty(this.state.quantities)&& prevState.quantities !== this.state.quantities) {
      const {categoryData, quantities } = this.state
      const raMemberCost = this.state.raMemberCost.map((item,index)=> {
        return {
          ...item,
          memberCost: categoryData[index].pricing.Cost
        }
      })
      this.setState({raMemberCost: raMemberCost})
      this.props.handleMemberCost(raMemberCost)
    }

    if(this.state.isPlanogram && !_.isEmpty(this.state.quantities)&& prevState.quantities !== this.state.quantities) {
      const {categoryData, quantities } = this.state
      const planogramMemberCost = this.state.planogramMemberCost.map((item,index)=> {
        return {
          ...item,
          memberCost: utilities.getCostForQtyPlp(categoryData[index].pricing, quantities[index] || '', true)
        }
      })
      this.setState({planogramMemberCost: planogramMemberCost})
      this.props.handleMemberCost(planogramMemberCost)
    }

  }

  componentWillReceiveProps(nextProps) {
    if(window.innerWidth >= 1024)
    {
      (nextProps?.match?.params?.listType !== DIRECTSHIP && nextProps.multiStoreDetails && ((nextProps.multiStoreDetails.viewCat && !nextProps.multiStoreDetails.viewPOG) || (nextProps.multiStoreDetails.access !== 1))) &&
      document.getElementById('searchbox').focus();
    }
    const {
      deptId,
      categoryId,
      subCategoryId,
      searchKey,
      searchValue,
      listType,
      vendorName,
      zoneCode
    } = nextProps.match ? nextProps.match.params : this.state;
    const { assortmentType, assortmentId, favVendors } = nextProps;
    if (
      (this.state.deptId !== '' && this.state.deptId !== deptId) ||
      this.state.categoryId !== categoryId ||
      this.state.subCategoryId !== subCategoryId ||
      this.state.searchKey !== searchKey ||
      this.state.searchValue !== searchValue ||
      this.state.vendorName !== vendorName ||
      this.state.listType !== listType ||
      this.state.assortmentType !== assortmentType ||
      this.state.assortmentId !== assortmentId ||
      this.props.zoneCode !== nextProps.zoneCode
    ) {
      const countPerPage =
        this.props.getprefer && this.props.getprefer.resultPerPage
          ? this.props.getprefer.resultPerPage
          : '50';
      const isDOMListing = assortmentType && assortmentType === 'DOM';
      this.sortParam = isDOMListing ? 'item_nbr' : '';
      this.sortDirection = isDOMListing ? 'asc' : '';
      this.scrollPosition = 0;
      this.pageSize =
        listType === CLOSEOUTS || listType === DIRECTSHIP || listType === HOTDEALS  ||  this.props.isMonthlyListing || this.state.isDOMListing
          ? countPerPage
          : `&ResultsPerPage=${countPerPage}`;
      this.isShowAllWarehouse =
        this.props.getprefer &&
          this.props.getprefer.warehouseProducts &&
          this.props.getprefer.warehouseProducts === 'A'
          ? true
          : false;
      this.page = listType === CLOSEOUTS || listType === DIRECTSHIP || listType === HOTDEALS  ||  this.props.isMonthlyListing || this.state.isDOMListing ? '1' : '&Page=1';
      this.filterString = '';
      this.pathname = nextProps.location ? nextProps.location.pathname : '';
      this.setState({
        deptId,
        categoryId,
        subCategoryId,
        searchKey,
        searchValue,
        listType,
        assortmentType,
        assortmentId,
        vendorName,
        appliedFilters: {},
        clearFilters: true,
        isCompare: false,
        selectedProducts: [],
        comparedItems: [],
        currentPage: 1,
        isFavorite: favVendors && this.vendorId
            ? favVendors.find(vendor => Number(this.vendorId) === Number(vendor.VendorId))
            : false,
        isCloseouts: listType === CLOSEOUTS,
        isDirectship: listType === DIRECTSHIP,
        isHotdeals: listType === HOTDEALS,
        pageSize: countPerPage,
        sortBy: '',
        sortDir: '',
        searchPage:''
      });
      this.fetchProductsForCategory(nextProps);
    }
    else if (!this.props.qtyUpdate && this.props.currentCartId !== nextProps.currentCartId && this.state.listType !== DIRECTSHIP ){
        this.fetchProductsForCategory(nextProps);
    }
    else if (searchValue && this.state.searchValue === searchValue) {
      const countPerPage =
        this.props.getprefer && this.props.getprefer.resultPerPage
          ? this.props.getprefer.resultPerPage
          : '50';
      this.setState({
        sortBy: '',
        sortDir: '',
        appliedFilters: {},
        //filters: []
      });
      this.filterString = '';
      this.sortParam = '';
      this.page = '';
      this.sortDirection = '';
      this.pageSize =
        listType === CLOSEOUTS || listType === HOTDEALS || this.props.isMonthlyListing || this.state.isDOMListing
          ? countPerPage
          : `&ResultsPerPage=${countPerPage}`;
      this.isShowAllWarehouse =
        this.props.getprefer &&
          this.props.getprefer.warehouseProducts &&
          this.props.getprefer.warehouseProducts === 'A'
          ? true
          : false;
    }
    else if (
      this.props.searchby !== nextProps.searchby ||
      this.props.searchvalue !== nextProps.searchvalue
    ) {
      this.fetchProductsForCategory(nextProps);
    }

    if(this.props.qtyUpdate) {
      this.props.updateQty(false)
    }  
    if(nextProps.isCtbAssortment && this.props.isMissing !== nextProps.isMissing)  { 
      let { categoryData } = this.state
      if([0, 2].includes(nextProps.isMissing)) {
        categoryData = [0, 2].includes(nextProps.isMissing) && categoryData.filter(product=>{
          if(parseInt(product.IsMissingItem)=== 0) {
            return product
          }
          else {
            return
          }
        })
      }     
      this.setState({missing: nextProps.isMissing, totalItems: categoryData.length},()=>{
      }) 
        
    }
    if(this.props.directShipCartDetails !== nextProps.directShipCartDetails) {
      this.setState({
        directShipTotalCartItems: nextProps.directShipCartDetails.TotalCartItems,
        directShipTotalCartQty:nextProps.directShipCartDetails.TotalCartQty,
        directShipTotalCartValue: nextProps.directShipCartDetails.TotalCartValue,
      })

    }
  }
  populatePageWithQueryParam = (storedUrl, listType, assortmentType) => {
    let queryParam = storedUrl.split('?');
    const { currentCartId } = this.props;
    let {
      searchKey,
      searchValue,
      deptId,
      categoryId,
      subCategoryId,
      vendorName,
    } = this.props.match
        ? this.props.match.params
        : this.state;
    if (queryParam[1]) {
      if(!(listType === 'CloseoutItems' || listType === 'DirectShipItems' || listType === 'HotDeals' 
        || this.state.isPlanogram || this.state.isRetailAssortment
        || this.state.assortmentType || this.state.isDOMListing 
        || this.props.isMonthlyListing || this.props.isReunionListing)){
        let url = this.getListingUrl(assortmentType, listType);
        if (listType) {
          url += listingItems[listType].url + '&GroupURLId=' + this.props.groupUrlId;
        }
        if (deptId) {
          deptId = encodeURIComponent(deptId);
          url = url + 'CatPath=' + deptId;
        }
        if (vendorName) {
          this.setState({
            isVendor:true
          })
          vendorName = utilities.replaceAll(vendorName, '%2F', '/')
          vendorName = encodeURIComponent(vendorName);
          url = url + 'Vendor=' + vendorName;
        }
        if (categoryId) {
          categoryId = encodeURIComponent(categoryId);
          url = url + '&Category=' + categoryId;
        }
        if (this.isShowAllWarehouse && listType !== 'CloseoutItems' && listType !== 'DirectShipItems'&& listType !== 'HotDeals') {
          url = url.endsWith('&')
            ? url + 'allwarehouse=1&'
            : url + '&allwarehouse=1';
        }
        if (subCategoryId) {
          subCategoryId = encodeURIComponent(subCategoryId);
          url = url + '&SubCategory=' + subCategoryId;
        }
        if (this.pageSize && this.pageSize.length > 0) {
          url += this.pageSize;
        }
        if (!utilities.isEmptyOrNullString(currentCartId)) {
          const currentCartDetails = this.props.carts.filter((c) => c.cartId === currentCartId);
          let currentCart = currentCartId;
          if(currentCartDetails && currentCartDetails.length > 0 && currentCartDetails[0]?.cartInfoName && currentCartDetails[0].cartInfoName === 'MS'){
            const msCarts = this.props.carts.filter((c) => c.cartInfoName === 'MS');
            currentCart = msCarts.length > 0 ? msCarts[0]?.cartId : currentCartId;
          }
          url += '&cartid=' + currentCart;
        }
        //To retain Subclass attributes filter
        let urlQueryObject = new URLSearchParams(queryParam[1]);
        let isDeptSelected = false;
        let isMultiDept = false;
        let isClassSelected = false;
        let isMultiClass = false;
        let isSubClassSelected = false;
        let isMultiSubClass = false;
        let deptSelected =  urlQueryObject.get('FineLineDepartment');
        let classSelected =  urlQueryObject.get('FineLineClass');
        let SubClassSelected = urlQueryObject.get('FineLineSubClass');
        if(deptSelected != null  && deptSelected != ''){
          isDeptSelected = true;
          if(deptSelected.includes('~')){
            isMultiDept = true;
          }
        }
        if(classSelected != null  && classSelected != ''){
          isClassSelected = true;
          if(classSelected.includes('~')){
            isMultiClass = true;
          }
        }
        if(SubClassSelected != null && SubClassSelected != '')
        {
          isSubClassSelected = true;
          if(SubClassSelected.includes('~')){
            isMultiSubClass = true;
          }
        }
        if(isDeptSelected || isClassSelected || isSubClassSelected ||
          (subCategoryId && subCategoryId != 'null' && subCategoryId != 'undefined'))
        {
          this.setState({
            url: url,
            loading: true,
          });
          url = url.replace('&ResultsPerPage=50','&ResultsPerPage=1');
          if (searchKey) {
            url = url + '&' + searchKey + '=';
          }
          if (searchValue && searchKey === 'Description') {
            /* if Search value containes % followed by space replace (2% milk) as 2 milk  */
            url = url + searchValue.replace('%25%20', '');
          } else if (searchValue) {
            url = url +  searchValue;
          }
          if(isSubClassSelected && !isMultiSubClass){
            if(isDeptSelected){
              url += '&FineLineDepartment=' + encodeURIComponent(deptSelected);
            }
            if(isClassSelected){
              url += '&FineLineClass=' + encodeURIComponent(classSelected);
            }
            url += '&FineLineSubClass=' + encodeURIComponent(SubClassSelected);
          }
          else if(isClassSelected && !isMultiClass && !isMultiSubClass){
            if(isDeptSelected){
              url += '&FineLineDepartment=' + encodeURIComponent(deptSelected);
            }
            url += '&FineLineClass=' + encodeURIComponent(classSelected);
          }
          else if(isDeptSelected && !isMultiDept && !isMultiClass && !isMultiSubClass){
            url += '&FineLineDepartment=' + encodeURIComponent(deptSelected);
          }
          else if(isDeptSelected){
            url += '&FineLineDepartment=' + encodeURIComponent(deptSelected);
          }
          else if(isClassSelected){
            url += '&FineLineClass=' + encodeURIComponent(classSelected);
          }
          else if(isSubClassSelected){
            url += '&FineLineSubClass=' + encodeURIComponent(SubClassSelected);
          }
          let request = restUtils.getData(url);
          let filters;
          request
            .then(response => {
              filters = response.data.source && response.data.source.attributes && response.data.source.attributes.attribute;
              //If Department/Class/Subclass returns '0' result in filter then get filter value from URL
              if(filters && isArray(filters)){
                for(let i = 0; i < filters.length; i++ ){
                  let attrListValue = filters[i]['attributeValueList'] ? filters[i]['attributeValueList'] : [];
                  if(isDeptSelected && filters[i]['name'] == 'Department'){
                    if(attrListValue.length > 0 && attrListValue[0].attributeValue == 'Department'){
                      let deptList = deptSelected.split('~');
                      for(let j = 0;j < deptList.length; j++){
                        attrListValue[j] = {
                          "attrType": 1,
                          "productCount": '-',
                          "displayAsLink": true,
                          "attributeValue": decodeURIComponent(deptList[j]),
                          "nodeString": "Department",
                          "seoPath": "",
                          "valueType": 1,
                          "singleValued": true
                        }
                      }
                    }
                  }
                  else if(isClassSelected && filters[i]['name'] == 'Class'){
                    if(attrListValue.length > 0 && attrListValue[0].attributeValue == 'Class' ){
                      let classList = classSelected.split('~');
                      for(let j = 0;j < classList.length; j++){
                        attrListValue[j] = {
                          "attrType": 1,
                          "productCount": '-',
                          "displayAsLink": true,
                          "attributeValue": decodeURIComponent(classList[j]),
                          "nodeString": "Class",
                          "seoPath": "",
                          "valueType": 1,
                          "singleValued": true
                        }
                      }
                    }
                  }
                  else if(isSubClassSelected && filters[i]['name'] == 'SubClass'){
                    if(attrListValue.length > 0 &&
                      (attrListValue[0].attributeValue == 'Class' 
                      || attrListValue[0].attributeValue == 'SubClass')){
                      let subClassList = SubClassSelected.split('~');
                      for(let j = 0;j < subClassList.length; j++){
                        attrListValue[j] = {
                          "attrType": 1,
                          "productCount": '-',
                          "displayAsLink": true,
                          "attributeValue": decodeURIComponent(subClassList[j]),
                          "nodeString": "SubClass",
                          "seoPath": "",
                          "valueType": 1,
                          "singleValued": true
                        }
                      }
                    }
                  }
                  filters[i]['attributeValueList'] = attrListValue;
                }
                this.setState({
                  filters
                });
              }
              this.getFilteredData(queryParam, listType);
            });
        }else{
          this.getFilteredData(queryParam, listType);
          this.setState({
            url: url,
            loading: true,
          });
        }
      } else {
        this.getFilteredData(queryParam, listType);
      }
    } else {
      this.fetchProductsForCategory();
    }
  }

  handleDropShip = (val) =>{
    this.setState({
      isDirectShipPage: val,
    },()=>{
      if(val) {
        history.replace({
          pathname:`/directship/DirectShipItems/${this.state.vendorName.replace(/\//g, '%2F')}`+'/'+this.vendorId+'/'+this.vendorCount,
          state: { vendorExists: true, vendorId: this.vendorId,vendorCount : this.vendorCount }
      })
      }else {
        history.replace({
            pathname: `/vendor/${this.state.vendorName.replace(/\//g, '%2F')}`+'/'+this.vendorId+'/'+this.vendorCount,
            state: { vendorExists: true, vendorId: this.vendorId,vendorCount : this.vendorCount }
        })
      }
    })
  }

  multistoreHandleCarts= () => {
    if(this.props?.isCtbAssortment && _.isEmpty(this.state?.ctbShipOpt)) {
      utilities.showToast('Please Select Shiping Option')
      return
    }
    this.setState({
      isMultistoreModalOpen: true
    });
  };

  closeMultistoreModal=()=>{
    this.setState({ isMultistoreModalOpen: false });
  }

  getFilteredData = (queryParam, listType) => {
    let getParams = queryParam[1].split('&');
    let sortParam = queryParam[1].includes('&SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : '';
    this.page = listType === 'CloseoutItems' || listType === 'DirectShipItems' || listType === 'HotDeals' ? getParams[0].split('=')[1] : '&' + getParams[0];
    this.pageSize = listType === 'CloseoutItems' || listType === 'DirectShipItems' || listType === 'HotDeals' ? getParams[1].split('=')[1] : '&' + getParams[1];
    this.isShowAllWarehouse = queryParam[1].includes('allwarehouse') ? true : false;
    if(this.state.isDOMListing){
      let searchParam = queryParam[1].includes('searchBy') ? queryParam[1].split('searchBy=')[1] : '';
      let domSectionChange = queryParam[1].includes('domSectionPage') ? queryParam[1].split('domSectionPage=')[1] : '';
       this.sectionPage = domSectionChange.split('&')[0];
       if(searchParam !== ''){
         let search = searchParam.split('=');
         this.domSearchBy = search[0];
         this.domSearchValue = search[1].split('&')[0];
       }
 }
    this.sortParam = queryParam[1].includes('&SortParam=') ?
                  listType === 'CloseoutItems' || listType === 'DirectShipItems' || listType === 'HotDeals' || this.state.isDOMListing || this.state.isPlanogram
                      || this.state.isRetailAssortment || this.state.assortmentType === 'ImpulseMerchandising' || this.props.isMonthlyListing
                      ? sortParam : '&SortParam=' + sortParam : '';
    this.sortDirection = queryParam[1].includes('SortType=asc') ? listType === 'CloseoutItems' || listType === 'DirectShipItems' || this.state.isDOMListing ||
                           listType === 'HotDeals' || this.state.isPlanogram || this.state.isRetailAssortment ||  
                            this.state.assortmentType === 'ImpulseMerchandising' || this.props.isMonthlyListing ? 'asc' : '&SortType=asc' :
      queryParam[1].includes('SortType=desc') ? listType === 'CloseoutItems' || listType === 'DirectShipItems' || listType === 'HotDeals' || this.state.isPlanogram
        || this.state.isRetailAssortment || this.state.isDOMListing || this.state.assortmentType === 'ImpulseMerchandising' || this.props.isMonthlyListing ? 'desc' : '&SortType=desc' : '';

    this.scrollPosition = queryParam[1].includes('scrollPosition=') 
                            ? queryParam[1].split('scrollPosition=')[1].split('&')[0] 
                            : 0;
    this.filterString = queryParam[1].includes('filterString') 
                              ? queryParam[1].split('filterString=')[1] 
                              : '';
    if (this.state.isPlanogram || this.state.isRetailAssortment) {
      this.filterString = this.filterString.includes('&Department') 
                            ? this.filterString 
                            : this.filterString + "&Department=" + encodeURIComponent(this.props.seleDept).split(',');
      this.filterString = this.props.seleCatg && !this.filterString.includes('&Category')
                            ? this.filterString + "&Category=" + encodeURIComponent(this.props.seleCatg.split(','))
                            : this.filterString;
    }
    this.appliedFilters = listType === 'CloseoutItems'|| listType === 'DirectShipItems'  || listType === 'HotDeals' || this.props.isMonthlyListing  
                              ? utilities.getFilterArrayAsMultiSelectObject(this.filterString) 
                                  : utilities.getFilterArrayDecode(this.filterString);
    //Encoding filter values
    let newAppliedFilters = {};
    for (let key in this.appliedFilters) {
      if (this.appliedFilters.hasOwnProperty(key)) {
        newAppliedFilters[key] = encodeURIComponent(this.appliedFilters[key]);
      }
    }
    let FilterApplied = utilities.getFilterArray(this.filterString);
    let selectVendor =  this.props.vendorDetails ? this.props.vendorDetails.vendorName : null ;
    this.filterString = listType === 'CloseoutItems' || listType === 'DirectShipItems' || listType === 'HotDeals' 
                        || this.state.isPlanogram || this.state.isRetailAssortment 
                        || this.state.assortmentType === 'ImpulseMerchandising' 
                          ? utilities.getQueryJsonAssortment(FilterApplied) 
                          : this.props.isMonthlyListing 
                            ? utilities.getQueryJsonAssortment(FilterApplied, null, null, selectVendor)
                            : utilities.getQueryStrForFilterString(this.appliedFilters);
    if(this.state.isDOMListing && this.filterString !== '') {
      this.filterString = decodeURIComponent(this.filterString.replace('&Visibility=','').replace('&BOM=',','));
      }
    this.setState({
      appliedFilters:  newAppliedFilters,
      selectedWareHouse: this.isShowAllWarehouse ? ALL_WAREHOUSE : MY_WAREHOUSE,
      pageSize: getParams[1].split('=')[1],
      sortBy: queryParam[1].includes('&SortParam=') ? sortParam : '',
      sortDir: queryParam[1].includes('SortType=asc') 
                  ? 'asc' 
                  : queryParam[1].includes('SortType=desc') 
                    ? 'desc' 
                    : 'asc',
      currentPage: getParams[0].split('=')[1],
      domSearchValue : this.domSearchValue,
      domSearchBy : this.domSearchBy
    });
    this.state.isPlanogram || this.state.isRetailAssortment 
        ? this.getFilteredData_Planograms() 
        : this.fetchProductsForCategory();
  }

  setScrollPosition = () => {
    this.scrollPosition = window.pageYOffset;
    this.setBackButtonPath();
  }

  setBackButtonPath = () => {
    let pathName = '';
    const isCloseouts = this.state.listType === CLOSEOUTS;
    const isDirectship = this.state.listType === DIRECTSHIP;
    const isHotdeals = this.state.listType === HOTDEALS;
    const impMerch = this.state.assortmentType === 'ImpulseMerchandising';
    if (this.state.isRetailAssortment || this.state.isPlanogram || impMerch || this.props.isMonthlyListing) {
      if (this.sortParam && this.sortParam.length > 0) {
        pathName = pathName + '&SortParam=' + this.sortParam;
        if (this.sortDirection && this.sortDirection.length > 0) {
          pathName = pathName + '&SortType=' + this.sortDirection;
        } else {
          pathName = pathName + '&SortType=asc';
        }
      }
        pathName = this.scrollPosition > 0 ? pathName + '&scrollPosition=' + this.scrollPosition : pathName;
      if (this.filterString && this.filterString !== '') {
        pathName = pathName + '&filterString=' + utilities.getQueryStrForFilterString(this.appliedFilters);
      }
    }
   
    else {
      pathName = isCloseouts || isDirectship || isHotdeals ? utilities.isEmptyOrNullString(this.page) ? 'Page=1' : 'Page=' + this.page
        : utilities.isEmptyOrNullString(this.page) ? 'Page=1' : this.page.replace('&', '');
      pathName = pathName + (isCloseouts || isDirectship || isHotdeals || this.state.isDOMListing ? '&ResultsPerPage=' + this.pageSize : this.pageSize.includes('&') ? this.pageSize : '&' + this.pageSize);
      if (this.isShowAllWarehouse && !isCloseouts && !isDirectship && !isHotdeals) {
        pathName = pathName + '&allwarehouse=1';
      } else {
        pathName = this.isShowAllWarehouse ? pathName + '&allwarehouse=1' : pathName;
      }
      if(this.state.isDOMListing){
        pathName = pathName + '&domSectionPage='+this.sectionPage;
        if(this.domSearchValue.length > 0){
          pathName = pathName + '&searchBy='+this.domSearchBy+'='+this.domSearchValue;
        }
       
      }
      if (this.sortParam && this.sortParam.length > 0 && !isCloseouts && !isDirectship && !isHotdeals && !this.state.isDOMListing) {
        pathName = pathName + this.sortParam;
        if (this.sortDirection && this.sortDirection.length > 0) {
          pathName = pathName + this.sortDirection;
        } else {
          pathName = pathName + '&SortType=asc';
        }
      } else if (this.sortParam && this.sortParam.length > 0) {
        pathName = pathName + '&SortParam=' + this.sortParam;
        if (this.sortDirection && this.sortDirection.length > 0) {
          pathName = pathName + '&SortType=' + this.sortDirection;
        } else {
          pathName = pathName + '&SortType=asc';
        }
      }
      pathName = pathName + '&scrollPosition=' + this.scrollPosition;
      if (this.filterString && this.filterString !== '' && !isCloseouts && !isDirectship && !isHotdeals && !this.state.isDOMListing) {
        pathName = pathName + '&filterString=' + this.filterString;
      } else {
        pathName = this.props.isMonthlyListing || isCloseouts || isDirectship || isHotdeals || this.state.isDOMListing && this.filterString ?
          pathName + '&filterString=' + utilities.getQueryStrForFilterString(this.appliedFilters) : pathName;
      }
    }
    if (pathName !== '') {
      this.impulseUrl =this.state.urlqueryParam.includes('?') ? this.state.urlqueryParam.split('?')[0]:this.state.urlqueryParam;
      let domUrl = this.state.isDOMListing  ? this.props.promoNbr + '?' + pathName : '';
       let pushUrl = this.state.assortmentType === 'ImpulseMerchandising'? this.impulseUrl + '?' + pathName
                    : this.state.isDOMListing ? domUrl : this.pathname + '?' + pathName;
      window.history.pushState(window.history.state, "", pushUrl);
    }
  }

  handleEnterKeys = e => {
    if (e.keyCode === 13) {
      this.handleEnterKey(e);
      return;
    }
  };

  changeSearchParam = e => {
    this.searchParam = e.currentTarget.value;
    this.scrollPosition = window.pageYOffset;    
    this.setState({domSearchBy:  e.currentTarget.value,domSearchValue: ''});
    this.changeDomSearchBy(e);
  };

  changeDomSectionParam = e => {
    this.sectionPage = e.currentTarget.value;
    this.scrollPosition = window.pageYOffset;    
    this.domSectionChange(e);
  };

  onFilterChange = e => {
    const { validity = {}, value } = e.target;
    this.scrollPosition = window.pageYOffset;
    if (!validity.valid) {
      e.preventDefault();
      return;
    }
    this.setState({
      domSearchValue: value
    });
  };
   
  handleFilter = (name, value) => {
    let filterCBs = null;
    let filerEl = document.getElementById('fs-' + name);
    if(filerEl) {
      filterCBs = document
      .getElementById('fs-' + name)
      .querySelectorAll('.filter-checkbox');
    }
    if (!filterCBs) return;
    filterCBs.forEach(cb => {
      if (cb.value === value) {
        cb.checked = true;
        return;
      }
    });
  };

  changePageSize = e => {
    this.pageSize = this.state.isDirectship ||  this.state.isCloseouts || this.state.isHotdeals  || this.props.isMonthlyListing || this.state.isDOMListing
      ? e.currentTarget.value
      : '&ResultsPerPage=' + e.currentTarget.value;
    this.page = this.state.isCloseouts || this.state.isDirectship || this.state.isHotdeals  || this.props.isMonthlyListing || this.state.isDOMListing ? '1' : '&Page=1';
    this.scrollPosition = window.pageYOffset;
    this.fetchProductsForCategory();
  };
  changeSortOrder = value => {
    if(value === this.state.sortBy) {
      this.setState({ clsSortDir: !this.state.clsSortDir})
      this.sortDirection = this.state.clsSortDir ? '': 'asc'
      this.setState({sortDir: this.state.clsSortDir ? '': 'asc'})
    }
    this.fetchProductsForCategory()
  }
  changeSortParam = e => {
    if(this.state.viewType === 'classic') {
      if(this.state.sortBy === e.currentTarget.value) {
        this.setState({ clsSortDir: !this.state.clsSortDir})
        let sortDirection = this.state.clsSortDir ? 'asc': 'desc'
          this.sortDirection =
          this.state.isPlanogram ||
          this.state.isRetailAssortment ||
          this.state.isCloseouts ||
          this.state.isDirectship ||
          this.state.isHotdeals ||
          this.props.isReunionListing ||
          this.props.isMonthlyListing ||
          this.state.assortmentType === IMPULSE_MERCHANDISING ||
          this.state.isDOMListing
            ? sortDirection
            : '&SortType=' + sortDirection;
        this.page = this.state.isCloseouts || this.state.isDirectship || this.state.isHotdeals ? '1' : '&Page=1';
        this.scrollPosition = window.pageYOffset;
        this.state.isPlanogram || this.state.isRetailAssortment
          ? this.getFilteredData_Planograms()
          : this.fetchProductsForCategory();
        this.setState({ sortDir: sortDirection });
        return
      }
    }
    this.sortParam =
      this.state.isPlanogram ||
      this.state.isRetailAssortment ||
      this.state.isCloseouts ||
      this.state.isDirectship ||
      this.state.isHotdeals ||
      this.state.isDOMListing||
      this.props.isReunionListing ||
      this.props.isMonthlyListing ||
      this.state.assortmentType === IMPULSE_MERCHANDISING ||
      this.state.isNewItemListing
        ? e.currentTarget.value
        : '&SortParam=' + e.currentTarget.value;
    this.page = this.state.isCloseouts || this.state.isDirectship ||  this.state.isHotdeals ? '1' : '&Page=1';
    this.scrollPosition = window.pageYOffset;
    this.state.isPlanogram || this.state.isRetailAssortment
      ? this.getFilteredData_Planograms()
      : this.fetchProductsForCategory();
    this.setState({ sortBy: e.currentTarget.value });
  };

  getFilteredData_Planograms = () => {
    this.setState({ loading: true });
    if (this.filterString.filterBy === undefined) {
      const queryJson = [];
      let departmentSelected = this.props.seleDept;
      let deptArr = {
        name: 'Department',
        valueList: departmentSelected.split(',')
      };
      queryJson.push(deptArr);
      if (this.props.seleCatg) {
        const catgArr = {
          name: 'Category',
          valueList: this.props.seleCatg.split(',')
        };
        queryJson.push(catgArr);
      }
      this.filterString = { filterBy: queryJson };
    }
    let sortArr = {
      sortParam: this.sortParam ? this.sortParam : 'ItemNo',
      sortType: this.sortDirection ? this.sortDirection : 'asc'
    };
    let sortArrRA = {
      sortParam: this.sortParam ? this.sortParam : 'Item_Number',
      sortType: this.sortDirection ? this.sortDirection : 'asc'
    };
    let requestPayLoad = '';
    let fitlerUrl = '';
    if (this.state.isPlanogram) {
      requestPayLoad = {
        storeId: this.props.storeId,
        planogramNumber: this.props.assortmentId,
        primaryWarehouseNumber: this.props.rdc,
        ...this.filterString,
        sortBy: sortArr,
        userId: this.props.userId,
        aliasSKURetailerGrpID : this.props.aliasSKURetailerGrpID
      };
      if(utilities.msExist(this.props.multiStoreDetails) > 1 && this.props.currentCartId){
        requestPayLoad = {...requestPayLoad,multiStoreCartId:this.props.currentCartId}
      }
      fitlerUrl = `${PLANOGRAM_FILTER}`;
    } else if(this.state.isRetailAssortment) {
      requestPayLoad = {
        memberNo: this.props.storeId,
        assmtNbr: this.props.assortmentId,
        modelnumber: this.props.modelnumber,
        ...this.filterString,
        sortBy: sortArrRA,
        cartid : this.props.currentCartId,
        rdc: this.props.rdc,
        aliasSKURetailerGrpID : this.props.aliasSKURetailerGrpID,
        ...(this.props?.isCtbAssortment ? {pognbr:this.props?.planoNum}: null)
      };
      if(this.props?.isCtbAssortment) {
        requestPayLoad = {
          ...requestPayLoad,
          isMissing: this.state.missing,
          type:'CTB'
        }
      }
      if(utilities.msExist(this.props.multiStoreDetails) > 1 && this.props.currentCartId){
        requestPayLoad = {...requestPayLoad,multiStoreCartId:this.props.currentCartId}
      }
      fitlerUrl = `${RETAILASSORTMENT}/Detail`;
    } else {
      requestPayLoad = {
        memberNo: this.props.storeId,
        assmtNbr: this.props.assortmentId,
        modelnumber: this.props.modelnumber,
        ...this.filterString,
        sortBy: sortArr,
        rdc: this.props.rdc,
        aliasSKURetailerGrpID : this.props.aliasSKURetailerGrpID        
      };
      if(utilities.msExist(this.props.multiStoreDetails) > 1 && this.props.currentCartId){
        requestPayLoad = {...requestPayLoad,multiStoreCartId:this.props.currentCartId}
      }
      fitlerUrl = `${RETAILASSORTMENT}/Detail`;
    }
    restUtils
      .postData(fitlerUrl, requestPayLoad)
      .then(response => {
        const data = response.data;
        if(data && !data.itemList){
          this.setState({noProductMsg: data})
        } 
        const categoryData = data.itemList ? data.itemList : [];
        let retailAssortmentQtyPost =  (this.state.isRetailAssortment && this.props.marketFlag) 
                                            ? data?.cart_available
                                            : this.state.isRetailAssortment
                                            ? data?.cart_available[0]?.value
                                            : 0;
        const cartSubmitPost = this.state.isRetailAssortment ? 
        data?.shipopts?.length !==0 ? 
        data?.shipopts: 
            this.props.shipOptions:0
        this.setState({planogramMemberCost:[]})
        this.setState({raMemberCost:[]})
        if(this.state.isPlanogram) {
          categoryData.map(product => {
            const planogramMemberCost = {orderQty:product.recQty, memberCost: utilities.getCostForQtyPlp(product.pricing, product.recQty || '', true)}
            this.setState({planogramMemberCost: [...this.state.planogramMemberCost, planogramMemberCost]})
          })
        }
        if(this.state.isRetailAssortment) {
          categoryData.map(product => {
            const raMemberCost = {orderQty:product.Available, memberCost: product.pricing.Cost}
            this.setState({raMemberCost: [...this.state.raMemberCost, raMemberCost]})
          })
          if(this.props?.isCtbAssortment) {
            this.props.handleCost(data.missing_cr_amt,data.all_cr_amt,data.member_missing_cost,data.regCost,data.missing_items_count,data.all_items_count)
          }
        }
        const quantities = this.state.isPlanogram
          ? categoryData.map(product => {
            return utilities.isEmptyOrNullString(product.cart_available)
              ? product.recQty
              : product.cart_available
          })
          : [];
          let missingItem = []
          if(this.props?.isCtbAssortment) {          
            missingItem = [0, 2].includes(this.state.missing) && categoryData.filter(product=>{
              if(parseInt(product.IsMissingItem)=== 0) {
                return product
              }
            })
        }
        this.setState({
          categoryData,
          quantities,
          filters: data.filters,
          retailAssortmentQty:retailAssortmentQtyPost,
          cartSubmit:cartSubmitPost,
          loading: false,
          serverError: false,
          isNoProducts: categoryData.length === 0,
          totalItems:this.props?.isCtbAssortment && [0, 2].includes(this.state.missing) ? missingItem.length: categoryData.length
        });
        if(!this.state.showFilters)
        {
          this.setState({showFilters: true});
          for (let key in this.appliedFilters) {
            if(document.getElementById('fh-' + key))
             {
               document.getElementById('fh-' + key).className = 'filter-head applied-filter-head';
             }
          }
          this.setState({showFilters: false});
        }else{
          for (let key in this.appliedFilters) {
            if(document.getElementById('fh-' + key))
             {
               document.getElementById('fh-' + key).className = 'filter-head applied-filter-head';
             }
          }
        }
        window.scrollTo(0, this.scrollPosition);
        this.setBackButtonPath();
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });
  };

  changeSortDirection = e => {
    this.sortDirection =
      this.state.isPlanogram ||
      this.state.isRetailAssortment ||
      this.state.isCloseouts ||
      this.state.isDirectship ||
      this.state.isHotdeals ||
      this.props.isReunionListing ||
      this.props.isMonthlyListing ||
      this.state.assortmentType === IMPULSE_MERCHANDISING ||
      this.state.isDOMListing
        ? e.currentTarget.value
        : '&SortType=' + e.currentTarget.value;
    this.page = this.state.isCloseouts || this.state.isDirectship || this.state.isHotdeals ? '1' : '&Page=1';
    this.scrollPosition = window.pageYOffset;
    this.state.isPlanogram || this.state.isRetailAssortment
      ? this.getFilteredData_Planograms()
      : this.fetchProductsForCategory();
    this.setState({ sortDir: e.currentTarget.value });
  };

  onPageChange = e => {
    if(this.state.searchPage !== '' && Number(this.state.searchPage) > Number(this.state.totalPages)){
      utilities.showToast(`Please enter the page is less than ${this.state.totalPages}. The page is not available.`, true,'centerToast',9000);
      return;
    }
    let queryPage = e.currentTarget?.text || this.state.searchPage;
    if (e.currentTarget.text === 'Next') {
      queryPage =
        this.state.currentPage < this.state.totalPages
          ? parseInt(this.state.currentPage) + 1
          : this.state.currentPage;
    }
    if (e.currentTarget.text === 'Prev') {
      queryPage = this.state.currentPage === 1 ? 1 : this.state.currentPage - 1;
    }
    if (e.currentTarget.text === '»') {
      queryPage = this.state.totalPages;
    }
    if (e.currentTarget.text === '«') {
      queryPage = 1;
    }
    this.scrollPosition = 0;
    this.setState({
      searchPage:''
    })
    this.page = this.state.isCloseouts || this.state.isDirectship || this.state.isHotdeals ? queryPage : '&Page=' + queryPage;
    window.scrollTo(0, 0);
    this.fetchProductsForCategory();
  };
  onSearchPageHandle = (e) => {
   this.setState({
      searchPage:e.target.value
   })
  };

  filterCallback = (isAdd, filterKey, filterValue) => {
    let appliedFilters = this.state.appliedFilters;
    this.scrollPosition = window.pageYOffset;
    this.page = this.state.isCloseouts || this.state.isDirectship  ? 1 : this.page;
    const { isReunionListing, isMonthlyListing, vendorDetails } = this.props;
    let keyExists = appliedFilters.hasOwnProperty(filterKey);
    const isImListing = this.state.assortmentType === IMPULSE_MERCHANDISING;
    let isDefaultListing =
      !this.state.isPlanogram &&
      !this.state.isRetailAssortment &&
      !this.state.isDOMListing &&
      !this.state.isReunionListing &&
      !this.state.isMonthlyListing &&
      !this.state.isCloseouts &&
      !this.state.isDirectship &&
      !this.state.isHotdeals &&
      !isImListing;
    if (!keyExists && isAdd) {
      appliedFilters = { ...appliedFilters, [filterKey]: [filterValue] };
    } else if (keyExists) {
      appliedFilters[filterKey] = isArray(appliedFilters[filterKey])
        ? appliedFilters[filterKey]
        : isDefaultListing
          ? appliedFilters[filterKey].split('~')
          : appliedFilters[filterKey];

      let value = isArray(appliedFilters[filterKey]) ? appliedFilters[filterKey] : [appliedFilters[filterKey]];
      let index = value.indexOf(filterValue);
      if (isArray(value) && index > -1) {
        value.splice(index, 1);   // to handle value Array with filter value pre-existing
      } else if (index > -1) {
        value = []         // to handle single string Value 
      } else {
        value.push(filterValue);
      }
      if (!value.length && keyExists) {
        delete appliedFilters[filterKey];
      } else {
        appliedFilters[filterKey] = isDefaultListing
          ? value.join('~')
          : value;
      }
    }
    this.filterString = this.state.isPlanogram
      ? utilities.getQueryJsonAssortment(
        appliedFilters,
        this.props.seleDept,
        this.props.seleCatg
      )
      : this.state.isRetailAssortment
        ? utilities.getQueryJsonAssortment(
          appliedFilters,
          this.props.seleDept,
          this.props.seleCatg
        )
      : this.state.isDOMListing
      ? utilities.getDOMQueryStr(appliedFilters)
      : isReunionListing
      ? this.getReunionFilterString(
          appliedFilters,
          vendorDetails ? vendorDetails.vendorName : null
        )
      : isMonthlyListing
      ? this.getMonthlyFilterString(
          appliedFilters,
          vendorDetails ? vendorDetails.vendorName : null
        )
      : this.state.isCloseouts || this.state.isDirectship || isImListing || this.state.isHotdeals
      ? utilities.getQueryJsonAssortment(appliedFilters)
      : utilities.getQueryStr(appliedFilters);
    this.setState({
      isCompare: false,
      currentPage: 1,
      appliedFilters,
      clearFilters: false
    });
    this.appliedFilters = appliedFilters;
    this.vendorName =  vendorDetails ? vendorDetails.vendorName : null
    this.state.isPlanogram || this.state.isRetailAssortment
      ? this.getFilteredData_Planograms()
      : this.fetchProductsForCategory();
  };

  getListingUrl = (assortmentType, listType) => {
    switch (assortmentType) {
      case 'DOM':
        return listingUrls.DOM;
      case IMPULSE_MERCHANDISING:
        return listingUrls.imProductListing;
      default:
        return listType === CLOSEOUTS
          ? listingUrls.CloseoutItems :
          listType === DIRECTSHIP 
          ? listingUrls.DirectShipItems 
          : listType === HOTDEALS
            ? listingUrls.HotDeals
            : listingUrls.productListing +
            '?rdc=' +
            this.props.rdc +
            '&storeID=' +
            this.props.storeId +
            '&affiliate=' +
            this.props.affiliate +
            '&primaryWarehouseNum=' +
            this.props.primaryWarehouseNum +
            '&GroupURLId=' + this.props.groupUrlId +
            '&aliasSKURetailerGrpID='+this.props.aliasSKURetailerGrpID + '&easyAskDic=' + this.props.easyAskDic +
            '&';
    }
  };

  fetchProductsForCategory = newProps => {
    const { isReunionListing, isMonthlyListing, currentCartId } = newProps
      ? newProps
      : this.props;
    if (isReunionListing) {
      this.fetchReunionProductsList(newProps);
      return;
    } else if (isMonthlyListing) {
       this.fetchMonthlyProductsList(newProps);
       this.setBackButtonPath();
       return;
    }
    let {
      deptId,
      categoryId,
      subCategoryId,
      searchKey,
      searchValue,
      listType,
      vendorName
    } =
      newProps && newProps.match
        ? newProps.match.params
        : this.props.match
          ? this.props.match.params
          : this.state;
    const { assortmentType } = newProps
      ? newProps
      : this.props;
    const isDOMListing = assortmentType === 'DOM';
    const isImListing = assortmentType === IMPULSE_MERCHANDISING;
    const isCloseouts = listType === CLOSEOUTS;
    const isDirectship = listType === DIRECTSHIP
    const isHotDeals = listType === HOTDEALS;
    if (isReunionListing) {
      this.fetchReunionProductsList(newProps);
      return;
    } else if (isImListing) {
      this.fetchItemsForImpulseMerchadising(newProps);
      this.setBackButtonPath();
      return;
    } else if (isDOMListing) {
      this.fetchItemsForDOM(newProps);
      this.setBackButtonPath();
      return;
    }
    let url = this.getListingUrl(assortmentType, listType);
    if (isImListing) {
      url =
        url +
        this.props.assortmentId +
        '&storeID=' +
        this.props.storeId +
        '&rdcnum=' +
        this.props.rdc;
    }
    if (isDOMListing) {
      url =
        url +
        'memNbr=' +
        utilities.emptyForNull(this.props.storeId) +
        '&promoNbr=' +
        utilities.emptyForNull(this.props.promoNbr) +
        '&rdc=' +
        this.props.rdc +
        '&sectionPage=' +
        utilities.emptyForNull(this.sectionPage) +
        '&zoneCode=' +
        utilities.emptyForNull(this.props.zoneCode) +
        '&aliasSKURetailerGrpID=' +
        utilities.emptyForNull(this.props.aliasSKURetailerGrpID) +
        '&searchtype=' +
        utilities.emptyForNull(this.domSearchBy) +
        '&searchby=' +
        utilities.emptyForNull(this.domSearchValue);
    }

    if (isHotDeals) {
      url = url;
    }
    if (listType && !isCloseouts && !isDirectship && !isHotDeals) {
      listType = listingItems[listType].url + '&GroupURLId=' + this.props.groupUrlId;
      url += listType;
    }
    if (searchKey) {
      url = url + searchKey + '=';
    }
    if (searchValue && searchKey === 'Description') {
      /* if Search value containes % followed by space replace (2% milk) as 2 milk  */
      url = url + searchValue.replace('%25%20', '');
    } else if (searchValue) {
      url = url + searchValue;
    }
    if (this.isShowAllWarehouse && !isCloseouts && !isDirectship && !isHotDeals) {
      url = url.endsWith('&')
        ? url + 'allwarehouse=1&'
        : url + '&allwarehouse=1';
    }
    if (this.state.isPlanogram) {
      const selecDept = encodeURIComponent(this.props.seleDept);
      const seleCatg = encodeURIComponent(this.props.seleCatg);
      url = `${PLANOGRAM_LISTING}`;
      url =
        url +
        `storeId=${this.props.storeId}&planogramNumber=${this.props.assortmentId}&deptName=${selecDept}&categoryName=${seleCatg}&rdcNbr=${this.props.rdc}&userId=${this.props.userId}&aliasSKURetailerGrpID=${this.props.aliasSKURetailerGrpID}`;
    }

    if (this.state.isRetailAssortment) {
      const selecDept = encodeURIComponent(this.props.seleDept);
      const seleCatg = encodeURIComponent(this.props.seleCatg);
      const modelnumber = encodeURIComponent(this.props.modelnumber);
      url = `${RETAILASSORTMENT}`;
      url =
        url +
        `/Items?deptName=${selecDept}&categoryName=${seleCatg}&assmtNbr=${this.props.assortmentId}&modelnumber=${modelnumber}&memberNbr=${this.props.storeId}&sortType=ASC&rdc=${this.props.rdc}&aliasSKURetailerGrpID=${this.props.aliasSKURetailerGrpID}`;
    }

    if (deptId) {
      deptId = encodeURIComponent(deptId);
      url = url + 'CatPath=' + deptId;
    }
    /*  Should we use vendor ID instead of Vendor Name? */
    if (vendorName) {
      vendorName = utilities.replaceAll(vendorName, '%2F', '/')
      vendorName = encodeURIComponent(vendorName);
      url = url + 'Vendor=' + vendorName;
    }
    if (categoryId) {
      categoryId = encodeURIComponent(categoryId);
      url = url + '&Category=' + categoryId;
    }
    if (subCategoryId) {
      subCategoryId = encodeURIComponent(subCategoryId);
      url = url + '&SubCategory=' + subCategoryId;
    }
    //loop through attr and add condns
    if (this.sortParam && this.sortParam.length > 0 && !isCloseouts && !isDirectship && !isHotDeals) {
      url = url + this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        url = url + this.sortDirection;
      } else {
        url = url + '&SortType=asc';
      }
    }
    if (!isDOMListing && this.page && this.page.length > 0 && !isCloseouts && !isDirectship && !isHotDeals) {
      url = url + this.page;
    }

    if (this.pageSize && this.pageSize.length > 0 && !isCloseouts && !isDirectship && !isHotDeals) {
      url = url + this.pageSize;
    }
    if (this.filterString && this.filterString !== '' && !isCloseouts && !isDirectship && !isHotDeals) {
      url = url + this.filterString;
    }
    if (!utilities.isEmptyOrNullString(currentCartId) && !isCloseouts && !isDirectship && !isHotDeals) {
      const currentCartDetails = this.props.carts.filter((c) => c.cartId === currentCartId);
      let currentCart = currentCartId;
      if(currentCartDetails && currentCartDetails.length > 0 && currentCartDetails[0]?.cartInfoName && currentCartDetails[0].cartInfoName === 'MS'){
        const msCarts = this.props.carts.filter((c) => c.cartInfoName === 'MS');
        currentCart = msCarts.length > 0 ? msCarts[0]?.cartId : currentCartId;
      }
      url = url + '&cartid=' + currentCart;
    }

    let countPerPage = isCloseouts || isDirectship || isHotDeals
      ? this.pageSize
      : this.pageSize.replace('&ResultsPerPage=', '');
      if(this.props.isCtbAssortment) {
        url = url+`&type=CTB&pognbr=${this.props.planoNum}`
      }
    this.setState({
      url: url,
      loading: true,
      pageSize: countPerPage,
      selectedWareHouse: this.isShowAllWarehouse ? ALL_WAREHOUSE : MY_WAREHOUSE
    });

    if (this.vendorId) {
      this.vendorId = encodeURIComponent(this.vendorId);
      url = url + '&VendorId=' + this.vendorId;
    }
    if (this.vendorCount) {
      this.vendorCount = encodeURIComponent(this.vendorCount);
      url = url + '&VendorProductCount=' + this.vendorCount;
    }
    
    if(utilities.msExist(this.props.multiStoreDetails) > 1  && !isCloseouts && !isDirectship && !isHotDeals && this.props.multiStoreCartId){
        url = url + '&multiStoreCartId=' + this.props.multiStoreCartId
    }
    const pageNum = this.page ? this.page.toString() : ''
    let requestPayLoad = {
      memberNbr: this.props.storeId,
      rdc: this.props.rdc,
      cartid: utilities.emptyForNull(currentCartId),
      page: pageNum && pageNum > 0 ? this.page : '1',
      resultPerPage: this.pageSize,
      sortby: this.sortParam ? ((this.state.isCloseouts || this.state.isDirectship) && this.sortParam === 'CurrentCost') ? 'closeOutCost' : this.sortParam : 'itemNbr',
      sortorder: this.sortDirection ? this.sortDirection : 'asc',
      allwarehouse: this.isShowAllWarehouse ? 1 : 0,
      aliasSKURetailerGrpID : this.props.aliasSKURetailerGrpID,
      ...this.filterString
    };
    if(utilities.msExist(this.props.multiStoreDetails) > 1 && this.props.currentCartId){
      requestPayLoad = {...requestPayLoad,multiStoreCartId:this.props.currentCartId}
    }

    let hotDealsPayload = {
      membernbr: this.props.storeId,
      rdcNum: this.props.rdc,
      cartid: utilities.emptyForNull(this.props.currentCartId),
      page: this.page && this.page > 0 ? this.page : '1',
      resultperpage: this.pageSize,
      sortby: this.sortParam ? this.sortParam : 'Qty',
      sortorder: this.sortDirection ? this.sortDirection : 'asc',
      aliasSKURetailerGrpID : this.props.aliasSKURetailerGrpID,
      ...this.filterString
    };
    if(utilities.msExist(this.props.multiStoreDetails) > 1 && this.props.currentCartId){
      hotDealsPayload = {...hotDealsPayload,multiStoreCartId:this.props.currentCartId}
    }
    let directShipPayload = {
      UserId: this.props.userId,
      page: pageNum && pageNum > 0 ? this.page : '1',
      resultPerPage: this.pageSize,
      sortby: this.sortParam ? this.sortParam : 'item_nbr',
      sortorder: this.sortDirection ? this.sortDirection : 'asc',
      VendorId: this.vendorId,
      ...this.filterString
    }
    if(isDirectship) {
      url = listingUrls[DIRECTSHIP]
    }
    let request = isCloseouts
      ? restUtils.postData(url, requestPayLoad)
      : isDirectship ?  restUtils.postData(url, directShipPayload)
        :isHotDeals
        ? restUtils.postData(url, hotDealsPayload)
        : restUtils.getData(url);
    request
      .then(response => {
        if (
          this.state.searchKey &&
          this.state.searchKey !== '' &&
          response.data.source &&
          response.data.source.products &&
          response.data.source.products.items &&
          response.data.source.products.items.length === 1
        ) {
          history.replace({
            pathname:'/product/' + response.data.source.products.items[0].Item_Number
          });

          return;
        }
        const apiResponse = utilities.getResponse(
          assortmentType,
          listType,
          response
        );
        let items =
          this.state.isPlanogram || this.state.isRetailAssortment
            ? apiResponse.itemList
            : isCloseouts
              ? apiResponse.CLoseOutItemsList
              : isDirectship
                ? apiResponse.DirectShipItems 
              : isHotDeals
                ? apiResponse.HotDeals
                : apiResponse;
        if (!items || items.length === 0 || items === '[]') {
          const originalWord = this.state.searchKey == "Description" && this.state.searchValue ?
	          utilities.replaceAll(decodeURIComponent(this.state.searchValue),'%25','%') : '';
          this.setState({
            loading: false,
            isNoProducts: true,
            totalItems: 0,
            firstItem: 0,
            lastItem: 0,
            currentPage: 0,
            totalPages: 0,
            serverError: false,
            correctedWord: '',
            originalWord
          });
          return;
        }
        if (this.state.isRetailAssortment) {
          this.props.setRegCost(apiResponse.regCost);
          let restrictedItems = [];
          apiResponse && apiResponse.itemList.map((product) => {
            product.itemRestricted === true &&
              restrictedItems.push(product.Item_Number)
          });
          this.props.setRestrictedItems(restrictedItems);
          if(this.props?.isCtbAssortment) {
            this.props.handleCost(apiResponse.missing_cr_amt,apiResponse.all_cr_amt,apiResponse.member_missing_cost,apiResponse.regCost,apiResponse.missing_items_count,apiResponse.all_items_count)
            this.setState({
              isCtbAssortmentRestrictCart: apiResponse?.restrict_cart || false,
            });
            this.props.getFixtures(apiResponse?.fixtures);
            this.props.getRestrictedCartFlag(
              apiResponse?.restrict_cart || false,
              apiResponse?.PogCartStatus,
              apiResponse?.PogSubmittedDate
            );
          }
        }
        const retailAssortmentQty =  _.isEmpty(apiResponse?.cart_available) ? '' : apiResponse?.cart_available[0]?.value;
        const cartSubmit = this.state.isRetailAssortment ? 
          apiResponse?.shipopts?.length !==0 ? 
            apiResponse?.shipopts: 
            this.props.shipOptions:0
            this.setState({planogramMemberCost:[]})
            this.setState({raMemberCost:[]})
        let categoryData = items.map(productData => {
          const favorited =
            this.props.favorites.indexOf(parseInt(productData.Item_Number)) >
            -1;
            if(this.state.isPlanogram) {
              const planogramMemberCost = {orderQty:productData.recQty, memberCost: utilities.getCostForQtyPlp(productData.pricing, productData.recQty || '', true)}
              this.setState({planogramMemberCost: [...this.state.planogramMemberCost, planogramMemberCost]})
            } 
            if(this.state.isRetailAssortment) {
              const raMemberCost = {orderQty:productData.Available, memberCost: productData.pricing.Cost}
              this.setState({raMemberCost: [...this.state.raMemberCost, raMemberCost]})
            }              
            return {
            ...productData,
            selected: false,
            favorited
          };
        });
        const normalizedQuestion = this.state.searchKey == "Description" && this.state.searchValue && response.data.source.normalizedQuestion ? 
          response.data.source.normalizedQuestion.indexOf(';;') !== -1 ? 
          response.data.source.normalizedQuestion.substring(0,response.data.source.normalizedQuestion.indexOf(';;')).trim()
	          : response.data.source.normalizedQuestion : '';
        const correctedWord = this.state.searchKey == "Description" && response.data.source
          && response.data.source.correctedWords ? 
          'There were no products found for \"'+ response.data.source.question.question + 
          '\" but we did find products for \"' + normalizedQuestion + '\" instead.' : '';
        const originalWord = this.state.searchKey == "Description" && this.state.searchValue ?
	        utilities.replaceAll(decodeURIComponent(this.state.searchValue),'%25','%') : '';
        const pagination =
          isCloseouts ? apiResponse.pagination && apiResponse.pagination[0] : isDirectship && apiResponse.pagination;
        const HotDealsPagination =
          isHotDeals && apiResponse.pagination;
        const totalPages = isCloseouts || isDirectship
          ? pagination && parseInt(pagination.totalPages)
          : isHotDeals
            ? HotDealsPagination && parseInt(HotDealsPagination.totalPages)
            : !assortmentType &&
            response.data.source.products.itemDescription.pageCount;
        const currentPage = isCloseouts || isDirectship
          ? pagination && parseInt(pagination.currentPage)
          : isHotDeals
            ? HotDealsPagination && parseInt(HotDealsPagination.currentPage)
            : !assortmentType &&
            response.data.source.products.itemDescription.currentPage;
        let filters =
          this.state.filters && !_.isEmpty(this.state.filters) && !newProps
          ? this.state.isPlanogram || this.state.isRetailAssortment || isHotDeals
            ? this.state.filters : isCloseouts || isDirectship ? apiResponse.filters
            : !assortmentType
              ? this.getNewFilterData(response.data.source && response.data.source.attributes && response.data.source.attributes.attribute)
              : this.state.filters
          : this.state.isPlanogram || this.state.isRetailAssortment || isCloseouts || isDirectship || isHotDeals
            ? apiResponse.filters
            : !assortmentType
              ? response.data.source && response.data.source.attributes && response.data.source.attributes.attribute
              : [];
        if (
          !this.state.isPlanogram &&
          !isDOMListing &&
          !isImListing &&
          !this.state.isRetailAssortment &&
          !isImListing === true &&
          !isCloseouts &&
          !isDirectship &&
          !isHotDeals &&
          !this.state.categoryId &&
          !this.state.subCategoryId &&
          url.indexOf('FineLineSubClass') === -1
        ) {
          filters = filters.filter(attribute => {
            return (attribute.name !== 'SubClass' && attribute.name !== 'GroupURLId');
          });
          const newFilters = response.data.source && response.data.source.attributes && response.data.source.attributes.attribute;

          const classFilter = newFilters.filter(attribute => {
            return attribute.name === 'SubClass';
          });
          if (classFilter && classFilter.length > 0) {
            filters.push(classFilter[0]);
          }
        }
        //Code to refresh Class filter list when it is not getting filtered
        if (
          !this.state.isPlanogram &&
          !isDOMListing &&
          !isImListing &&
          !this.state.isRetailAssortment &&
          !isImListing === true &&
          !isCloseouts &&
          !isDirectship &&
          !isHotDeals &&
          !this.state.categoryId &&
          !this.state.subCategoryId &&
          url.indexOf('FineLineClass') === -1
        ) {
          filters = filters.filter(attribute => {
            return (attribute.name !== 'Class' && attribute.name !== 'GroupURLId');
          });
          const newFilters1 = response.data.source && response.data.source.attributes && response.data.source.attributes.attribute;

          const classFilter1 = newFilters1.filter(attribute => {
            return attribute.name === 'Class';
          });
          if (classFilter1 && classFilter1.length > 0) {
            filters.push(classFilter1[0]);
          }
        }
        const totalItems = assortmentType
          ? items.length
          : isCloseouts || isDirectship
            ? pagination && pagination.totalItems
            : isHotDeals
              ? HotDealsPagination && HotDealsPagination.totalItems
              : response.data.source.products.itemDescription.totalItems;
        const firstItem = isCloseouts || isDirectship
          ? pagination && pagination.firstItem
          : isHotDeals
            ? HotDealsPagination && HotDealsPagination.firstItem
            : !assortmentType &&
            response.data.source.products.itemDescription.firstItem;
        const lastItem = isCloseouts || isDirectship
          ? pagination && pagination.lastItem
          : isHotDeals
            ? HotDealsPagination && HotDealsPagination.lastItem
            : !assortmentType &&
            response.data.source.products.itemDescription.lastItem;
        const quantities = assortmentType
          ? assortmentType === 'Planograms' ?
            categoryData.map(product => {
              return utilities.isEmptyOrNullString(product.cart_available)
                ? product.recQty
                : product.cart_available;
            })
            :
            categoryData.map(({ recQty }) => recQty)
          : categoryData.map(product => {
            return utilities.isEmptyOrNullString(product.cart_available)
              ? ''
              : product.cart_available;
          });
        const overrideRetails = categoryData.map(product => {
          return utilities.isEmptyOrNullString(product.cart_overrideretail)
            ? ''
            : product.cart_overrideretail;
        });
        let qtyAvailableItem = categoryData.filter(product => !utilities.isEmptyOrNullString(product.cart_available))
        if(isDirectship) {
          qtyAvailableItem = categoryData.filter(product => product.cart_available > 0)
        }
        let dataSetValue = qtyAvailableItem && qtyAvailableItem.map(ele=> {
          let product = {
            item: ele.Item_Number,
            qty: ele.cart_available,
            overrideRetail: utilities.isEmptyOrNullString(ele.cart_overrideretail) ? '' : ele.cart_overrideretail,
            productItem: ele
          }
          return product
        })
        let missingItem = []
          if(this.props?.isCtbAssortment) {          
            missingItem = [0,2].includes(this.state.missing) && categoryData.filter(product=>{
              if(parseInt(product.IsMissingItem)=== 0) {
                return product
              }
            })
        }
        let {Mov, Moq} = this.state
        if(this.state.listType === DIRECTSHIP) {
          this.props.updateDirectShipCartDetails(response.data.CartDetails)
          Moq = response.data.VendorDetails.Moq
          Mov = response.data.VendorDetails.Mov
        }
        this.setState({
          categoryData,
          loading: false,
          isNoProducts: false,
          totalItems:this.props?.isCtbAssortment && [0,2].includes(this.state.missing) ? missingItem.length: totalItems,
          cartSubmit,
          firstItem,
          lastItem,
          filters,
          currentPage,
          totalPages,
          serverError: false,
          quantities,
          overrideRetails,
          dataSetValue,
          retailAssortmentQty,
          correctedWord,
          originalWord,
          Mov,
          Moq
        });
        if (!this.state.isPlanogram &&
          !isDOMListing &&
          !isImListing &&
          !this.state.isRetailAssortment &&
          !isImListing === true) {
          for (let key in this.state.appliedFilters) {
            this.setState({showFilters: true});
            let keyvalue = isArray(this.state.appliedFilters[key]) ?
              this.state.appliedFilters[key].join('~') : this.state.appliedFilters[key];
            let filterValue = keyvalue.split('~');
            filterValue.map(value => {
              if (!isCloseouts && !isHotDeals && !isDirectship) {
                this.handleFilter(key, decodeURIComponent(value));
              }
              if(document.getElementById('fh-' + key))
               {
                 document.getElementById('fh-' + key).className = 'filter-head applied-filter-head';
                 document.getElementById('fh-' + key).style.backgroundColor = '#168c87';
               }
              return value;
            });
            this.setState({showFilters:this.props.getprefer && this.props.getprefer.filterView ? this.props.getprefer.filterView === 'E'
            ? true : false : window.innerWidth > 768 ? true : false})
          }
        }
        window.scrollTo(0, this.scrollPosition);
        this.setBackButtonPath();
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });
  };

  //Generate new filters on checking or unchecking any filter's value checkbox in PLP page
  getNewFilterData = (filterResponse) => {
    let filters = [];
    let oldFilters = Object.assign([],this.state.filters);
    let newFilters = filterResponse;
    oldFilters = oldFilters.filter(this.getOldFilter);
    newFilters = newFilters.filter(this.getNewFilter);
    filters = oldFilters.concat(newFilters);
    return filters;
  };

  /* Function to include the 13 static filters in NNW along with the selecting attribute filters
    and remove the rest attribute filters */
  getOldFilter = (oldFilter) => {
    let appliedFilters = Object.assign([],this.state.appliedFilters);
    let appliedFilterKeys = Object.keys(appliedFilters);
    let unchangedFilters = Object.keys(filterKeys);
    if(unchangedFilters.includes(oldFilter.name)) {
      return true;
    }
    else if(appliedFilterKeys.includes(oldFilter.name)) {
      return true;
    }
    else {
      return false;
    }

  }

  /* Function to remove the 13 static filters in NNW along with the selecting attribute filters
    and include the rest attribute filters */
  getNewFilter = (newFilter) => {
    let appliedFilters = Object.assign([],this.state.appliedFilters);
    let appliedFilterKeys = Object.keys(appliedFilters);
    let unchangedFilters = Object.keys(filterKeys);
    if(unchangedFilters.includes(newFilter.name)) {
      return false;
    }
    else if(appliedFilterKeys.includes(newFilter.name)) {
      return false;
    }
    else {
      return true;
    }
  
  }

  //Comparison methods
  removeFromComparison = itemId => {
    let categoryData = this.state.categoryData.map(product => {
      return itemId === '*' || product.Item_Number === itemId
        ? { ...product, selected: false }
        : product;
    });
    let comparedItems = [];
    let selectedProducts = this.state.selectedProducts.filter(product => {
      if (product.Item_Number !== itemId && itemId !== '*') comparedItems.push(product.Item_Number);
      return product.Item_Number !== itemId && itemId !== '*';
    });
    let isCompare = !comparedItems.length ? false : this.state.isCompare;

    this.setState({ categoryData, selectedProducts, isCompare, comparedItems });
  };

  selectForComparison = (itemId, isAdd = true) => {
    let maxProducts = window.innerWidth >= 768 ? 4 : 2;
    let { selectedProducts } = this.state;
    let chosenProduct = null;
    if (isAdd && selectedProducts.length >= maxProducts) return;
    let categoryData = this.state.categoryData.map(product => {
      if (product.Item_Number === itemId) {
        chosenProduct = product;
        return { ...product, selected: !product.selected };
      } else {
        return product;
      }
    });
    selectedProducts = isAdd
      ? [...selectedProducts, chosenProduct]
      : selectedProducts.filter(
        ({ Item_Number }) => Item_Number !== chosenProduct.Item_Number
      );
    this.setState({
      categoryData,
      selectedProducts,
      comparedItems: selectedProducts.map(({ Item_Number }) => Item_Number)
    });
    this.scrollPosition = window.pageYOffset;
  };

  //Favorites
  toggleFavorite = itemId => {
    const { favorites, addToFavorites, removeFromFavorites } = this.props;
    const isAdd =
      favorites.indexOf(parseInt(itemId)) < 0 && favorites.indexOf(itemId) < 0;

    const categoryData = this.state.categoryData.map(product => {
      return product.Item_Number === itemId
        ? { ...product, favorited: !product.favorited }
        : product;
    });

    const newFavorites = categoryData.filter(product => product.favorited);

    this.setState({ categoryData, favorites: newFavorites , toastMessage: isAdd ? 'Item added to Favorite Items List' : 'Item removed from Favorite Items List' }, () => {
      isAdd ? addToFavorites(itemId) : removeFromFavorites([itemId]);
    });
  };

  toggleFavVendor = () => {
    const { vendorName, isFavorite } = this.state

    const {
      favVendors,
      removeVendorFromFavorites,
      addVendorToFavorites
    } = this.props;

    let isAdded = favVendors.find(vendor => Number(this.vendorId) === Number(vendor.VendorId))

    isAdded
      ? removeVendorFromFavorites(vendorName, this.vendorId)
      : addVendorToFavorites(vendorName, this.vendorId);

    this.setState({ isFavorite: !isFavorite });
  };

  blurfun = click => {
    this.setState({
      clickcheck: click
    });
  };
  loadCategories() {
    if (this.props.hasMore) {
      // 20 is the default per_page number used for paginating categories
      const nextPage = Math.round(this.props.categories.length / 20) + 1;
      this.readCategories(nextPage);
    }
  }

  readCategories(page) {
    const { dispatch } = this.props;
    dispatch(fetchCategories({ page, per_page: 20, hide_empty: true }));
  }

  handleSort(e) {
    this.setState({ viewType: e });
    return this.e;
  }

  updateSelectedProductsVisible = () => {
    const { selectedProductsVisible } = this.state;
    this.setState({ selectedProductsVisible: !selectedProductsVisible });
  };

  toggleCompare = () => {
    this.setBackButtonPath();
    window.scrollTo(0, 0);
    this.setState(prevState => {
      return { isCompare: !prevState.isCompare };
    }, () => {
      window.scrollTo(0, this.scrollPosition);
    })
  };

  showHideImages = evt => {
    let img_txt_to_display =
      evt.target.innerHTML === 'Show Images' ? 'Hide Images' : 'Show Images';
    this.setState({ img_txt_to_display: img_txt_to_display });
  };

  clearAllQuantities = () => {
    const planogramMemberCost = this.state.planogramMemberCost.map(item=> {
      return {
        ...item,
        orderQty: 0.00
      }
    } )
    this.setState({
      quantities: Array(this.state.categoryData).fill(''),
      planogramMemberCost
    },()=>{
      this.props.handleMemberCost(this.state.planogramMemberCost)
    });
  };

  toggleShowFilter = () => {
    this.setState(prevState => {
      return { showFilters: !prevState.showFilters };
    });
  };
  getBreadCrumbsLinks = text => {
    const inhertitedBreadcrumbs = this.props.breadcrumbLinks;
    if (inhertitedBreadcrumbs && inhertitedBreadcrumbs.length > 0) {
      return inhertitedBreadcrumbs;
    }
    const vendorName =
      this.props.match && this.props.match.params ? this.props.match.params.vendorName : '';
    let breadcrumbLinks = this.vendorId
      ? [{ link: '/vendors', text: 'VENDORS' }, { link: this.pathname, text: vendorName }]
      : [{ link: '/home', text: 'HOME' }];
    if (text !== '') breadcrumbLinks.push({ link: '#', text });
    if (this.state.searchKey && this.state.searchKey !== '') {
      breadcrumbLinks.push({ link: '#', text: 'SEARCH RESULTS' });
    }
    if (this.state.deptId && this.state.deptId !== '') {
      breadcrumbLinks.push({
        link: '/categories/' + this.state.deptId,
        text: this.state.deptId
      });
    }
    if (this.state.categoryId && this.state.categoryId !== '') {
      breadcrumbLinks.push({
        link: '/categories/' + this.state.deptId + '/' + this.state.categoryId,
        text: this.state.categoryId
      });
    }
    if (this.state.subCategoryId && this.state.subCategoryId !== '') {
      const subCategoryLink = this.state.subCategoryId;

      breadcrumbLinks.push({
        link:
          '/categories/' +
          this.state.deptId +
          '/' +
          this.state.categoryId +
          '/' +
          subCategoryLink.replace(/\//g, '%2F'),
        text: utilities.replaceAll(decodeURIComponent(this.state.subCategoryId), '%2F', '/')
      });
    }
    return breadcrumbLinks;
  };
  handleKeyDown = (e, cartItems)=>{
    const msCheck = utilities.msExist(this.props.multiStoreDetails);
    if(this.props.multiStoreDetails.access !== 1 && e.key === 'Enter' && !this.props.isReunionOnlyStore){
            if(msCheck && msCheck > 1){
          this.multistoreHandleCarts();
        }
        else{
          this.addAssortmentToCart(cartItems);
        }
    }
  }
  addAssortmentToCart = (currentRaqty) => {
    const costs = this.state.categoryData.map(({ Member_Cost }, index) => {
      return Member_Cost * this.state.quantities[index];
    });
    const { carts } = this.props;
    if(this.props?.isCtbAssortment) {
      if(_.isEmpty(this.state.ctbShipOpt)) {
        utilities.showToast('Please Select Shiping Option')
        return
      }
    }
    switch (this.state.assortmentType) {
      case 'Planograms':
        this.addPlanogramToCart(
          this.state.assortmentId,
          carts,
          costs,
          this.props.userId,
          this.props.storeId
        );
        break;
      case IMPULSE_MERCHANDISING:
        this.addImToCart(costs);
        break;
      case 'RetailAssortments':
        currentRaqty = currentRaqty && currentRaqty.filter((validRes)=>{
          if(validRes.qty !==""){
            return validRes;
          }
        })
        let qty = this.props.RAMarketFlag === 'Y'  ? currentRaqty : this.state.retailAssortmentQty;
        if(this.props?.isCtbAssortment) {
          qty =  [{
            month:this.state.ctbShipOpt,
            qty:1,
            ship_date: this.state.ctbShipDate,
            isMissing:parseInt(this.state.missing)
          }]
        }
        this.addRetailAssortmentToCart(
          this.state.assortmentId,
          qty,
          'LR',
          this.props.rdc,
          this.props.userName,
          this.props.userId,
          this.props.storeId,
          this.props.RAcost,
          this.props?.isCtbAssortment,
          this.props?.serialNumber,
          this.state.missing
        );
        break;
      default:
        break;
    }
  };
  handleWareHouseSelect = event => {
    const { value } = event.target;
    this.isShowAllWarehouse = value === ALL_WAREHOUSE;
    this.fetchProductsForCategory();
  };

  getOptionsSection = (breadcrumbLinks) => {
    const {
      vendorName,
      vendorId,
      assortmentType,
      clickcheck,
      isFavorite,
      isDOMListing,
      sectionPages
    } = this.state;
    const { location = {}, isReunionListing , isMonthlyListing,  storeId} = this.props;
    const popupStyle = {fontWeight: 100, fontSize: '10px'}
    return (
      <div className={clickcheck ? 'blur_state options-div' : 'options-div'}>
        {!assortmentType && vendorName && vendorName !== '' ? (
          <>
          {/* <h4 id='vendor_name_padding'>
            <Icon
              color={isFavorite ? 'black' : ''}
              name={isFavorite ? 'heart' : 'heart outline'}
              onClick={this.toggleFavVendor}
            />
            {utilities.replaceAll(vendorName, '%2F', '/')} ({this.vendorId})
            <Popup
              wide='very'
              hoverable
              content={<VendorDetailsPopup ID={this.vendorId}/>}
              className= {window.innerWidth < 767 ? 'flowing' : ''}
              // header={vendorName}
              position='bottom left'
              trigger={
                <Button
                  size='mini'
                  className='view-details-btn vendorViewBtn'
                  content=' View Details'
                />
              }
            />
            </h4>*/}</>
        ): ( (breadcrumbLinks?.length > 0 && window.innerWidth > 1024)
        &&(!this.props.isCtbAssortment && this.state.assortmentType !== 'DOM' && <h2>{decodeURIComponent(breadcrumbLinks[ breadcrumbLinks.length-1].text)}</h2>))}
        <div className='right-justified'>
          {this.state.assortmentType === 'DOM' && (
            <>
              <span className='options-label'>Choose Page </span>
              <select
                id='dom-section-combo'
                onChange={this.changeDomSectionParam}
                className='input-select'
                value={this.sectionPage}
              >
                {sectionPages &&
                  sectionPages.map(sectionPage => {
                    return <option value={sectionPage}>{sectionPage}</option>;
                  })
                }
              </select>
              <span className='options-label'> Search</span>
              <select
                id='dom-search-by'
                onChange={this.changeSearchParam}
                className='input-select'
                value={this.state.domSearchBy}
              >
                <option value='itemnbr' className='SelectOption'>
                  Item#
                </option>
                {!utilities.isEmptyOrNullString(this.props.aliasSKURetailerGrpID) && ( <option
                  value='alternateSKU'
                  className='SelectOption'
                >
                  Alternate SKU#
                </option>)}
                <option value='model' className='SelectOption'>
                  Model#
                </option>
                <option value='description' className='SelectOption'>
                  Description
                </option>
              </select>
              <div
                id='dom-search'
                onChange={this.changeDomSearchValue}
                onKeyDown={this.handleEnterKey}
              >
                <input type='text' onKeyDown={this.handleEnterKeys} 
                  onChange = {this.onFilterChange} 
                  value={this.state.domSearchValue}  
                  pattern={this.patterns[this.state.domSearchBy]}/>
                {renderImages.getImage({
                  src: search_img,
                  onClick: this.searchDom,
                  style: { cursor: 'pointer' }
                })}
              </div>
            </>
          )}
          {this.state.listType !== DIRECTSHIP && !this.state.assortmentType && !isReunionListing && !isMonthlyListing && !this.state.isHotdeals && (
            <>
              <span
                className={
                  this.state.vendorName && this.state.vendorName !== ''
                    ? ''
                    : 'products_padding'
                }
              >
                <span>Show Products from </span>
                <select
                  text='My Warehouse'
                  className='input-select'
                  value={this.state.selectedWareHouse}
                  onChange={this.handleWareHouseSelect}
                >
                  <option className='SelectOption' value={MY_WAREHOUSE}>
                    My Warehouse
                  </option>
                  <option className='SelectOption' value={ALL_WAREHOUSE}>
                    All Warehouses
                  </option>
                </select>
                {showItemCount(parseInt(this.state.pageSize), this.changePageSize)}  
              </span>
            </>
          )}
          {(this.state.listType === DIRECTSHIP || this.state.isHotdeals|| this.props.isMonthlyListing || this.state.isDOMListing) && (
            <>
              <span>Show </span>
              {showItemCount(parseInt(this.pageSize), this.changePageSize)} 
            </>
          )}
        </div>
        {this.state.listType === DIRECTSHIP ? 
         <div className='sortByCategories'>
          <span>Sort by </span>
          <select
            onChange={this.changeSortParam}
            text='Item#'
            className='input-select'
            value={this.state.sortBy}
          >
            <option value='item_nbr' className='SelectOption'>
            Item#
            </option>
            <option value='model' className='SelectOption'>
            Model#
            </option>
            <option value='Description' className='SelectOption'>
            Description
            </option>
            <option value='srp_cost' className='SelectOption'>
            Sugg. Retail
            </option>
            <option value='ds_cost' className='SelectOption'>
            DS Cost
            </option>
            </select>
            <select
            onChange={this.changeSortDirection}
            text='Ascending'
            className='input-select'
            value={this.state.sortDir}
          >
            <option value={
                this.state.assortmentType !== 'RetailAssortments'
                  ? 'asc'
                  : '' } className='SelectOption'
            >
              Ascending
            </option>
            <option value='desc' className='SelectOption'>
              Descending
            </option>
          </select>
          </div>
         :
        <div className='sortByCategories'>
          <span>Sort by </span>
          <select
            onChange={this.changeSortParam}
            text='Item#'
            className='input-select'
            value={this.state.sortBy}
          >
            {!this.state.assortmentType && this.state.listType !== HOTDEALS &&  (
              <>
                <option value='Relevance' className='SelectOption'>
                  Relevance
                </option>
              </>
            )}
            {!this.state.assortmentType && !isMonthlyListing && (
              <>
                <option value='Qty' className='SelectOption'>
                  Available
                </option>
              </>
            )}
            <option
              value={(isDOMListing || isReunionListing || isMonthlyListing)
                      ? 'item_nbr'
                      : this.state.assortmentType === 'RetailAssortments'
                      ? 'Item_Number'
                      : 'ItemNo'}
              className='SelectOption'
            >
              Item#
            </option>
            {!utilities.isEmptyOrNullString(this.props.aliasSKURetailerGrpID) &&  !isReunionListing && !isMonthlyListing 
                && ( <option
              value={'alternateSKU'}
              className='SelectOption'
            >
              Alternate SKU#
            </option>)}
            {!isDOMListing && !isMonthlyListing &&
              !isReunionListing && this.state.listType !== HOTDEALS &&
              this.state.listType !== CLOSEOUTS && this.state.listType !== DIRECTSHIP && this.state.listType !== DISCONTINUED && (
                <option value='MemberCost' className='SelectOption'>
                  Member Cost
                </option>
              )}
            <option
              value={isDOMListing || isReunionListing ||isMonthlyListing ? 'model' : 'ModelNo'}
              className='SelectOption'
            >
              Model#
            </option>
            {(this.state.listType === CLOSEOUTS || this.state.listType === DIRECTSHIP ||
              this.state.listType === DISCONTINUED) && (
                <>
                  <option
                    value={
                      this.state.listType === DISCONTINUED
                        ? 'DiscontinuedDate'
                        : 'discontinuedDate'
                    }
                    className='SelectOption'
                  >
                    Discontinued Date
                </option>
                  <option
                    value={'RegularCost'
                    }
                    className='SelectOption'
                  >
                    Regular Cost
                </option>
                  <option
                    value={'MemberCost'}
                    className='SelectOption'
                  >
                    Member Cost
                </option>
                  <option
                    value={'Savings'                    
                    }
                    className='SelectOption'
                  >
                    % Off
                </option>
                </>
              )}
            {this.state.listType === HOTDEALS && (
              <>
                <option value='discontinuedDate' className='SelectOption'>
                  Discontinued Date
                </option>
                <option value='CurrentCost' className='SelectOption'>
                Member Cost
                </option>
                <option value='percOff' className='SelectOption'>
                  % Off
                </option>
              </>
            )}
            {!this.state.assortmentType && (
              <>
                <option value='Description' className='SelectOption'>
                  Description
                </option>
                <option value='VendorName' className='SelectOption'>
                  Vendor Name
                </option>
                {/* Need to uncomment after we decide on showing SRP in PLP */}
                <option value='suggestedRetail' className='SelectOption'>
                  Suggested Retail Price
                </option>
              </>
            )}
            {(this.state.assortmentType === 'Planograms' || this.state.assortmentType ===  'RetailAssortments') && (
              <>
                <option value='Description' className='SelectOption'>
                  Description
                </option>
                <option value='VendorName' className='SelectOption'>
                  Vendor Name
                </option>
              </>
            )}
            {this.state.listType === 'NewItems' && (
              <>
                <option value='creationDate' className='SelectOption'>
                  Date Added
                </option>
              </>
            )}
            {this.state.assortmentType === 'ImpulseMerchandising' &&
              <>
              <option value='Description' className='SelectOption'>
                Description
              </option>
            </>
            }
          </select>
          <select
            onChange={this.changeSortDirection}
            text='Ascending'
            className='input-select'
            value={this.state.sortDir}
          >
            <option value={
                this.state.assortmentType !== 'RetailAssortments'
                  ? 'asc'
                  : '' } className='SelectOption'
            >
              Ascending
            </option>
            <option value='desc' className='SelectOption'>
              Descending
            </option>
          </select>
        </div>
        }
        <Responsive minWidth={1024}>
          <div id='icon_align'>
            {!isReunionListing && (
            <Popup content='Classic View' className={"iconPopUp"} style ={popupStyle} trigger ={<i
              className='table icon cursor_p'
              value='List'
              id='classic_icon'
              size='mini'
              onClick={() => this.handleSort('classic')}
            ></i>} />
            )}
            |
            <Popup content='List View' style ={popupStyle} trigger ={ <i
              className='list icon cursor_p'
              value='List'
              id='list_icon'
              size='mini'
              onClick={() => this.handleSort('list')}
            ></i>} />
            |
            <Popup content='Grid View' style ={popupStyle} trigger ={<i
              className='th icon cursor_p'
              value='Grid'
              id='grid_icon'
              size='mini'
              onClick={() => this.handleSort('grid')}
            ></i> } />
          </div>
        </Responsive>
      </div>
    );
  };
  ctbAssortmentQtyChange = (e) => {
    const { value } = e.target
    if(value !==1) {
      utilities.showToast('Quantity value should me 1')
    }
  }
  changeStateForCTBValue = (e, ship) =>{
    const { value } = e.target
   this.setState({ctbShipOpt:value, ctbShipDate: ship.rlsDate})
  }
  redirectVendorPage = () => {
    let url = '/vendors'
    if(this.state.listType === DIRECTSHIP) {
      url = '/vendors/ds'
    }
    history.push({
      pathname: url
    })
  }
  render() {
    const {
      img_txt_to_display,
      listType,
      viewType,
      quantities,
      overrideRetails,
      categoryData,
      toastError,
      toastMessage,
      isDOMListing,
      appliedFilters,
      isPlanogram,
      isRetailAssortment,
      filters,
      isNoProducts,
      loading,
      assortmentId,
      assortmentName,
      showFilters,
      isCloseouts,
      isDirectship,
      noProductMsg,
      favVendors,
      DOMheaderFixed
    } = this.state;
    let{ shipObj,
      retailAssortmentQty,cartSubmit,ctbAssortmentQty} = this.state;
    const {
      assortmentType,
      isReunionListing,
      vendorDetails,
      promoNbr,
      isFlashsales,
      isMonthlyListing,
      screenName,shipOptions
    } = this.props;
    const isImListing =
      assortmentType && assortmentType === IMPULSE_MERCHANDISING;
    const img_style = img_txt_to_display === 'Hide Images' ? 'block' : 'none';
    const isDiscontinued =
      listType === DISCONTINUED || isCloseouts || isDirectship;
    const isNewItemListing = listType === 'NewItems';
    const isHotDeal = listType === 'HotDeals';
    let text =
      listType && listType.length > 0 ? listingItems[listType].text : '';
    const breadcrumbLinks = this.getBreadCrumbsLinks(text);
    const tableType = isCloseouts
      ? CLOSEOUTS
      : isDirectship 
      ? DIRECTSHIP
      : isHotDeal
      ? HOTDEALS
      : isDiscontinued
        ? DISCONTINUED
        : isReunionListing
          ? 'Reunion'
          :isMonthlyListing
          ? MONTHLY_SPECIALS
          : isNewItemListing
          ? 'NewItems'
          : assortmentType || 'default';
    const hasHighlightedProducts =
            (isImListing || isPlanogram)
              && (categoryData.map(({ recQty },index) => recQty !== quantities[index])).length > 0;
    let currentRaqty = this.props.RAMarketFlag === 'Y'  &&
      cartSubmit && cartSubmit.map((res)=>{
        const monthsSplit = res.month?.length > 3 ? res.month?.slice(0, 3) : res.month;
          shipObj = {...shipObj, 'month': monthsSplit,'qty': '' }
          retailAssortmentQty && retailAssortmentQty.map((qty,index)=>{
            if(qty.name === monthsSplit){
              shipObj = {...shipObj, 'month': qty.name,'qty': qty.value || ''}
            }
            return shipObj;
          })
            return shipObj;
      })
      let ctbAssortmentMonths = this.props.isCtbAssortment && 
      cartSubmit && cartSubmit.map((res)=>{
        const monthsSplit = res.month?.length > 3 ? res.month?.slice(0, 3) : res.month;
          shipObj = {...shipObj, 'month': monthsSplit,'qty': '','rlsDate': res.rlsDate }
          ctbAssortmentQty && ctbAssortmentQty.map((qty,index)=>{
            if(qty.name === monthsSplit){
              shipObj = {...shipObj, 'month': qty.name,'qty': qty.value || ''}
            }
            return shipObj;
          })
            return shipObj;
      })
      let planogramExportData = [...categoryData]        
      planogramExportData.map((ele,index)=> {
          ele['itemQty'] = this.state.quantities[index]
          return ele
      })
    const cat =
      viewType === 'list' && window.innerWidth >= 1024 ? (
        <ProductListing
          access={this?.props?.multiStoreDetails?.access}
          img_style={img_style}
          listType={listType}
          title={text}
          tableType={tableType}
          viewType={this.props.viewType}
          toggleFavorite={this.toggleFavorite}
          blurfun={this.blurfun}
          categoryData={categoryData}
          selectForCompare={this.selectForComparison}
          removeFromCompare={this.removeFromComparison}
          quantities={quantities}
          overrideRetails={overrideRetails}
          dataSetValue = {this.state.dataSetValue}
          handleChange={this.handleChange}
          handleChangeValue={this.handleChangeValue}
          handleChangeblur={listType === DIRECTSHIP ? this.classicHandleChangeblur: this.handleChangeblur}
          breadcrumbLinks={breadcrumbLinks}
          missing={this.state?.missing}
          isCtbAssortment = {this.props?.isCtbAssortment}
          classicAddToCart={
            isDOMListing
              ? this.classicAddDomProduct
              : isReunionListing
              ? this.addReunionOfferingToCart
              : isMonthlyListing
              ? this.addMonthlySpecialToCart
              : this.classicAddToCart
          }
          addToCart={
            isDOMListing
              ? this.addDomProduct
              : isReunionListing
              ? this.addReunionOfferingToCart
              : isMonthlyListing
              ? this.addMonthlySpecialToCart
              : listType === DIRECTSHIP ? this.addDirectShipToCart
              : this.addToCart
          }
          validateProductQuantity={this.validateProductQuantity}
          assortmentType={assortmentType}
          assortmentId={assortmentId}
          assortmentName={assortmentName}
          ctbseleDept={this.props.seleDept}
          isShowAllWarehouse={this.isShowAllWarehouse}
          storeId={this.props.storeId}
          setBackButtonPath={this.setScrollPosition}
          RAMarketFlag={assortmentType==='RetailAssortments'?this.props.RAMarketFlag:''}
          isReunionOnlyStore = {this.props.isReunionOnlyStore}
          searchKey={isPlanogram ||
            isDOMListing ||
            isReunionListing ||
            isImListing ||
            isMonthlyListing ||
            isRetailAssortment ||
            isCloseouts ||
            isDirectship ||
            isHotDeal ? '' : this.props.match.params.searchKey ? this.props.match.params.searchKey : ''}
          searchValue={isPlanogram ||
            isDOMListing ||
            isReunionListing ||
            isImListing ||
            isMonthlyListing ||
            isRetailAssortment ||
              isCloseouts ||
              isDirectship ||
            isHotDeal ? '' : this.props.match.params.searchValue ? this.props.match.params.searchValue : ''}
           vendorId = {this.vendorId}
        />
        ) :
       viewType === 'classic' && !isReunionListing && window.innerWidth >= 1024 ? (
         <ProductClassicView access={this?.props?.multiStoreDetails?.access}
         img_style={img_style}
         listType={listType}
         title={text}
         tableType={tableType}
         viewType={this.props.viewType}
         toggleFavorite={this.toggleFavorite}
         blurfun={this.blurfun}
         categoryData={categoryData}
         selectForCompare={this.selectForComparison}
         removeFromCompare={this.removeFromComparison}
         quantities={quantities}
         overrideRetails={overrideRetails}
         dataSetValue = {this.state.dataSetValue}
         handleChange={this.handleChange}
         handleChangeblur={this.classicHandleChangeblur}
         breadcrumbLinks={breadcrumbLinks}
         missing={this.state?.missing}
          isCtbAssortment = {this.props?.isCtbAssortment}
         classicAddToCart={
           isDOMListing
             ? this.classicAddDomProduct
             : isReunionListing
             ? this.addReunionOfferingToCart
             : isMonthlyListing
             ? this.addMonthlySpecialToCart
             : listType === DIRECTSHIP ? this.props.classicDirectShipAddToCart
             : this.classicAddToCart
         }
         validateProductQuantity={this.validateProductQuantity}
         assortmentType={assortmentType}
         assortmentId={assortmentId}
         assortmentName={assortmentName}
          ctbseleDept={this.props.seleDept}
         isShowAllWarehouse={this.isShowAllWarehouse}
         storeId={this.props.storeId}
         setBackButtonPath={this.setScrollPosition}
         RAMarketFlag={assortmentType==='RetailAssortments'?this.props.RAMarketFlag:''}
         isReunionOnlyStore = {this.props.isReunionOnlyStore}
         getClassicViewPayload = {listType === DIRECTSHIP ? this.getDirectShipClassicViewPayload : this.getClassicViewPayload}
         inputFocus = { this.state.inputFocus}
         changeSortOrder = {this.changeSortParam}
        searchKey={isPlanogram ||
          isDOMListing ||
          isReunionListing ||
          isImListing ||
          isMonthlyListing ||
          isRetailAssortment ||
          isCloseouts ||
          isDirectship ||
          isHotDeal ? '' : this.props.match.params.searchKey ? this.props.match.params.searchKey : ''}
          searchValue={isPlanogram ||
          isDOMListing ||
          isReunionListing ||
          isImListing ||
          isMonthlyListing ||
          isRetailAssortment ||
          isCloseouts ||
          isHotDeal ? '' : this.props.match.params.searchValue ? this.props.match.params.searchValue : ''}
          vendorId = {this.vendorId}
       />
      ) : (
        <ProductListingGrid
          access={this?.props?.multiStoreDetails?.access}
          img_style={img_style}
          listType={listType}
          title={text}
          tableType={tableType}
          viewType={this.props.viewType}
          toggleFavorite={this.toggleFavorite}
          blurfun={this.blurfun}
          categoryData={categoryData}
          selectForCompare={this.selectForComparison}
          removeFromCompare={this.removeFromComparison}
          quantities={quantities}
          overrideRetails={overrideRetails}
          handleChange={this.handleChange}
          handleChangeValue={this.handleChangeValue}
          handleChangeblur={this.handleChangeblur}
          dataSetValue = {this.state.dataSetValue}
          breadcrumbLinks={breadcrumbLinks}
          missing={this.state?.missing}
          isCtbAssortment = {this.props?.isCtbAssortment}
          addToCart={
            isDOMListing
              ? this.addDomProduct
              : isReunionListing
              ? this.addReunionOfferingToCart
              : isMonthlyListing
              ? this.addMonthlySpecialToCart
              : listType === DIRECTSHIP ? this.addDirectShipToCart
              : this.addToCart
          }
          validateProductQuantity={this.validateProductQuantity}
          assortmentType={assortmentType}
          assortmentId={assortmentId}
          assortmentName = {assortmentName}
          ctbseleDept={this.props.seleDept}
          isShowAllWarehouse={this.isShowAllWarehouse}
          storeId={this.props.storeId}
          setBackButtonPath={this.setScrollPosition}
          RAMarketFlag={assortmentType==='RetailAssortments'?this.props.RAMarketFlag:''}
          isReunionOnlyStore = {this.props.isReunionOnlyStore}
          vendorId = {this.vendorId}
        />
      );
    const selectedProductsDiv =
      this.state.selectedProducts.length === 0 ? (
        ''
      ) : (
          <SelectedProductView
            products={this.state.selectedProducts}
            selectForCompare={this.selectForComparison}
            toggleCompare={this.toggleCompare}
            removeFromCompare={this.removeFromComparison}
            selectedProductsVisible={this.state.selectedProductsVisible}
            updateSelectedProductsVisible={this.updateSelectedProductsVisible}
          />
        );
    const headerSection =
      this.props.assortmentType || isReunionListing || isMonthlyListing ? (
        ''
      ) : (
          <div className='displayFlex bread-crumb'>
            <div className={this.state.clickcheck ? 'blur_state header-display' : 'header-display'}>
              <div className='page-header' id='Warehouse_products'>
                {text !== ''
                  ? text
                  : this.state.vendorName
                    ? 'Vendor Products'
                    : 'Warehouse Products'}
              </div>
              <BreadcrumbComp path={breadcrumbLinks} />
              {(this.state.correctedWord || this.state.originalWord) ? 
              <div className='ui small breadcrumb' >
              <Fragment>
                <div className='divider'><i className='chevron right icon'></i></div>
                <div className='section'>
                  {this.state.correctedWord ? 
                   <div style={{fontFamily:'HelveticaNeue',fontSize:'0.9rem',paddingTop: '0rem',paddingBottom:'0rem',color: 'red'}}>
                     {this.state.correctedWord}
                    </div> : 
                    <div style={{fontFamily:'HelveticaNeueBold',fontSize:'0.9rem',paddingTop: '0rem',paddingBottom:'0rem'}}>
                      SEARCH RESULTS FOR&nbsp;<b style={{color:'red'}}>{this.state.originalWord}</b>
                    </div>
                  }
                </div>
              </Fragment>
              </div>: null}
            </div>
          </div>
        );
    const optionsSection = this.getOptionsSection(breadcrumbLinks);

    const filtersList =
      listType === DISCONTINUED
        ? filters && filters.filter(attribute => {
          return attribute.name !== 'DisconItems';
        })
        : listType === 'NewItems'
          ? filters && filters.filter(attribute => {
            return attribute.name !== 'NewItems';
          })
          : this.state.deptId && this.state.categoryId && this.state.subCategoryId
            ? filters && filters.filter(attribute => {
              return (
                attribute.name !== 'Department' &&
                attribute.name !== 'Class' &&
                attribute.name !== 'SubClass'
              );
            })
            : this.state.deptId && this.state.categoryId
              ? filters && filters.filter(attribute => {
                return (
                  attribute.name !== 'Department' && attribute.name !== 'Class'
                );
              })
              : this.state.deptId
                ? filters && filters.filter(attribute => {
                  return attribute.name !== 'Department';
                })
                : filters;
    let msCheck = utilities.msExist(this.props.multiStoreDetails); 
    return (
      <div className={classnames('categories', { 'filter-open': showFilters })}>
        <MultistoreToCartModal
          categoryData={this.state.categoryData}
          quantities={this.state.quantities}
          ctbShipOpt = {this.state.ctbShipOpt}
          isCtbAssortment = {this.props?.isCtbAssortment}
          assortmentOrder={true}
          DisplayName={this.props.DisplayName}
          shipOptions={this.props.shipOptions}
          RAMarketFlag={this.props.RAMarketFlag}
          assortmentType={this.state.assortmentType}
          assortmentId={this.state.assortmentId}
          quantitiesMs={this.state.retailAssortmentQty}
          isMultistoreModalOpen={this.state.isMultistoreModalOpen}
          closeMultistoreModal={this.closeMultistoreModal}
          serialNumber = {this.props?.serialNumber}/>
          
        {loading && (
          <div>
            <Loader active />
          </div>
        )}
        {this.state.serverError ? (
          <InternalServerError />
        ) : (
            <>
              {this.state.isCompare ? (
                <CompareProducts
                  toggleFavorite={this.toggleFavorite}
                  comparedItems={this.state.comparedItems}
                  selectedProducts={this.state.selectedProducts}
                  categoryId={this.state.categoryId}
                  removeFromCompare={this.removeFromComparison}
                  toggleCompare={this.toggleCompare}
                  isCustomerView={this.props.viewType === CUSTOMER_VIEW}
                  storeId={this.props.storeId}
                  HotDeals={this.state.isHotdeals}
                />
              ) : (
                  <>
                    <Segment
                      id={
                        this.state.assortmentType
                          ?  this.state.assortmentType ==="DOM" ? 'assortment_info_dom': 'assortment_info'
                          : 'productDetailInfo'
                      }
                    >
                      {(this.state.vendorName || this.state.listType === DIRECTSHIP) && (
                        <>
                        <div className='header-display'>
                          <div class="page-header vendorListHeader" id="Warehouse_products">Vendor Products</div>
                          <div class="ui small breadcrumb"><div class="section caps"><p className='vendorBreadcrumb'onClick={this.redirectVendorPage}>Vendors</p></div><div class="section caps"></div></div>
                        </div>
                        <div className='directShipHeader'>
                          <div className="dropShipBtnWrapper">
                            <button className= { this.state.listType !== DIRECTSHIP ? "ui red button" :"ui button"} onClick={()=>this.handleDropShip(false)}>Warehouse</button>
                            <button className= { this.state.listType === DIRECTSHIP ? "ui red button" :"ui button"} onClick={()=>this.handleDropShip(true)}>Direct Ship</button>                           
                          </div>
                          <div>
                            <h4 id='vendor_name_padding'>
                              {this.state.listType !== DIRECTSHIP && (
                                <Icon
                                color={this.state.isFavorite ? 'black' : ''}
                                name={this.state.isFavorite ? 'heart' : 'heart outline'}
                                onClick={this.toggleFavVendor}
                              />
                              )}                              
                              {utilities.replaceAll(this.state.vendorName, '%2F', '/')} ({this.vendorId})
                              <Popup
                                wide='very'
                                hoverable
                                content={<VendorDetailsPopup ID={this.vendorId}/>}
                                className= {window.innerWidth < 767 ? 'flowing' : ''}
                                // header={vendorName}
                                position='bottom left'
                                trigger={
                                  <Button
                                    size='mini'
                                    className='view-details-btn vendorViewBtn'
                                    content=' View Details'
                                  />
                                }
                              />
                            </h4>
                          </div>
                          <div></div>
                        </div>
                         { this.state.listType === DIRECTSHIP && <p style={{textAlign:'center', color:'#db2828'}}>We're actively collaborating to onboard Direct Ship vendors onto Netwarehouse, aiming to enhance your overall experience. Stay tuned for additional updates and communication</p> }
                        </>

                      )}
                      <div className={ this.state.DOMheaderFixed  ? 'HeaderFixedPLP' : this.props.assortmentType || isReunionListing || isMonthlyListing || this.state.vendorName ? '' :"HeaderFixedPLP"}>
                      {(_.isEmpty(this.state.vendorName) && this.state.listType !== DIRECTSHIP) && headerSection}
                      {optionsSection}
                      </div>
                      <Grid className = 'stackable two column plpPadding'>
                      <Grid.Column computer = {showFilters?3:'none'} tablet = {showFilters?4:'none'} className = { this.state.DOMheaderFixed  ? "compact_padding staticFilter plpPageFilter " :this.props.assortmentType || isReunionListing || isMonthlyListing || this.state.vendorName ? 'compact_padding staticFilter' :"compact_padding staticFilter plpPageFilter "}>
                          {isPlanogram ||
                            isDOMListing ||
                            isReunionListing ||
                            isImListing ||
                            isMonthlyListing ||
                            isRetailAssortment ||
                            isCloseouts ||
                            isDirectship ||
                            isHotDeal ? (
                              <Filter
                                clearFilters={this.state.clearFilters}
                                filtersApplied={!_.isEmpty(appliedFilters)}
                                appliedFilters={appliedFilters}
                                toggleShowFilter={this.toggleShowFilter}
                                showFilters={this.state.showFilters}
                                filters={filters}
                                callback={this.filterCallback}
                                isNoProducts={isNoProducts}
                                isDirectShipFilter = {this.state.isDirectShipPage}
                                selectedVendor={
                                  (isReunionListing || isMonthlyListing)
                                    ? vendorDetails && vendorDetails.vendorName
                                    : null
                                }
                                selecDept={
                                  isPlanogram || isRetailAssortment
                                    ? this.props.seleDept
                                    : null
                                }
                                seleCatg={
                                  isPlanogram || isRetailAssortment
                                    ? this.props.seleCatg
                                    : null
                                }
                                listType={
                                  isImListing ? IMPULSE_MERCHANDISING
                                        : isDOMListing
                                        ? 'DOM'
                                        : isMonthlyListing
                                        ? 6
                                        : listType
                                }
                                screenName={isPlanogram ? 'Planograms' : screenName}
                              />
                            ) : (
                              <SubCategoryList
                                clearFilters={this.state.clearFilters}
                                filtersApplied={!_.isEmpty(this.state.appliedFilters)}
                                toggleShowFilter={this.toggleShowFilter}
                                showFilters={this.state.showFilters}
                                filters={filtersList}
                                callback={this.filterCallback}
                                isNoProducts={isNoProducts}
                              />
                            )}
                        </Grid.Column>
                        <Grid.Column computer = {showFilters?13:16} tablet = {showFilters?12:16} className =  {this.state.DOMheaderFixed  ? "compact_padding plpPageListing" : this.props.assortmentType || isReunionListing || isMonthlyListing || this.state.vendorName ? 'compact_padding' :"compact_padding plpPageListing"}> 
                        {!this.state.isNoProducts && this.state.listType === DIRECTSHIP && (
                              <div id={this.state.listType === DIRECTSHIP? 'ds_directShipQtyMsg' : '' } className='directShipQtyMsg'> 
                                <p><span>Order Total: ${utilities.commify(this.state.directShipTotalCartValue)}</span> &nbsp;| &nbsp;<span>Qty: {this.state.directShipTotalCartQty}</span> &nbsp;{this.state.directShipTotalCartValue < this.state.Mov && <strong>&nbsp;&nbsp;&nbsp;${utilities.commify(this.state.Mov)} Min threshold not met.</strong>}</p>
                              </div>
                     )}
                        {isNoProducts && !isHotDeal ? (
                          <React.Fragment>
                            <div className='no_prdcts_display'>
                              <h1>{noProductMsg ? noProductMsg : 'No Products to Display!'}</h1>
                            </div>
                            <div className='highlight-disclaimer'>
                              <h3 className='noproducts-suggestion'>Hint: To broaden your search, change your setting from Show products in "MY Warehouse" to "ALL Warehouses".</h3>
                            </div>
                          </React.Fragment>
                        ) 
                        :
                        isNoProducts && isHotDeal? (
                          <div className='no_prdcts_display'>
                          <h1>No {deals} are available at this time. Please check back next month</h1>
                        </div>
                        )
                        : (
                            <>
                              {hasHighlightedProducts &&
                                <p className='highlight-disclaimer'>
                                  *Highlight indicates quantity has been changed from recommended quantity
                                </p>
                              }
                               <div id={this.state.listType === DIRECTSHIP? 'ds_pagination' : '' } className = {showFilters && window.innerWidth < 769?'listing-header flex-end assortmentListingHeader':  this.state.DOMheaderFixed ?  "listing-header plpPageFixed assortmentListingHeader" : this.props.assortmentType || isReunionListing || isMonthlyListing || this.state.vendorName ? 'listing-header assortmentListingHeader' : 'listing-header plpPageFixed assortmentListingHeader'}>
                          <div className = 'listing-header-left'>
                        <span
                            onClick={this.showHideImages}
                            id='show_and_hide_images'
                          >
                            {this.state.img_txt_to_display}
                          </span>
                          {this.state.assortmentType  &&  !this.state.isDOMListing ? (
                            <span>
                              &nbsp;&nbsp;
                              {this.state.totalItems}{' '}
                              {this.state.totalItems > 1 ? 'items' : 'item'}
                            </span>
                          ) 
                          :
                        (
                              <span>&nbsp;&nbsp;
                                {this.state.firstItem}-{this.state.lastItem} of {' '}
                                {this.state.totalItems} products
                              </span>
                            )}
                            </div>
                          {this.state.assortmentType && !isDOMListing && !loading ? (
                            <>
                              {isRetailAssortment ? (
                                <>
                                  <span className='final_sales'> ALL SALES ARE FINAL!</span>
                                  {this.props.RAMarketFlag === 'Y' && !this.props?.isCtbAssortment  ?
                                    (
                                      <span className='qty-field'>
                                        Delivery: &nbsp;
                                        {currentRaqty && currentRaqty.map((ship, index) => (
                                          <>
                                            <span class='ship_options'>
                                              <span class={index > 0 ? 'delivery_options' : 'delivery_options_month'}>
                                                {ship.month?.length > 3 ? ship.month?.slice(0, 3) : ship.month}
                                              </span>
                                              <input
                                                type='text'
                                                className='delivery-qty-box'
                                                id={'qty-box' + ship.month}
                                                data-field='qty'
                                                value={ship.qty}
                                                name={'retailAssortmentQty' + ship.month}
                                                onChange={(e) => this.changeStateForRAValue(e, ship.month, index,this.props.RAMarketFlag)}
                                              />
                                            </span>
                                          </>
                                        ))}
                                      </span>
                                    )
                                    :this.props?.isCtbAssortment ? 
                                    (
                                      <>
                                       <span className='qty-field'>
                                        Quantity: &nbsp;
                                        <span>1</span>
                                      </span>
                                      <span 
                                       className='qty-field ctbShipOption'
                                       style={{
                                        display:
                                          this.props?.isCtbAssortment &&
                                          this.state.isCtbAssortmentRestrictCart
                                            ? "none"
                                            : "inherit",
                                      }}> 
                                        
                                        Ship Month: &nbsp;
                                        {ctbAssortmentMonths && ctbAssortmentMonths.map((ship, index) => (
                                          <>
                                            <span class='ship_options'>
                                              <label for ={'qty-box' + ship.month}class={index > 0 ? 'delivery_options' : 'delivery_options_month'}>
                                                {ship.month?.length > 3 ? ship.month?.slice(0, 3) : ship.month}
                                              </label>
                                              <input
                                                type='radio'
                                                className='delivery-qty-box'
                                                id={'qty-box' + ship.month}
                                                data-field='qty'
                                                value={ship.month}
                                                name={'ctbAssortmentQty'}
                                                onChange={(e) => this.changeStateForCTBValue(e, ship)}
                                              /> 
                                              {/*<p>Exp({utilities.getDateByFilterMonthDate(ship.rlsDate)})</p>      */}                                     
                                            </span>
                                          </>
                                        ))}
                                      </span>
                                      </>
                                    ):
                                    (
                                      <span className='qty-field'>
                                        Quantity: &nbsp;
                                        <input
                                          type='text'
                                          className='qty-box'
                                          id='qty-box'
                                          data-field='qty'
                                          name='retailAssortmentQty'
                                          value={this.state.retailAssortmentQty}
                                          onChange={this.changeStateForValue}
                                          onKeyDown = {(e)=>this.handleKeyDown(e, currentRaqty)}
                                        />&ensp;
                                      </span>
                                    )
                                  }
                                </>
                              ) : (
                                  <span onClick={this.clearAllQuantities} id='clear_quantities'>
                                    Clear All Quantity
                                  </span>
                                )}
                              {
                                (
                                  !(
                                    isImListing &&
                                    (assortmentId === 'CLIP01' || assortmentId === 'CTOP01')
                                  ) && !(
                                    isRetailAssortment && this.props.isAssortmentRestricted
                                  )
                                ) && (
                                  <>
                                    {this.props.multiStoreDetails.access !== 1 && (
                                      <>
                                        {msCheck && msCheck > 1 ?
                                          (!this.props.isReunionOnlyStore && !this.props?.isCtbAssortment) ?
                                          <button
                                          className={`red ui button ${
                                            isImListing
                                              ? 'im-item-list-addtocart-btn'
                                              : 'pl-item-list-addtocart-btn'
                                            }`}
                                          onClick={() =>
                                            this.multistoreHandleCarts()
                                          }
                                          disabled = {isNoProducts}
                                        >
                                          Add to Store
                                        </button>
                                        : this.props?.isCtbAssortment ?
                                        <button
                                        className={`red ui button ${
                                          isImListing
                                            ? 'im-item-list-addtocart-btn'
                                            : 'pl-item-list-addtocart-btn'
                                          }`}
                                        onClick={()=>this.addAssortmentToCart(currentRaqty)}
                                        disabled = {isNoProducts || this.state.totalItems === 0}
                                        >
                                          Add to Cart
                                        </button>
                                       :
                                        <div>
                                            <button className='red ui button' disabled>Add to Store</button>
                                            <p class="disabledMessage">*Ordering restricted</p>
                                          </ div>
                                      :!this.props.isReunionOnlyStore ?
                                      <button
                                        className={`red ui button ${
                                          isImListing
                                            ? 'im-item-list-addtocart-btn'
                                            : 'pl-item-list-addtocart-btn'
                                          }`}
                                        onClick={()=>this.addAssortmentToCart(currentRaqty)}
                                        style={{
                                          display:
                                            this.props.isCtbAssortment &&
                                            this.state
                                              .isCtbAssortmentRestrictCart
                                              ? "none"
                                              : "inherit",
                                        }}
                                        disabled = {isNoProducts || this.state.totalItems === 0 || (this.props.isCtbAssortment && this.state.isCtbAssortmentRestrictCart)}
                                      >
                                        Add to Cart
                                      </button>
                                      : <div>
                                          <button className='red ui button' disabled>Add to Cart</button>
                                          <p class="disabledMessage">*Ordering restricted</p>
                                        </ div>
                                      }
                                      </>
                                    )}
                                  </>
                                )}

                              {(isImListing || isRetailAssortment || isPlanogram || isFlashsales || this.props?.isCtbAssortment) && !utilities.isMobileTabDevice() && (
                                <Responsive minWidth='767'>
                                  <ExcelExport
                                    missing={this.state?.missing}
                                    isCtbAssortment={this.props?.isCtbAssortment}
                                    planogramNumber={this.props.planoNum}
                                    className={
                                        isImListing
                                          ? 'exportPDF im-export exportPdfPLP'
                                          : 'exportPDF exportPdfPLP listingExport'
                                    }
                                    itemsData={isPlanogram ? planogramExportData : categoryData}
                                    planogramMemberCost = {this.state.planogramMemberCost}
                                    raMemberCost = {this.state.raMemberCost}
                                    fileName={
                                      (isRetailAssortment && this.props?.isCtbAssortment)
                                      ? CTB_ASSORTMENTS                                     
                                      : isImListing
                                        ? IMPULSE_MERCHANDISING
                                        : isFlashsales
                                          ? FLASH_SALES
                                          : isRetailAssortment
                                                ? RETAIL_ASSORTMENTS
                                                  : isPlanogram 
                                                    ? PLANOGRAM_ITEMS
                                                      : text
                                    }
                                    totalItems={this.state.totalItems}
                                    viewType={this.props.viewType}
                                    aliasSKURetailerGrpID={this.props.aliasSKURetailerGrpID}
                                    RAMarketFlag={this.props.RAMarketFlag}
                                    assortmentId={this.state.assortmentId}
                                    getprefer={
                                      this.props.getprefer && this.props.getprefer.customerView
                                        ? this.props.getprefer.customerView
                                        : 'c1'
                                    }
                                    planogramData ={isPlanogram ? this.props.PlanogramDetails : ''}
                                    storeId= {this.props.storeId}
                                    rdc={this.props.rdc}
                                    cartid={this.props.currentCartId}
                                    favorites={this.props.favorites}
                                    userId = {this.props.userId}
                                  />
                                </Responsive>
                              )}
                            </>
                          ) : (
                              <>
                                {this.state.totalPages > 0 && (
                                  <div className="headerPagination">
                                    {getPagination(0, parseInt(this.state.currentPage), parseInt(this.state.totalPages), this.onPageChange, null, 1)}
                                    <div className='paginationSearch'>
                                    <label htmlFor="search_PLPPagination">Enter Page No</label>
                                      <div className='inlineGrouptext'>
                                      <input
                                        id='search_PLPPagination'
                                        onChange={this.onSearchPageHandle}
                                        value={this.state.searchPage}
                                      />
                                      <span className="rightLabel"> / {this.state.totalPages}</span>
                                      </div>
                                      <button className="red ui button paginationButton" onClick={this.onPageChange}>Go</button>
                                    </div>
                                  </div>
                                )}
                                {this.state.assortmentType !==
                                  'RetailAssortments' && (
                                    <Responsive minWidth='767'>
                                      <ExcelExport
                                        className='exportPDF exportPdfPLP'
                                        itemsData={categoryData}
                                        fileName={ isReunionListing
                                            ? 'Market'
                                            : isDOMListing
                                            ? 'dom'
                                            : isMonthlyListing
                                            ? MONTHLY_SPECIALS
                                            :  this.state.listType === DIRECTSHIP  ? 'DirectShip Products': this.state.vendorName ?  'Vendor Products' : text
                                        }
                                        aliasSKURetailerGrpID={this.props.aliasSKURetailerGrpID}
                                        totalItems={this.state.totalItems}
                                        viewType={this.props.viewType}
                                        getprefer={
                                          this.props.getprefer &&
                                            this.props.getprefer.customerView
                                            ? this.props.getprefer.customerView
                                            : 'c1'
                                        }
                                        rdc={this.props.rdc}
                                        storeID={this.props.storeId}
                                        affiliate={this.props.affiliate}
                                        primaryWarehouseNum={this.props.primaryWarehouseNum}
                                        groupUrlId={this.props.groupUrlId}
                                        easyAskDic={this.props.easyAskDic}
                                        catPath={this.state.deptId ? encodeURIComponent(this.state.deptId) : ''}
                                        cartid={this.props.currentCartId}
                                        category={this.state.categoryId ? encodeURIComponent(this.state.categoryId) : ''}
                                        description={this.state.searchKey}
                                        descriptionValue={this.state.searchValue}
                                        subCategory={this.state.subCategoryId ? encodeURIComponent(this.state.subCategoryId) : ''}
                                        sortDir={this.state.sortDir}
                                        sortBy={this.state.sortBy}
                                        filterString={this.filterString}
                                        vendorName={this.state.vendorName ? encodeURIComponent(this.state.vendorName) : ''}
                                        vendorId={this.vendorId}
                                        isShowAllWarehouse={this.isShowAllWarehouse}
                                        favorites={this.props.favorites}
                                        multiStoreDetails={this.props.multiStoreDetails}
                                      />
                                    </Responsive>
                                  )}
                              </>
                            )}
                        </div>
                              {cat}
                              {!this.state.assortmentType && (
                                <span className='pagination_bottom'>
                                  {this.state.totalPages > 0 && (
                                     getPagination(0, parseInt(this.state.currentPage), parseInt(this.state.totalPages), this.onPageChange, null, 1)
                                  )}
                                </span>
                              )}
                              {this.state.assortmentType &&
                                !isDOMListing &&
                                !loading &&
                                (!(
                                  isImListing &&
                                  (assortmentId === 'CLIP01' ||
                                    assortmentId === 'CTOP01')
                                ) && !(
                                  isRetailAssortment && this.props.isAssortmentRestricted
                                )
                                ) && (
                                  <>
                                    {this.props.multiStoreDetails.access !== 1 && (
                                      <>
                                        {msCheck && msCheck > 1 ?
                                          (!this.props.isReunionOnlyStore && !this.props?.isCtbAssortment) ?
                                          <button
                                          className='red ui button pl-item-list-addtocart-btn'
                                          onClick={() =>
                                            this.multistoreHandleCarts()
                                          }
                                        >
                                          Add to Store
                                        </button>
                                        : this.props?.isCtbAssortment ?
                                        <div className='bottomAddtoCart'>
                                          {this.state.totalItems > 0 && (
                                            <button
                                            className={`red ui button ${
                                              isImListing
                                                ? 'im-item-list-addtocart-btn'
                                                : ''
                                              }`}
                                            onClick={()=>this.addAssortmentToCart(currentRaqty)}
                                            disabled = {isNoProducts}
                                            >
                                              Add to Cart
                                            </button>
                                          )}                                        
                                        </div>
                                       :
                                        <div>
                                            <button className='red ui button' disabled>Add to Store</button>
                                            <p class="disabledMessage">*Ordering restricted</p>
                                          </ div>
                                        :!this.props.isReunionOnlyStore ?
                                        <div className='bottomAddtoCart'>
                                          {this.state.totalItems > 0 && 
                                          <button
                                            className='red ui button'
                                            onClick={()=>this.addAssortmentToCart(currentRaqty)}
                                            disabled = {isNoProducts}
                                            style={{
                                              display:
                                                this.props.isCtbAssortment &&
                                                this.state
                                                  .isCtbAssortmentRestrictCart
                                                  ? "none"
                                                  : "inherit",
                                            }}
                                          >
                                            Add to Cart
                                          </button>}
                                          </div>
                                          : <>
                                            <button className='red ui button' disabled>Add to Cart</button>
                                            <p class="disabledMessage">*Ordering restricted</p>
                                            </>
                                          }
                                      </>
                                    )}
                                  </>
                                )}
                            </>
                          )}
                        </Grid.Column>
                      </Grid>
                    </Segment>
                    {selectedProductsDiv}
                    <div
                      id='snackbar'
                      className={classnames({
                        red: toastError,
                        show: toastMessage
                      })}
                    >
                      {toastMessage}
                    </div>
                  </>
                )}
            </>
          )}
      </div>
    );
  }
}

Categories.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.number.isRequired,
  categories: PropTypes.arrayOf(categoryPropType).isRequired,
  hasMore: PropTypes.bool.isRequired,
  searchVisible: PropTypes.bool.isRequired,
  closeSearch: PropTypes.func.isRequired,
  viewType: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  const { cart } = state;
  let currentCartId = state?.cart?.orders?.currentCart?.cartId ? state.cart.orders.currentCart.cartId : 0;
  const viewTypeStorage = localStorage.getItem('viewType');
  let currentCart = state?.cart?.orders?.allCarts && state.cart.orders.allCarts.find(cart => parseInt(cart.cartId) === parseInt(currentCartId));
  return {
    storeId: state.SessionReducer.storeId,
    userName: state?.SessionReducer?.address?.userName,
    affiliate: state?.SessionReducer?.address?.affiliate,
    primaryWarehouseNum: state?.SessionReducer?.address?.primaryWarehouseNum,
    loading: getCategoriesFetching(state.categories),
    categories: getCategories(state.categories),
    hasMore: getCategoriesHasMore(state.categories),
    searchVisible: isSearchVisible(state.navbar),
    favorites: state.FavoritesReducer.favItemNums,
    favVendors: state.FavoritesReducer.favVendors,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    currentCartId: currentCartId,
    multiStoreCartId : currentCart?.parentId,
    carts: state.cart.orders.allCarts,
    sectionPages: state.DomReducer.sectionPages,
    userId: state.SessionReducer.UserId,
    rdc: state.SessionReducer.rdc,
    getprefer: state.preference.listData,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    groupUrlId: state.SessionReducer.address ? state.SessionReducer.address.urlId : '',
    aliasSKURetailerGrpID:state.SessionReducer.address ? state.SessionReducer.address.aliasSKURetailerGrpID : '',
    easyAskDic:state.SessionReducer.address ? state.SessionReducer.address.easyAskDic : '',
    marketFlag: cart?.orders?.marketFlagEnabled,
    RaStore:state.RaReducer,
    PlanogramStore: state.PlanogramReducer,
    isReunionOnlyStore: state.SessionReducer.address.isReunionOnlyStore,
    qtyUpdate: state.cart.orders?.updateQty,
    directShipCartDetails: state.cart.orders.directShipCartDetails,
    dsCarts: state.cart?.orders?.allCarts && state.cart?.orders?.allCarts.length > 0 && state.cart.orders.allCarts.filter(cart => cart.cartInfoName === 'DS'),
  }
}

function mapDispatchToProps(dispatch) {
  return Object.assign(
    { dispatch },
    bindActionCreators(
      {
        fetchCategories,
        closeSearch,
        addToFavorites,
        removeFromFavorites,
        addVendorToFavorites,
        getAllFavoriteVendors,
        removeVendorFromFavorites,
        getFavoriteItems,
        createNAddMany,
        addProduct,
        createNAddCart,
        createDirectNAddCart,
        classicCreateNAddCart,
        addManyProducts,
        chooseCart,
        addItemstoCartPlanogram,
        addReunionCartProduct,
        addAssortmentProduct,
        addMonthlySpecialCart,
        classicAddProduct,
        updateQty,
        addDirectShipProduct,
        classicDirectShipAddToCart,
        updateDirectShipCartDetails
      },
      dispatch
    )
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Categories);