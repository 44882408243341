import React from 'react';
import { getTableRow, getSelect, exportExcel, getTable } from '../../common/ComponentUtilities';
import BreadcrumbComp from '../../../components/BreadcrumbComp';
import utilities from '../../../utils/utilities';
import search_img from '../../images/Search.svg';
import _ from 'lodash';
import renderImages from '../../common/RenderImages';
import SAM_Icon from '../../AllOrders/SAM_Icon.gif';
import { getSortByDirection,getPagination,showItemCount } from '../../common/CustomComponents';
import Filter from '../../common/Filter';
import restUtils from '../../../utils/restUtils';
import InternalServerError from '../../ErrorPages/500_error_page';
import { PROMOSHIP_FETCHORDERS } from '../../../urlConstants';
import ExcelExport from '../../common/ExcelExport';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loader,Responsive } from 'semantic-ui-react';
import { imagePromo,imageColor} from '../PromoShip/promoconstants';

class PromoShip extends React.Component{
    constructor(props){
        super(props);
        this.state={
            ordersList:[],
            filters: [],
            clearFilters: false,
            isLoading : false,
            serverError: false,
            appliedFilters: {},
            searchNumber : '',
            searchby : 'itemNbr',
            showFilters: utilities.getShowFilters(props.getprefer),
        }
        this.filterString = {};
        this.searchby = 'itemNbr';
        this.searchvalue = '';
        this.sortParam = 'orderDate';
        this.sortDirection = 'desc';
        this.pathname = '';
        this.page = 1;
        this.pageSize = utilities.getResultperPage(props.getprefer);
        this.scrollPosition = 0;
        
    }
    componentDidMount(){
      let queryParam = window.location.href.split('?');
      this.pageSize = utilities.getResultperPage(this.props.getprefer);
      this.pathname = this.props?.location?.pathname;
      if(queryParam[1]) {
        this.getUrlFilters(queryParam);
      }
      else{
        this.fetchPromoShipOrders();
      }
    }

    search = (e) =>{
        if(e.target.name==='search-icon' || e.keyCode===13){ 
            this.page = 0;
             this.fetchPromoShipOrders();
        }
    }

    toggleShowFilter = () => {
        this.setState(prevState => {
          return { showFilters: !prevState.showFilters };
        });
      };

      getUrlFilters = (queryParam) => {
        let getParams = queryParam[1].split('&');
        this.page = getParams[0].split('=')[1];
        this.pageSize =  getParams[1].split('=')[1];
        let searchParam = queryParam[1].includes('searchBy') ? queryParam[1].split('searchBy=')[1] : '';
        if(searchParam !== ''){
          let search = searchParam.split('=');
          this.searchby = search[0];
          this.searchvalue = search[1].split('&')[0];
        }
        this.sortParam = queryParam[1].includes('&SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : '';
        this.sortDirection = queryParam[1].includes('SortType=asc') ? 'asc' : 
                              queryParam[1].includes('SortType=desc') ? 'desc' : '';    
        this.scrollPosition = queryParam[1].split('scrollPosition=')[1].split('&')[0];
        this.filterString = queryParam[1].includes('filterString') ? queryParam[1].split('filterString=')[1] : '';
        let appliedFilters = utilities.getFilterArrayDecode(this.filterString);
        let filterApplied = utilities.getFilterArray(this.filterString);
        this.filterString = utilities.getQueryJsonAssortment(filterApplied);
        this.setState({
          appliedFilters : appliedFilters,
          searchNumber : this.searchvalue,
          searchby : this.searchby
        },() => {
          this.fetchPromoShipOrders();
        });
      }

      setScrollPosition = () => {
        this.scrollPosition = window.pageYOffset;
        this.setBackButtonPath();
      }

      setBackButtonPath = () => {
        let pathName = ''; 
        pathName = utilities.isEmptyOrNullString(this.page) ? 'Page=1' : 'Page='+this.page;
        pathName = pathName + '&ResultsPerPage='+this.pageSize;
        if (this.searchvalue) {
          pathName = pathName + '&searchBy='+this.searchby+'='+this.searchvalue;
        }
        if (this.sortParam && this.sortParam.length > 0) {
          pathName = pathName + '&SortParam='+this.sortParam;
          if (this.sortDirection && this.sortDirection.length > 0) {
            pathName = pathName + '&SortType='+this.sortDirection;
          } else {
            pathName = pathName + '&SortType=asc';
          }
        }    
        pathName = pathName + '&scrollPosition='+this.scrollPosition;
        if (!_.isEmpty(this.filterString)) {
          let obj = utilities.getQueryStrFromJson(this.filterString);
          pathName = pathName + '&filterString='+ utilities.getQueryStrForFilterString(obj);
        }    
        let pushUrl = this.pathname +'?'+pathName;
        window.history.pushState(window.history.state, "", pushUrl);
      }

    filterCallback = (isAdd, filterKey, filterValue) => {
        this.scrollPosition = window.pageYOffset;
        let appliedFilters = this.state.appliedFilters;
        this.page = 1;
        let keyExists = appliedFilters.hasOwnProperty(filterKey);
        if (!keyExists && isAdd) {
          appliedFilters = { ...appliedFilters, [filterKey]: filterValue };
        } else if (keyExists) {
          let value = appliedFilters[filterKey].split(',');
          let index = value.indexOf(filterValue);
          if (index > -1) {
            value.splice(index, 1);
          } else {
            value.push(filterValue);
          }
          if (!value.length && keyExists) delete appliedFilters[filterKey];
          else appliedFilters[filterKey] = value.join(',');
        }
        this.filterString = utilities.getQueryJsonAssortment(appliedFilters);
        this.setState({
          appliedFilters,
          clearFilters: false
        },() => {
          this.fetchPromoShipOrders();
        });
      };

    fetchPromoShipOrders = () =>{
        let searchArr = {};
        if (this.searchvalue) {
          searchArr = {
            name: this.searchby ,
            valueList: this.searchvalue.split(',')
          };
        }
        const sortArr = { sortParam: this.sortParam, sortType: this.sortDirection };
        let queryJson = [];  
        let appliedFilters = this.state.appliedFilters;
        if(!_.isEmpty(appliedFilters)){      
          for (let property in appliedFilters) {
            if (appliedFilters.hasOwnProperty(property)) {
              let filter = {};
              filter.name = property;
              filter.valueList = appliedFilters[property].split(',');
              queryJson.push(filter);
            }
          }
        } 
        let payload = { "storeId": this.props.storeId, "userId": this.props.userId, searchBy: searchArr, sortBy: sortArr, 
        ...{ filterBy: queryJson },"pageNumber": this.page,aliasSKURetailerGrpID : this.props.aliasSKURetailerGrpID,
        "resultsPerPage": this.pageSize }; 
        this.setState({isLoading:true})
        restUtils
            .postData(PROMOSHIP_FETCHORDERS,payload)
            .then(response=>{
                const{data}=response; 
                const paginationPromo = data?.pagination;
                let filters =data?.filters;
                const promoShipordersList = data?.promoShipOrderList;
                const isNoPromoships = !promoShipordersList || promoShipordersList.length===0;
                this.setState({
                    promoShipordersList,
                    filters: filters,
                    isNoPromoships,
                    isLoading: false,
                    serverError:false,
                    firstItem: paginationPromo?.firstItem,
                    lastItem: paginationPromo?.lastItem,
                    currentPage:paginationPromo?.currentPage,
                    totalPages:paginationPromo?.totalPages, 
                    totalItems:paginationPromo?.totalItems, 
                     showFilters: utilities.getShowFilters(this.props.getprefer),
                })

              window.scrollTo(0,this.scrollPosition);
              this.setBackButtonPath();
            }).catch(err=>{
                this.setState({isLoading:false,serverError:false})
            })
    }
    changeSearchBy = (e) =>{
      this.scrollPosition = window.pageYOffset;
        this.searchby = e.target.value;
        this.setState({
          searchby: this.searchby,
          searchNumber :''
        });
    }

    changeSortParam=(e)=>{
      this.scrollPosition = window.pageYOffset;
       this.sortParam = e.currentTarget.value;
       this.fetchPromoShipOrders();
    }

    changeSearchValue = (e) =>{
      this.scrollPosition = window.pageYOffset;
      this.searchvalue = e.target.value;
      this.setState({
          searchNumber: this.searchvalue
        });
    }

    onOrderpagesize=e=>{
       this.scrollPosition = window.pageYOffset;
        this.pageSize = e.target.value;
        this.page = 1;
        this.fetchPromoShipOrders();
      }

      onPageChange = e => {
        let{totalPages,currentPage}=this.state;
        let queryPage = e.currentTarget.text;
        if (queryPage === 'Next') {
          queryPage =
          currentPage < totalPages
              ?  parseInt(currentPage) + 1
              : currentPage;
        }
        if (queryPage === 'Prev') {
          queryPage = currentPage === 1 ? 1 : parseInt(currentPage) - 1;
        }
        if (queryPage === '»') {
          queryPage = totalPages;
        }
        if (queryPage === '«') {
          queryPage = 1;
        }
        this.page = queryPage;
        this.scrollPosition = window.pageYOffset;
        window.scrollTo(0, 0);
        this.fetchPromoShipOrders();
      };

    searchItems = () => {
        this.page = 1;
        this.fetchPromoShipOrders();
      };

    changeSortDirection = e => {
        this.page = 1;
        this.sortDirection = e.currentTarget.value;
        this.scrollPosition = window.pageYOffset;
        this.fetchPromoShipOrders();
      };

    getPromoShipHeaders = () => {
        const tableHeader = [
          // {props:{className:'allOdrHeader', id:'no_border'}, text: 'SAM'},
          {props:{className:'allOdrHeader', id:'no_border'}, text: 'Order Created Date'},
          {props:{className:'allOdrHeader', id:'no_border'}, text: 'Invoice #'},
          {props:{className:'allOdrHeader', id:'no_border'}, text: 'PO #'},
          {props:{className:'allOdrHeader', id:'no_border'}, text: 'Promo #'},
          {props:{className:'allOdrHeader', id:'no_border'}, text: 'RDC #'},
          {props:{className:'allOdrHeader', id:'no_border'}, text: 'Expected Release Week'},
          {props:{className:'allOdrHeader', id:'title_width_logo'}, text: 'Status'}
        ];        
        return(getTableRow({},tableHeader,true))
    }
    
    getPromoShipColumns = (rowProps, promoShipOrderList) => {
      let promoShipColumns = promoShipOrderList.orderList.map((order, index) => {

        const shipDate = new Date(order.promoEndDateString);
        const currentDate = new Date();
        const oneWeekAgo = new Date(currentDate.setDate(currentDate.getDate() + 7))
        const isRecent = shipDate <= oneWeekAgo;
        const statusStyle = isRecent ? { color: 'red', fontWeight: 'bold' } : {};
    
        const tableDataCols = [
            // {columnProps:{ className: 't-center', id: 'field_width' }, columnText: index===0 ? renderImages.getImage({ src: SAM_Icon }) : ''},
            { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.invoiceDateString) },
            { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.invoiceNbr) },
            { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.poNbr) },
            { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.promoName ? order.promoName : order.relay_nbr) },
	          { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.rdcNbr) },
            { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.shipDateString) },
            { columnProps: { className: 't-center', id: 'field_width' }, columnText: 
            <>
           <span style={statusStyle}>{utilities.hyphenForNull(order.displayedStatus)}</span>
            <br/>
            <Link onClick={this.setScrollPosition} to={{ pathname: "/promoItemDetails", state: { orderDetails: order } }}>View Details</Link>
            </> }
        ];
        let promoClassname = (promoShipOrderList?.orderList?.length === index+1) ? '' : 'promoRowdisable';
        return (
            <>
            {getTableRow(rowProps, tableDataCols,null,promoClassname)}
            </>
          ) 
      });
      return promoShipColumns;
    }

    getSortByFields = ()=>{
        const selectProps = {className:'sort-criteria combo', onChange: this.changeSortParam, id: 'pl_dropdown', value : this.sortParam};
        const options = [
                            {props:{value:'orderDate', id: 'orderDate', className:'SelectOption'}, text: 'Order Created Date'},
                            {props:{value:'invoiceNbr', id: 'invoiceNbr', className:'SelectOption'}, text: 'Invoice #'},
                            {props:{value:'poNbr', id: 'poNbr', className:'SelectOption'}, text: 'PO #'},
                            {props:{value:'expectedReleaseWeek', id: 'expectedReleaseWeek', className:'SelectOption'}, text: 'Expected Release Week'},
                            {props:{value:'status', id: 'status', className:'SelectOption'}, text:'Status'}
                        ];
    
        return(      
            getSelect(selectProps, options)
        );
    }
    
    getSearchByFields = ()=>{
        const selectProps = {className:'sort-criteria combo', onChange: this.changeSearchBy, id: 'pl_dropdown', value: this.state.searchby};
        const options =[
            {props:{value:'itemNbr', id: 'itemNbr', className:'SelectOption'},text:'Item #'},
            {props:{value:'poNbr', id: 'poNbr', className:'SelectOption'}, text:'PO #'}
        ];

        return (
            getSelect(selectProps,options)
        );
    }

    optionsSection=()=>{
        const sortBy = this.getSortByFields();
        const searchBy = this.getSearchByFields();
        return (
            <div className='options-div'>
                <div className='ui icon input' id='search_div_address'>
                    <span className='vender-search-header'>Search </span>
                    { searchBy }
                    <input
                        type='text'
                        className='search-box_address'
                        onChange={this.changeSearchValue}
                        onKeyDown = {this.search}
                        value={this.state.searchNumber}
                    />
                    <span className = 'search_icon_address' name='search-icon' onClick={this.search} >
                        {renderImages.getImage({
                            src: search_img,
                            name: 'search-icon'
                        })}
                    </span>
                </div>
                <div className='right-justified'>
                    <div className = 'sortByCategories'>
                        <span className='promolistings'>
                        <span >Show</span>
                         {showItemCount(this.pageSize, this.onOrderpagesize)}              
                        </span>
                        <span>Sort By</span>
                        {   sortBy }
                        {
                            getSortByDirection(this.changeSortDirection,null, null, this.sortDirection)
                        }
                    </div>
                </div>
            </div>
        )
    }
 
    render(){
        const img_style = this.state.img_txt_to_display === 'Hide Images' ? 'block' : 'none';
        const { promoShipordersList, isLoading, isNoPromoships,serverError} = this.state;
        return (
          serverError ? <InternalServerError /> :
            <div className='page-wrap'>
                <div className='page-header'>Auto-Ship</div>
                {this.optionsSection()}
                <div className={window.innerWidth > 1024 ? "displayFlex allOrdersTable orderstatusMargin" : "allOrdersTableItem"}>
                <Filter
                clearFilters={this.state.clearFilters}
                filtersApplied={!_.isEmpty(this.state.appliedFilters)}
                appliedFilters={this.state.appliedFilters}
                toggleShowFilter={this.toggleShowFilter}
                showFilters={this.state.showFilters}
                filters={this.state.filters}
                callback={this.filterCallback}
                isNoProducts={this.state.isNoProducts}
                screenName={'promostatus'}
                loading={this.state.loading}
                />
                    { isLoading &&
                        <Loader active/>
                    }
                    {
                    isNoPromoships?
                        <div className='no_prdcts_display'>
                            <h1>No Auto-Ship Order Available!</h1>
                        </div>
                    :
                    <div className={window.innerWidth > 1024 ? 'promolist' : 'allOrdersTableItem'} id="allOrdersTable">
                       <Responsive minWidth='1025'> 
                        <div className="eighteen wide column promo-listing">
                            <div className='order-pagination pagination-padding'>
                                <span className='OrderTotalitem'>
                                    {this.state.firstItem}-{this.state.lastItem} of{' '} {this.state.totalItems} orders
                                </span>
                                <span className='paginationAllorders'>        
                                {this.state.totalPages > 0 && (     
                                    getPagination(0, parseInt(this.state.currentPage), parseInt(this.state.totalPages), this.onPageChange, null, 1)    
                                )}
                                </span>
                                <span className='padding_left'>
                                    <ExcelExport
                                        fileName='AutoShip'
                                        className='exportPDF exportPdfPLP'
                                        itemsData={this.state.promoShipordersList}
                                    />
                                </span>
                            </div>
                        <>
                        {
                        getTable({
                                class:"ui celled table all-wh-table", 
                                id:'font-size-orders'},
                                this.getPromoShipHeaders(), 
                                promoShipordersList, this.getPromoShipColumns, 
                                {className:'wh-row'})
                        }
                        </>
                        </div>
                        </Responsive>
                        <Responsive maxWidth='1024'>
                        <div className='promo-top'>
                            <div className='promopage-items'>
                                {this.state.firstItem}-{this.state.lastItem} of{' '} {this.state.totalItems} orders
                            </div>
                            <div className='promo-pagination'>        
                            {this.state.totalPages > 0 && (     
                                getPagination(0, parseInt(this.state.currentPage), parseInt(this.state.totalPages), this.onPageChange, null, 1)    
                            )}
                            </div>
                          </div>
                          {this.state?.promoShipordersList?.map((orders) => (
                            orders?.orderList?.map((order_details, index) => {
                              return <OrderCardPromoList 
                                order={order_details} 
                                loggedInUser={this.props.loggedInUser} 
                                index={index} 
                                samNumber={orders.samNumber}
                                getRaCategories={this.getRaCategories}
                                showLess={this.showLess}
                                showMore={this.showMore}
                                openTrackingSite={this.openTrackingSite}/>
                            })
                          ))}
                        </Responsive>
                    </div>
                    }                 
                </div>
            </div>
        )
    }
}

const OrderCardPromoList = ({ order}) => {
  const isDateWithinAWeek = (promoEndDateString) => {
    if (!promoEndDateString) return false;
    const shipDate = new Date(promoEndDateString);
    const currentDate = new Date();
    const oneWeekLater = new Date(currentDate.setDate(currentDate.getDate() + 7));
    return shipDate <= oneWeekLater;
  };
  const statusStyle = isDateWithinAWeek(order.promoEndDateString)
    ? { color: 'red', fontWeight: 'bold' }
    : {};

  return (
    <div className='all-orders-card'>
      <div className='floatRight view-details-link'>
      <Link to={{ pathname: "/promoItemDetails", state: { orderDetails: order } }}>View Details</Link></div>
      <table id='order-card-table'>
        {/* <tr>
          <td className='order-card-field padding'>SAM</td>
          <td>{renderImages.getImage({ src: SAM_Icon })}</td>
        </tr> */}
        <tr>
          <td className='order-card-field padding'>Order Created Date</td>
          <td>{utilities.hyphenForNull(order.invoiceDateString)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Invoice Date</td>
          <td>{utilities.hyphenForNull(order.invoiceDateString)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Invoice #</td>
          <td>{utilities.hyphenForNull(order.displayedInvoiceNumber)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>PO #</td>
          <td>
          { utilities.hyphenForNull(order.poNbr)}
          </td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Promo #</td>
          <td>{utilities.hyphenForZero(order.promoName ? order.promoName : order.relay_nbr)}</td>
         </tr>
         <tr>
           <td className='order-card-field padding'>RDC #</td>
	           <td>{utilities.hyphenForZero(order.rdcNbr)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Expected Release Week</td>
          <td>{utilities.hyphenForNull(order.shipDateString)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Status</td>
          <td> <span style={statusStyle}>{utilities.hyphenForNull(order.displayedStatus)}</span></td>
        </tr>
      </table>
    </div>
  )
}
const mapStateToProps = (state) =>{
    return {
        storeId : state?.SessionReducer?.storeId,
        userId : state?.SessionReducer?.UserId,
        aliasSKURetailerGrpID:state?.SessionReducer?.address ? state?.SessionReducer?.address?.aliasSKURetailerGrpID : '',
        getprefer: state?.preference?.listData,
    }
}
export default connect(mapStateToProps)(PromoShip);