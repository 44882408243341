import React, { Component } from 'react';
import PromoShip_Order from './PromoShip_Order.pdf';
import Edit_PromoShip from './Edit_PromoShip.pdf';
import Order_Reunion from './Order_Reunion.pdf';
import {custCareClosingDays, custCareLimitDays, MOLhelpdeskClosingDays} from '../../../../src/constants/productMenu';
import './styles.css'

class HelpandTutorials extends Component {
	render() {
		const pdfDetails = [
			{ file_path: PromoShip_Order, file_name: 'Promo-Ship Ordering Instructions.pdf', display_text: 'Promo-Ship Ordering Instructions (PDF)' },
			{ file_path: Edit_PromoShip, file_name: 'Editing Promo-Ship Orders.pdf', display_text: 'Editing Promo-Ship Orders (PDF)' },
			{ file_path: Order_Reunion, file_name: 'Order Reunion Items.pdf', display_text: 'How to Order Market Items (PDF)' }
		]
		return (
			<div className="page_layout" >
				<div className='page-header'>Help</div>
				<div className="contact_us">
					<p className="t-center header_text">Contact Us</p>
						<div className={window.innerWidth<768?'':'displayFlex'}>
							<div className="customer_support">
								<p className="header_text">Customer Care</p>
								<p className="text_width">
										Have a question about a product, program, how to place an order, warehouse invoice issues or claims, 
										or simply not sure who to turn to? Your Customer Care Team is ready to assist.<br/> 
										Email <u className="email_color"><a href="mailto: customercare@truevalue.com" className="vendorNum">customercare@truevalue.com</a></u><br/>
										or call <b> 800-621-6025 option 2.</b>
								</p>
									<span><b>Regular Hours of Operation</b><br/>Monday through Thursday 7:30 a.m. to 6 p.m. (CST)<br/>
									Friday 7:30 a.m. to 5 p.m. (CST)<br/>
									<br/>
									<b>Limited Support on:</b>
									{custCareLimitDays.map(day => {
										return <p className="no_margin">{day}</p>
									})}<br/><br/>
									<b>Closed on:</b></span><br/>

									{custCareClosingDays.map(day => {
									return <p className="no_margin">{day}</p>
									})}
							</div>
							<div className="help_desk">
								<p className="header_text">MOL Help Desk</p>
								<p>Have a technical issue?<br/>
									Email <u className="email_color"><a href="mailto: membersonlinehelp@truevalue.com" className="vendorNum">membersonlinehelp@truevalue.com</a></u><br/>
									or call <b>800-621-6025 option 3. </b> 
									The Help Desk supports Unity, RockSolid, truStart2, CipherLab and Membersonline.
								</p>
								<span>
									<b>Regular Hours of Operation</b>
									<br/>Monday through Friday: 8 a.m. to 11 p.m. (EST)
									<br/>Saturday: 8 a.m. to 7 p.m. (EST)
									<br/>Sunday: 9 a.m. to 7 p.m. (EST)
								</span>
								<div className="limited_support">
									<p>
										<b>Limited Support</b> 
										&nbsp;is available from 9 a.m. to 7 p.m. (EST) on the days listed below. Calls will be returned as soon as possible based 
										on their priority. If call volume is heavy, low priority calls may not be answered until the following day.
									</p>
									{MOLhelpdeskClosingDays.map(day => {
										return <p className="no_margin">{day}</p>
									})}
									<p style={{ paddingTop: '1rem' }}>
										<b>The Help Desk is closed on Thanksgiving Day and Christmas Day</b>. 
												All calls will be returned the following day.
									</p>
								</div>
							</div>
							</div>
					</div>

					{/* <div className="tutorials">
							<p className="t-center header_text">Tutorials and Instructions</p>
							{pdfDetails.map(item => {
									return <p className="instructions"><a href={item.file_path} download={item.file_name}>{item.display_text}</a></p>
							})}
					</div> */}
			</div>
		);
		}
}

export default HelpandTutorials;
