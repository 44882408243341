
export const orderNames = {
    "REGULAR":{displayName:"Warehouse Products",key:"WH",infoType:1},
    "PLANOGRAM":{displayName:"Planograms",key:"WH",infoType:5},
    "ImpulseMerchandising":{displayName:"Impulse Merchandising",key:"WH",infoType:7},
    "VM":{displayName:"Impulse Merchandising",key:"WH",infoType:7},
    "DOM":{displayName:"Retail Events", key:"WH",infoType:2 },
    "RETAIL_ASSORTMENT":{ displayName :"Retail Assortment" , key:"LR", infoType:4},
    "LR":{ displayName :"Retail Assortment" , key:"LR", infoType:4},
    "REUNION":{displayName:"Market Events", key:"EVENT", infoType:9},
    "ET":{displayName:"Market Events", key:"EVENT", infoType:9},
    "MONTHLY_SPECIALS": {displayName: "Monthly Specials", key:"MS", infoType: 10},
    "ITEMFLOW" : {key:"WH"},
    "PALLETFLOW" : {key:"WH"},
    "ASSORTMENTFLOW" : {key:"WH"},
    "DIRECTSHIP":{displayName:"DirectShip Products",key:"DS",infoType:33},
}

export const infoTypes = {
    1:"REGULAR",
    2:"DOM",
    4:"LR",
    5:"PLANOGRAM",
    7:"VM",
    9:"ET",
    10:"MS",
    33:"DS"
}

 
export const s2SShippingMethods=[
    {value:'TRK',text:'Add to my regular weekly warehouse delivery'},
    {value:'LTL',text:'Use common carrier'},
    {value:'MPK',text:'Will pick up at warehouse, Please contact the RDC to arrange pick-up time'},
    {value:'URG',text:'Ground (delivered within 3-5 business days)'}
];

export const s2CNonRetrofitShippingMethods=[
    {value:'URG',text:'Ground (delivered within 3-5 business days)'},
    {value:'LTL',text:'Use common carrier'},
     
];

export const emergencyShippingMethods=[
    {value:'ETR',text:'Emergency Truck'},
    {value:'EPK',text:'Emergency Pick-up at RDC'}  
]
/*export const s2cRetrofitLimitedQty = [
    {value:'USD',text:'2 Day'},
    {value:'URG',text:'Ground'} 
]*/

export const s2cRetrofitLimitedQty = [
    {value:'ECO',text:'Post Delivery'},
    {value:'LTL',text:'Common Carrier'}
]
export const s2CRetrofitShippingmethods=[
    {value:'USD',text:'2 Day'},
    {value:'URG',text:'Ground'},
    {value:'ECO',text:'Post Delivery'},
    {value:'LTL',text:'Common Carrier'}
];

export const monthlySpecialShippingmethods=[
    {value:'TRK',text:'Regular Delivery'}
]
export const CentralShipRDC = 41;

export const WH_ShoppingCartId = '01';

export const reunionEventInfoNames = [
    "ITEMFLOW",
    "ASSORTMENTFLOW",
    "PALLETFLOW",
    "VendorDropShip",
    "DropShip DC"
];

export const isShoppingAreaCart = (cartInforName) =>{
    return reunionEventInfoNames.indexOf(cartInforName)>=0;
}

export const shoppingAreaFlowTypes = {
    "ITEMFLOW" : 1,
    "ASSORTMENTFLOW":2,
    "PALLETFLOW":3,
    "VendorDropShip": 4,
    "DropShip DC": 5
}

export const shoppingAreaName = {
    1: "Bargain Burners",
    2: "Warehouse Discount City",
    3: "Impulse Alley",
    4: "Market Retail Assortments",
    5: "WOW",
    6: "Warehouse Department Events",
    7: "New Item Neighborhood",
    8: "New Item Neighborhood",
    9: "Vendor Drop Ship",
    10: "Drop Ship Discount City",
    12: "Show Specials End Caps"
}