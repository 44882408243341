import { utilities } from '../../utils/utilities';
import {getEventRedirectUrl} from '../../views/ReunionEvent/AllReunion/eventRedirectUtilities';
import {getRedirectUri} from '../../urlConstants'
import { PRODUCT_MENU } from "../../views/common/constants";
import _ from 'lodash';

export const fetchDepartmentData = (response, type)=> {
  if (response.data && response.data.length > 0) {
    const departmentOutput = response.data.map(department => {
      let showDepartmentLetter = true;
      const categoryLoop = (data, path ) => {
        const { Id, Name, Children } = data;
        const formatedName = encodeURIComponent(Name);
        let departmentData = {
          key: `${Id}$/${path}/${formatedName}`,
          nnwhKey: true,
          title: showDepartmentLetter ? utilities.titleCase(Name) + (isNaN(parseInt(Id)) ? ` (${Id})` :'') : utilities.titleCase(Name),
          isMenu: false,
          submenus:[],
          cms_page: 'No',
          new_window:'No',
          slug:'',
          url:'',
          id:''
        }
        showDepartmentLetter = false;
        if(Children && Children.length > 0){
          departmentData.isMenu = true
          const categoryObject = [{}]
          Children.forEach((category, index2) => {
            const subPath = departmentData.key.split('$/')[1];
            categoryObject[index2] = categoryLoop(category, subPath)
          })
          departmentData = {...departmentData, 'submenus': [...categoryObject] }
        }   
        return departmentData
      }    
      const formatedData = categoryLoop(department, type)
      return formatedData;  
    })
    return departmentOutput
  }
};

export const fetchWpMenuData = response=> {
  if (response && response.length > 0) {
    const departmentOutput = response.map(department => {
      const categoryLoop = (data ) => {
        const { url=null, title, submenus, cms_page, new_window, id } = data;
        const isNnwhPage = url.includes(getRedirectUri())
        let nnwhPageUrl = ''
        let targetUrl =''
        if(isNnwhPage) {
          const getPath = url.split(`${getRedirectUri()}/`)
          if(getPath.length > 1) {
            nnwhPageUrl = getPath[1]
          }
        }
        if(url) {
          targetUrl = cms_page === 'Yes' ? `cms/${id}`: isNnwhPage ? nnwhPageUrl : url
        }
        let departmentData = {
          key: `${"Id"}$/${targetUrl}`,
          title: utilities.titleCase(title),
          isMenu: false,
          isWpMenu: true
        }
        if(submenus && submenus.length > 0){
          departmentData.isMenu = true
          const categoryObject = {}
          submenus.forEach((category, index2) => {
            categoryObject[index2] = categoryLoop(category)
          })
          departmentData = {...departmentData, ...categoryObject }
        }   
        return departmentData
      }    
      const formatedData = categoryLoop(department)
      return formatedData;  
    })
    return departmentOutput
  }
};
export const processBussinessMenu = data =>{
  let menu = {}
  _.map(data,(ele=> {
      ele.Categories.map(cat=>{
        if(menu[cat]) {
          menu[cat] = {
            ...menu[cat],
            isMenu: true,
            title:cat,
            url: "/",
            cms_page: 'No',
            submenus:[...menu[cat].submenus, {
              key:cat,
              title:ele.Name,
              hasAccess: ele.HasAccess,
              new_window:"No",
              url: "/",
              cms_page: 'No',
              nnwhKey: false,
              isbusinessMenu: true,
            }]
          }
        }
        else {
          menu = {
            ...menu,
            [cat]: {
              name:cat,
              isMenu: true,
              title:cat,
              url: "/",
              cms_page: 'No',
              submenus:[{
                key:cat,
                title:ele.Name,
                hasAccess: ele.HasAccess,
                isbusinessMenu: true,
                new_window:"No",
                url: "/",
                cms_page: 'No',
                nnwhKey: false,
              }]
            }
          }
        }
      })
  }))
  return Object.values(menu)
}
export const fetchDepartmentDataFinelineCatalog = response => {
  let items = [];
  if (response.data && response.data.length > 0) {
    items = response.data.map(item => {
      const { id, name } = item;
      const data = {
        id: id,
        name: name,
        key: `${id}$/finelineCatalog/${name}`,
        value: utilities.titleCase(name),
      };
      return data;
    });
  }
  return items;
}

export const fetchCategoryDataFinelineCatalog = (response) => {
  let items = [];
  if (response.data && response.data.length > 0) {
    items = response.data.map(item => {
      const { id, name } = item;
      const data = {
        id: id,
        name: name,
        value: utilities.titleCase(name),
      };

      return data;
    });
  }

  return items;
}

export const fetchSubcategoryDataFinelineCatalog = (response) => {
  let items = [];
  if (response.data && response.data.length > 0) {
    items = response.data.map((item) => {
      const { id, name } = item;
      const data = {
        id: id,
        name: name,
        value: utilities.titleCase(name),
      };
      return data;
    });
  }
  return items;
}

export const getPathArray = info => {
  return info.map(key => {
    if (key.indexOf('$') !== -1) {
      return key.split('$')[0];
    }
    return key;
  });
};

export const getPathFromHistory = (history = []) => {
  let pathArray = getPathArray(history);
  let currentHistory = [...history];

  const { length } = pathArray;
  if (length > 3) {
    const lastNode = pathArray[length - 1];

    if (+lastNode) {
      let lastCNodeIndex = length - 2;
      const category = history[length - 1].split('/')[2];
      for (let i = length - 1; i >= 0; i--) {
        const categoryValue = history[i].split('/')[2];
        const nodeValue = pathArray[i];
        if (
          !+nodeValue &&
          nodeValue.length === 1 &&
          category === categoryValue
        ) {
          lastCNodeIndex = i;
          break;
        }
      }
      currentHistory = [
        ...history.slice(0, 2),
        history[lastCNodeIndex],
        history[length - 1]
      ];
      pathArray = [
        ...pathArray.slice(0, 2),
        pathArray[lastCNodeIndex],
        lastNode
      ];
    } else {
      currentHistory = [...history.slice(0, 2), history[length - 1]];
      pathArray = [...pathArray.slice(0, 2), lastNode];
    }
  }
  return { currentHistory, pathArray };
};

export const getNavItems = (navItemArray, subMenuItems, department) => {
  let navItems = [];
  navItemArray.forEach(itemName => {
    const isSubMenu = subMenuItems && subMenuItems[itemName];
    let deptName = {
      key: `${itemName}$/planograms${
        isSubMenu ? `/${itemName}` : `/${department}/${itemName}`
      }`,
      title: utilities.titleCase(itemName),
      isMenu: isSubMenu ? true : false,
      cms_page: 'No',
      new_window:'No',
      slug:'',
      url:'',
      id:'',
      nnwhKey: true,
      itemName
    };
    navItems.push(deptName)
  });
  return navItems;
};

export const getPlanogramsFlyout = data => {
  const { departmentNameList, categoryNameMap } = data;
  let planogramsFlyout = getNavItems(departmentNameList, categoryNameMap);
  planogramsFlyout = planogramsFlyout.map(prop=>{
    prop.submenus = getNavItems(categoryNameMap[prop.itemName], null, prop.itemName)
    return prop
  })
 /* for (const prop in planogramsFlyout) {
    planogramsFlyout[prop] = [{
      ...planogramsFlyout[prop],
      ...getNavItems(categoryNameMap[prop], null, prop)
    }];
  }*/
  return planogramsFlyout;
};

export const getNavItemsRetailAssortments = data => {
  let items = [];
  data.length && data.map(item => {
    let deptName = {
      key: `${item.departmentId}$/retailAssortments/${item.departmentName}`,
      title: utilities.titleCase(item.departmentName)+` (${item.departmentId})`,
      cms_page: 'No',
      new_window:'No',
      slug:'',
      url:'',
      id:'',
      nnwhKey: true,
    };
    items.push(deptName)
  });
  return items;
};
export const getNavItemsCtbAssortments = data => {
  let items = [];
    let ctbMenuALL = {
        key: `$/ctbAssortments/ALL`,
        title: "ALL",
        cms_page: 'No',
        new_window:'No',
        slug:'',
        url:'',
        id:'',
        nnwhKey: true,
    }
    data.length > 0  &&  items.push(ctbMenuALL);
     data.length && data.map(item => {
      let deptName = {
        key: `${item.departmentId}$/ctbAssortments/${item.departmentName}`,
        title: utilities.titleCase(item.departmentName)+` (${item.departmentId})`,
        cms_page: 'No',
        new_window:'No',
        slug:'',
        url:'',
        id:'',
        nnwhKey: true,
      };
      items.push(deptName)
    });
  return items;
};
export const getRetailAssortmentsFlyout = data => {
  let retailAssortmentsFlyout = getNavItemsRetailAssortments(data);
  /*for (const prop in retailAssortmentsFlyout) {
    retailAssortmentsFlyout[prop] = {
      ...retailAssortmentsFlyout[prop],
      ...getNavItemsRetailAssortments(retailAssortmentsFlyout[prop])
    };
  }*/
  return retailAssortmentsFlyout;
};

export const getShoppingAreaFlyout = data => {
  const { currentshoppingList } = data ; 
  const reunionFlyout = getNavItemsReunion(currentshoppingList);
  for (const prop in reunionFlyout) {
    reunionFlyout[prop] = {
      ...reunionFlyout[prop],
      ...getNavItemsReunion(reunionFlyout[prop])
    };
  }
  return reunionFlyout;
};

export const getNavItemsReunion = data => {
  let items = {};
    data.length && data.map(item => {
      const keyParams= {
        shoppingAreaId : item.shoppingId,
        shoppingAreaMetaId : item.shoppingMetatDataId,
        shoppingAreaName : item.shoppingName,
        pageName : "PromoListing"
      };
      items[item.shoppingId] = {
        key: getEventRedirectUrl({keyParams}),
        title: utilities.titleCase(item.shoppingName)
      };
      return item;
    });
  return items;
};
export const getCtbAssortmentsFlyout = data => {
  const retailAssortmentsFlyout = getNavItemsCtbAssortments(data);
  /*for (const prop in retailAssortmentsFlyout) {
    retailAssortmentsFlyout[prop] = {
      ...retailAssortmentsFlyout[prop],
      ...getNavItemsCtbAssortments(retailAssortmentsFlyout[prop])
    };
  }*/
  return retailAssortmentsFlyout;
};

export const updateNnwhProductMenu = data => {
  return data.map(menu => {
       if(menu.title === 'Products') {
             const updateDeptMenu = menu.submenus.map((item)=>{
              if(PRODUCT_MENU.includes(item.title)) {
                return {
                  ...item,
                  isNnwhMenu: true
                  
                }
              }
              return item
            })
            menu.submenus  = updateDeptMenu
            return menu
       }
       else if(menu.title === 'Business Tools') {
          menu.isNnwhMenu = true
          return menu
       }
       return menu
  })
}