import React from 'react'
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import utilities from '../../../utils/utilities';
export default class FavVendorItem extends React.Component {
    render() {
        return (
        <div className='VendorFavorites'>
            <span onClick={()=>this.props.removeFavVendor(this.props.vendor)}>
                <Icon color="black" name="heart"/>
            </span>
            <Link to={this.props.vendor?'/vendor/'+utilities.replaceAll(this.props.vendor.VendorName,'/','%2F') +'/'+this.props.vendor.VendorId :'#'}>
            <b className="anchor-blue">{this.props.vendor.VendorName} ({this.props.vendor.VendorId})</b>

            </Link>
         
        </div>
        )
    }
}