export const filters = {
    AsstmentLevel:{displayName:"Assortment Item",requestString:"AssortmentLevel"},
    coreItems:{displayName:"Core Items",requestString:"CoreItems"},
    DisconItems:{displayName:"Discontinued Items",requestString:"Discontinued"},
    DisItems:{displayName:"To be Discontinued",requestString:"TobeDiscon"},
    Greener:{displayName:"Greener Options",requestString:"GreenerOptions"},
    MadeInUSA:{displayName:"Made In USA",requestString:"MadeInUSA"},
    NewItems:{displayName:"New",requestString:"NewItems"},
    NonDis:{displayName:"Non Discontinued Items",requestString:"NonDiscon"},
    Class:{displayName:"Fine Line Class",requestString:"FineLineClass"},
    SubClass:{displayName:"Fine Line Sub Class",requestString:"FineLineSubClass"},
    "Vendor Name":{displayName:"Vendor Name",requestString:"Vendor"},
    DemandClass:{displayName:"Demand Class",requestString:"Demand"},
    Department:{displayName:"Fine Line Department",requestString:"FineLineDepartment"},
    Brand:{displayName:"Brand",requestString:"Brand"},
}

export const domFiltersSet =[
    {
        name:"Visibility Level",
        valueList:[
            'SH','LL','MC','OF','NS'
        ]
    }
]

export const MY_WAREHOUSE = 'my-warehouse';
export const ALL_WAREHOUSE = 'all-warehouse';

export const filterValues = {
    level1: {displayName:'MVP Assortment Item',value:1},
    level2: {displayName:'Assortment Item',value:2},
    level3: {displayName:'Assortment Item',value:3},
    level4: {displayName:'Expiring Assortment Item',value:4},
    coreVal: {displayName:"Core Items", value:1},
    "Non-Classified":{displayName:'Non-Classified',value:'N'},
    DisValue: {displayName:"Discontinued", value:1},
    DisVal: {displayName:"Discontinued", value:1},
    GreenerVal: {displayName:"Greener Options", value:1},
    MadeInUSAVal: {displayName:"Made In USA", value:1},
    NewItemsVal: {displayName:"New Items", value:1},
    NonDisVal: {displayName:"Non Discontinued", value:1},
    SH:{displayName:'Shown',value:'SH'},
    LL:{displayName:'Line listed',value:'LL'},
    MC:{displayName:'Mentioned in Copy',value:'MC'},
    OF:{displayName:'Order Form',value:'OF'},
    FE:{displayName:'Featured',value:'FE'},
    NS:{displayName:'Not Shown',value:'NS'}
}

export const orderFilters = {    
    OrderType:{displayName:"Order Type",requestString:"orderType"},
    OrderStatus:{displayName:"Order Status",requestString:"status"}    
}

export const orderFilterValues = {
    level1: {displayName:'MVP Assortment Item',value:1},
    level2: {displayName:'Assortment Item',value:2},
    level3: {displayName:'Assortment Item',value:3},
    level4: {displayName:'Expiring Assortment Item',value:4},
    coreVal: {displayName:"Core Val", value:1},
    Disvalue: {displayName:"Disvalue", value:1},
    Disval: {displayName:"Disval", value:1},
    Greenerval: {displayName:"Greenerval", value:1},
    Madeinusaval: {displayName:"Madeinusaval", value:1},
    Newitemsval: {displayName:"Newitemsval", value:1},
    Nondisval: {displayName:"Nondisval", value:1},
}

export const assortmentLevels= {
    1: 'MVP Assortment Item',
    2: 'Assortment Item',
    3: 'Assortment Item',
    4: 'Expiring Assortment Item'
  }

export const visibilityIndicators = {
    "HV": "Highly Visible Item",
    "LV": "Visible Item",
    "BLD": "Less Visible"
}

export const FLASH_SALES = 'FLASH SALE';

export const RETAIL_ASSORTMENT = "/retailAssortments/:deptName/";
export const CTB_ASSORTMENT_DEPT = "/ctbAssortments/:deptName/";
export const REUNION_ASSORTMENT = "/reunionAssortments/:deptName/";
export const CTB_ASSORTMENT = "/ctbAssortments"
export const MOMENTUM = "/momentum"
                     
export const finelineCatalogFiltersName = {
    warehouse: 'warehouse',
    memberCost: 'memberCost',
    suggestedRetail: 'suggestedRetail',
    pageSize: 'pageSize',
}

export const footerContent = 'This password protected site is for True Value Company Members only';  
export const footerYearContent = `@${(new Date).getFullYear()} True Value Company` ;

export const REUNION_APP = 'Market App';
export const REUNION_ORDERING = 'Market Ordering';
export const ADMIN = 'Admin';

export const PAGE_LIMIT = 4000;

export const reunionVendorPayload = {
    "sortBy": "num",
    "sortOrder": "asc",
    "page": "1",
    "resultPerPage": "5"
}

export const reunionShoppingMetaId = {
    11: 'Destination Deals'
  }

export const nonStockMessage = 'Item is not stocked in your RDC. Please review other RDCs to place an order.'

export const memberViewConstants ={
    MEMBER_COST:'Member Cost',
    SUGG_RETAIL:'Sugg. Retail',
    DS_COST:'DS Cost',
    VAR_RETAIL: 'Var. Retail',
    ORIG_COST :'Original Cost',
    CURR_COST: 'Current Cost',
    SAVING : 'Savings',
    CREDIT_AMOUNT : 'Credit Amount',
    REGULAR_COST :'Regular Cost',
    ORDER_PAD_COST :'Cost',
    MARKDOWN_CREDIT_AMOUNT:'Markdown Credit Amount',
    RESET_CREDIT_AMOUNT:'Reset Credit Amount',
    MEMBER_MISSING_COST:'Member Missing Cost',
    TOTAL_MEMBER_COST:"Total Member Cost",
    COST_MISSING_ITEMS:'Cost For Missing Items',
    COST_ALL_ITEMS:'Cost For All Items',
    MARKDOWN:'Markdown',
    FINAL_COST:"Final Cost After Markdown",    
    COST :'Cost',
    EXTENDED_COST: 'Ext. Cost',
    TOTAL_COST: 'Total Cost',
    TOTAL: 'Total',
    TOTAL_EXTENDED_COST: 'TOTAL EXTENDED COST',
    ORDER_TOTAL: 'Order Total',
    SUB_TOTAL: 'Sub Total',
    CREDIT_VALUE: 'Credit Value',
    EXTENDED_COST_ORDER_PAD: 'Extended Cost',
    PROMO_COST: 'Promo WHS Cost',
    PROMO_COST_REUION: 'Promo Cost',
    PROMO_RETAIL: 'Promo Retail',
    EVENT_COST: 'Event Cost'
} 
export const customerViewConstants ={
    MEMBER_COST:'MC',
    SUGG_RETAIL:'SR',
    DS_COST:'DS',
    VAR_RETAIL: 'VR',
    ORIG_COST : 'OC',
    CURR_COST :  'CC',
    SAVING : 'SV',
    CREDIT_AMOUNT : 'CR',
    ORDER_PAD_COST : 'CS',
    REGULAR_COST :'RC',
    MARKDOWN_CREDIT_AMOUNT:'MCR',
    RESET_CREDIT_AMOUNT:'RCR',
    MEMBER_MISSING_COST:'MMR',
    TOTAL_MEMBER_COST:"TC",
    COST_MISSING_ITEMS:'CIT',
    COST_ALL_ITEMS:'CIA',
    MARKDOWN:'MA',
    COST :'MC',
    FINAL_COST:'CFM',
    EXTENDED_COST: 'EC',
    TOTAL_COST: 'TC',
    TOTAL: 'TC',
    TOTAL_EXTENDED_COST: 'TC',
    ORDER_TOTAL: 'OT',
    SUB_TOTAL: 'ST',
    CREDIT_VALUE: 'CV',
    EXTENDED_COST_ORDER_PAD: 'EC',
    PROMO_COST: 'PC',
    PROMO_COST_REUION: 'PC',
    PROMO_RETAIL: 'PR',
    EVENT_COST: 'EVC'
}   
export const whsStockCode = {
    STATUS_DISCONTINUED: 'DIS',
    STATUS_MARKED_OUT: 'M/O',
    STATUS_NEW_ITEM: 'N/I',
    STATUS_OUT_OF_SEASON: 'O/S',
    STATUS_TEMP_OUT: 'T/O',
    STATUS_OUT_FOR_SEASON: 'OFS',
    STATUS_EXTENDED_OUT_OF_STOCK: 'EXS',
    STATUS_EMERGENCY_OUT_OF_STOCK: 'EMR',
    STATUS_END_OF_LIFE: 'EOL',
    STATUS_PROMO_IN_OUT: 'PIO'
}
export const keyMap = {
    SCROLL_TO_TOP : 'ctrl+home'
}
export const PRODUCT_MENU = ['Departments', 'Planograms', 'Retail Assortments', 'CTB Assortments']
export const REUNIONORDERING = '/reunionOrdering/';
export const EVENTS = '/events/';
export const EVENT_NAME = '/eventName/';
export const TRUE = 'true';
export const ASSORTMENTS = '/assortments/';
export const BUSINESS_TOOLS = 'Business Tools';

export const ASSORTMENT_UNAVAILABLE_TEXT = 'No Market Assortments to display! ';
export const NOT_ELIGIBLE_TEXT = 'Not Eligible for Credit, Previously Purchased ';
export const DETAIL_TEXT_ASSORTMENT = "*The credit and/or dating below will only be applied to the first assortment ordered per Retail Assortment Category. Credits will appear as a credit on a future statement.";
export const RESTRICTED_COUNTRY = "* The following item(s) are restricted in your county and/or state: ";
export const ASSORTMENT_ADJUSTMENT = "(Your assortment cost will adjust accordingly since you will not be receiving these item(s).) ";
export const ASSORTMENT_RESTRICTED = "This assortment is restricted. ";
export const ASSORTMENT_ALTERNATE = "is an alternate assortment. "
export const CURRENT = "current";
export const UPCOMING = "upcoming";
export const BIGDESKTOP = 2880;