import React from 'react'
import Categories from '../Categories'

export default class ReunionItemListing extends React.Component{
    render(){
        const { searchDetails } = this.props.location?this.props.location.state:null;
        return (
            <>
                <div className='page-wrap'>
                    <div className='page-header'>{"Market Search Results"}</div>
                    <Categories 
                        searchDetails={searchDetails} 
                        isSearch = {true}
                        className='margin-top' 
                        isReunionListing={true} />
                </div>
            </>
        )
    }
}