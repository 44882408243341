import React from 'react';
import { Segment, Loader, Responsive } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import restUtils from '../../utils/restUtils';
import { Modal } from 'semantic-ui-react';
import './WarehouseDetails.css';
import BreadcrumbComp from '../../components/BreadcrumbComp';
import { TierTable, TierTableNew } from '../common/TierTable';
import renderImages from '../common/RenderImages';
import InternalServerError from '../../views/ErrorPages/500_error_page';
import { MaskPrice } from '../common/MaskPrice';
import { addProduct,chooseCart, addManyProducts, createNAddMany,createNAddCart } from '../Cart/actions';
import { bindActionCreators } from 'redux';
import { CUSTOMER_VIEW, MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import { TierIcon } from '../common/TierIcon';
import MultistoreToCartModal from '../Cart/MultistoreToCartModal';
import { PRODUCT_DETAILS, ALL_WAREHOUSE_DETAILS } from '../../urlConstants';
import { getImageLink } from '../ReunionEvent/AllReunion/itemListingUtilities';
import history from '../../history';
import utilities, { shouldHideCostForPreference } from '../../utils/utilities';
import AddToCartModal from '../Cart/AddToCartModal';
import { WH_ShoppingCartId } from '../Cart/constants';
import Freight from '../Freight';

class AllWarehouses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      product: null,
      wareHouseList: [],
      isMultistoreModalOpen:false,
      serverError: false,
      isAddToCartModalOpen:false,
      isSingle:false,
      showEstimator: false
    };
  }

  componentDidMount() {
    this.getProductDetails(this.props.match.params.productId);
  }

  getProductDetails = itemId => {
    const productDetailsUrl =
      PRODUCT_DETAILS +
      itemId +
      '&storeId=' + this.props.storeId +
      '&rdc=' + this.props.rdc +
      '&grpCollectionId=' + this.props.groupCollectionId;
    let whUrl =
      ALL_WAREHOUSE_DETAILS +
      itemId +
      '&rdc=' + 
      this.props.rdc;
    if (!utilities.isEmptyOrNullString(this.props.currentCartId)) {
      whUrl = whUrl + '&cartid=' + this.props.currentCartId;
    }
    this.setState({ loading: true });
    let product = {};
    let wareHouseList = [];
    const productDetailsPromise = restUtils
      .getData(productDetailsUrl)
      .then(response => {
        product = response.data;
      })
      .catch(error => {
        this.setState({ loading: false, serverError: true });
      });
    const warehouseDetailsPromise = restUtils
      .getData(whUrl)
      .then(response => {
        wareHouseList = response.data.wareHouseList.map(rdc=>{
          return {...rdc,qty:rdc.cart_available};
        });
      })
      .catch(error => {
        console.log(error)
        this.setState({ loading: false, serverError: true });
      });
    Promise.all([productDetailsPromise, warehouseDetailsPromise]).then(() => {
      this.setState({
        product,
        wareHouseList,
        loading: false,
        serverError: false
      });
    });
  };

  addToCartSingle = (rdc,isCreateNew,modalCartName,modalCartId) => {
    const cartName = modalCartName?modalCartName:'WHCart'
    const cartId = modalCartId?modalCartId:this.props.currentCartId;
    if (!this.props.currentCartId || isCreateNew) {
      this.props.createNAddCart(
        this.state.product.Item_Number,
        rdc.qty,
        '',
        this.state.product.Srp_Cost,
        'REGULAR',
        WH_ShoppingCartId,
        cartName
      );
    } else {
      this.props.addProduct(
        cartId,
        this.state.product.Item_Number,
        rdc.qty,
        '',
        this.state.product.Srp_Cost,
        'REGULAR',
        '',
        rdc.RdcNumber
      );
      if(modalCartId){
        this.props.chooseCart(this.props.whCarts.find(cart=>parseInt(cart.cartId)===parseInt(modalCartId)));
      }
    }
    this.showToast('Product(s) added to cart!', false);
  };
  openCartModal = (rdc)=>{
    this.setState({
      isSingle:rdc,
      isAddToCartModalOpen: true
    });
  }

  multistoreHandleCarts= (product) => {
    this.setState({
      productSelectedForModal: product,
      isMultistoreModalOpen: true
    });
  };

  closeMultistoreModal=()=>{
    this.setState({ isMultistoreModalOpen: false });
  }

  handleAddToCart = (e,rdc) =>{
    const cartId = this.props.currentCartId;
    if(this.props.currentCartType !== 'REGULAR' &&
    this.props.whCarts.length > 0){
      this.openCartModal(rdc);
    }else if(!cartId || this.props.whCarts.length===0){
      this.addToCart(true, 'Default');
    }else if(rdc){
      this.addToCartSingle(rdc);
    }else{
      this.addToCart();
    }
  }
  addToCart = (isCreateNew,modalCartName,modalCartId) => {
    let rdcs = this.state.wareHouseList.filter(rdc => rdc.qty && rdc.qty !== '');
    let itemList = [];
    const cartId = modalCartId?modalCartId:this.props.currentCartId;
    const cartName = modalCartName?modalCartName:'WHCart'
    const storeRdcNum = this.props.multiStoreDetails?.childStoreDetails !== {} ?
      this.props.multiStoreDetails.childStoreDetails[this.props.storeId] : '';

    if (this.validateRdcs(rdcs)) {
      itemList = rdcs.map(rdc => {
        return {
          CartId: cartId,
          ItemNumber: this.state.product.Item_Number,
          ItemQty: rdc.qty,
          OverrideRetail: '',
          RDC: rdc.RdcNumber,
          ItemSource: 'WH',
          TotalQtyInOrder: rdc.qty,
          userName:this.props.userName,
          primaryWarehouseNum: storeRdcNum && storeRdcNum.primaryWarehouseNum ? storeRdcNum.primaryWarehouseNum : '',
          storeId:this.props.storeId
        };
      });
      if(!isCreateNew && cartId) {
        this.props.addManyProducts(
          cartId,
          this.state.product.Srp_Cost,
          itemList
        );
      }else{
        this.props.createNAddMany(
          Array(itemList.length).fill(this.state.product.Srp_Cost),
          itemList,
          WH_ShoppingCartId,
          cartName
        )
      }

      if(this.props.location && this.props.location.state && this.props.location.state.fromPLP){
        history.goBack();
      }
    }
  };
  
  validateRdcs = rdcs => {
    let isValid = true;
    const{product} = this.state;
    if (rdcs.length === 0) {
      this.showToast('Please add quantity in atleast one row!', true);
      isValid = false;
    }
    rdcs.forEach(rdc => {
      if(product && (product.S_Pack_Type === 'F' && rdc.qty % product.S_Pack_Qty)) {
        this.showToast(`Quantity should be multiple of ${product && product.S_Pack_Qty}!`);
        isValid = false;
      }
      if(product.maxQty && rdc.qty > product.maxQty) {
        this.showToast(`Maximum Order quantity is ${product.maxQty}!`);
        isValid = false;
      }  
      if (rdc.qty > rdc.quantityAvailable) {
        this.showToast(
          'Please enter a quantity not more than the quantity available!',
          true
        );
        isValid = false;
      } else if (rdc.qty < 1) {
        this.showToast('Please enter a valid quantity !', true);
        isValid = false;
      }
      if (rdc.qty === 777 || rdc.qty === 999) {
        this.showToast('Quantity should not be ' + rdc.qty+'!',true);
        isValid = false;
      }
    });
    return isValid;
  };

  showToast = (toastText, isError) => {
    var x = document.getElementById('snackbar');
    if (x) {
      x.innerHTML = toastText;
      x.className = isError ? 'red show' : 'show';
      setTimeout(function() {
        x.className = isError
          ? x.className.replace('red show', '')
          : x.className.replace('show', '');
      }, 5000);
    }
  };

  quantityValidator = quantity => {
    if (quantity !== '') {
      const regularExpression = /^\d+$/;
      if (!regularExpression.test(quantity)) {
        return true;
      }
      const quantityValue = parseFloat(quantity);
      if (quantityValue <= 0) {
        utilities.showToast(`Quantity should be positive number!`,false);
        return true;
      }
  
      if (quantityValue > 9999) {
        utilities.showToast(`Quantity should not exceed 9999!`,false);
        return false;
      }
      if (quantityValue === 777 || quantityValue === 999) {
        utilities.showToast("Quantity should not be " + quantityValue+"!",false);
        return true;
      }
    }
  
    return true;
  };


  onQtyChange = (e, index) => {
    const { value } = e.target;
    if (!/^(\d+)$|^$/.test(value)) {
      return false;
    }
    const { wareHouseList } = this.state;
    const fieldValue = parseFloat(value);
    if (!this.quantityValidator(value)) {
      return false;
    }
    wareHouseList[index].qty = fieldValue || '';
    wareHouseList[index].cart_available = fieldValue || '';
    this.setState({ wareHouseList: [...wareHouseList] });
  };
  closeCartModal = (e,actionRequired,isCreateNew,modalCartId,modalCartName) => {
    if(actionRequired?.closeIcon){
      this.setState({isAddToCartModalOpen:false});
      return;
    }
    this.setState({isCreateNew,modalCartId,modalCartName, isAddToCartModalOpen: false });
    if(this.state.isSingle){
      this.addToCartSingle(this.state.isSingle,isCreateNew,modalCartName,modalCartId);
    }else{
      this.addToCart(isCreateNew,modalCartName,modalCartId)
    }
  };
  openEstimatorModel = () =>{
    this.setState({showEstimator:true})
  }
  closeEstimator = () =>{
    this.setState({showEstimator:false})
  }
  render() {
    const isMobileView = window.innerWidth > 600 ? false : true;
    const img2 =
      'https://images.truevalue.com/getimage.asp?id=' +
      this.props.match.params.productId +
      '&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2';
    const { product } = this.state;
    const pricingDetails = product && product.pricing ? product.pricing : {};
    let msCheck = utilities.msExist(this.props.multiStoreDetails); 
    const getpreferValue= this.props.getprefer && this.props.getprefer.customerView ? this.props.getprefer.customerView : 'c1';
    const displayMemberCostAndLbl =  shouldHideCostForPreference(this.props.viewType,getpreferValue);
    const memberCostView =  displayMemberCostAndLbl ?  (pricingDetails.tiered ? (this.props.viewType !== MEMBER_VIEW):  displayMemberCostAndLbl) : displayMemberCostAndLbl
    return (
      <>
        <AddToCartModal
            isAddtoCartModalopen={this.state.isAddToCartModalOpen}
            closeCartModal={this.closeCartModal}
            isSingle = {this.state.isSingle}
            isCheckAllWarehouse ={true}
          />
          <MultistoreToCartModal
          isMultistoreModalOpen={this.state.isMultistoreModalOpen}
          closeMultistoreModal={this.closeMultistoreModal}
          product={this.state.productSelectedForModal}
        />
        <Segment id='productDetailInfo'>
          {this.state.loading && (
            <div>
              <Loader active />
            </div>
          )}
          {this.state.serverError ? (
            <InternalServerError />
          ) : (
            <>
              {product && (
                <div
                  className='displayFlex ui one column grid'
                  style={{ paddingBottom: '0' }}>
                  <div className='sixteen wide column'>
                    <div className='page-header checkAllwarehouse'>
                      Check All Warehouses
                    </div>
                    <button class="ui red button" style={{float:"right",marginRight:'6rem'}} onClick={this.openEstimatorModel}>LTL Freight Estimator</button>
                    <div id='page_navigation'>
                      <BreadcrumbComp
                        path={[
                          { link: '/home', text: 'HOME' },
                          {
                            link: '/categories/' + product.deptName,
                            text: product.deptName
                          },
                          {
                            link:
                              '/categories/' +
                              product.deptName +
                              '/' +
                              product.className,
                            text: product.className
                          },
                          {
                            link:
                              '/categories/' +
                              product.deptName +
                              '/' +
                              product.className +
                              '/' +
                              product.subclassName,
                            text: product.subclassName
                          }
                        ]}
                      />
                    </div>
                  </div>
                  <Modal closeIcon
                   className='EstimatorPopup'
                   onClose = {this.closeEstimator}
                   closeOnDimmerClick={false}
                   open={this.state.showEstimator}>
                   <Modal.Content className=''>
                       <Freight />
                   </Modal.Content>
                 </Modal>
                  <div className='displayFlex ui sixteen wide grid'>
                    <div className='column' id='product_name_details'>
                      <span className='product-name'>
                        {product.Product_Title || product.shortdesc || product.Product_Name } &nbsp;&nbsp;
                      </span>
                      <Link
                        className='vendor-span'
                        to={{ pathname: product.Vendor_Name
                            ? '/vendor/' + encodeURIComponent(product.Vendor_Name)
                            : '#',state: { vendorId: product.Vendor_Id }}}
                      >
                        {product.Vendor_Name}
                      </Link>
                    </div>
                  </div>
                  <div className='displayFlex ui stackable sixteen column grid' id='itemDetailRow'>
                    <div className='row'>
                      <div className={
                          isMobileView
                            ? 'zero-padding wh-img-div'
                            : 'one wide column zero-padding wh-img-div'
                        }
                        id='whImg-div'>
                        <div id='image_style'>
                          {renderImages.getImage({
                            src: img2,
                            className: 'ui image'
                          })}
                        </div>
                      </div>
                      <div className={ isMobileView
                                        ? 'wh-First_level_details'
                                        : 'three wide column wh-First_level_details'
                                      } id='FirstLevelDetails'>
                        <span className='wh-card'>Item#:</span>
                        <span>{product.Item_Number}</span>
                        <br />
                        <span className='wh-card'>Model#:</span>
                        <span>{product.Model}</span>
                        <br />
                        <span className='wh-card'>Pack:</span>
                        <span>
                          {product.S_Pack_Type}
                          {product.S_Pack_Qty}
                        </span>
                        <br />
                        {memberCostView ?
                          (this.props.multiStoreDetails.access !== 1  || (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr)) &&
                          <>
                            <span className='wh-card'>{utilities.getLabelsByType(this.props.viewType,"MEMBER_COST")}:</span>
                            <span>
                              <MaskPrice
                                getprefer={this.props.getprefer}
                                viewType={this.props.viewType}
                                fieldVal={pricingDetails.Member_Cost}
                                field='Member Cost'
                              />
                            </span>
                            <br />
                          </>
                        :''}
                        <span className='wh-card'>Small Parcel:</span>
                        <span>{product.FedEx}</span>
                      </div>
                      <div className={ isMobileView
                                        ? 'wh-First_level_details dimensions-div'
                                        : 'three wide column wh-First_level_details'
                                      } id='FirstLevelDetails'>
                        <span className='wh-card'>Length:</span>
                        <span>{product.Length} IN</span>
                        <br />
                        <span className='wh-card'>Width:</span>
                        <span>{product.Width} IN</span>
                        <br />
                        <span className='wh-card'>Height:</span>
                        <span>{product.Height} IN</span>
                        <br />
                        <span className='wh-card'>Weight:</span>
                        <span>{product.weight} lbs</span>
                        <br />
                      </div>
                      <div className={ isMobileView ? 'wh-tier-table' : 'five wide column'}>
                        {this.props.viewType !== CUSTOMER_VIEW &&
                          pricingDetails && pricingDetails.tiered && this.props.multiStoreDetails.access !== 1  && (
                            <TierTableNew pricing={pricingDetails} />
                          )}
                      </div>
                      {product.alternateItem?.Number && product.alternateItem?.Number > 0 ?  (
                        <p className="alternativeItem">
                          <strong>{product.alternateItem.Label}</strong>
                          <Link class="altItemNo" to={{ pathname: '/product/' + product.alternateItem.Number}}>
                          {product.alternateItem.Number}
                          </Link>
                          <b className="altItemName">{product.alternateItem.Name}</b>
                          <span className='alternativeItemImg'>
                            {renderImages.getImage({
                                src: getImageLink(product.alternateItem.Number),
                                className: '',
                                alt: 'Image'
                            })}
                          </span>
                        </p>
                      ): null}
                    </div>
                    <div className='row zero-padding'>
                      <div className=' sixteen wide column d-center'>
                        <p className='Alert'>
                          Ordering from a non-servicing RDC will result in additional charges.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='ui center sixteen column grid zero-margin'>
                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                      <>
                        <div>
                          <div className='addCart'>
                            <button
                              className='red ui button pl-item-list-addtocart-btn'
                              id='warehouse_cart_btn'
                              onClick={this.handleAddToCart}>
                              Add to Cart
                            </button>
                          </div>
                        </div>
                        <div className='sixteen wide column' id='warehouseTable'>
                          <table className='ui celled table all-wh-table'>
                            <thead>
                              <tr>
                                <th className='wh-header '>RDC #</th>
                                <th className='wh-header t-left'>RDC Location</th>
                                <th className='wh-header '>Qty Available</th>
                                <th className='wh-header'>Max. Qty</th>
                                <th className='wh-header'>Quantity</th>
                                <th className ='wh-header'>Small Parcel</th>
                                <th className ='wh-header' style={{maxWidth: '3rem'}}>Stock Status</th>
                                <th className ='wh-header'>Ant. Rec. Date</th>
                                <th className='wh-header'>On Order to RDC</th>
                                <th className='wh-header'>ETA</th>
                                <th className='wh-header'></th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.wareHouseList.map((rdc, index) => {
                                let eta = rdc.etaWithQty && rdc.etaWithQty.split(';')
                                return (
                                  <tr
                                    id={'wh-' + rdc.RdcNumber}
                                    key={rdc.RdcNumber}
                                    className='wh-row'>
                                    <td className='t-center'>{rdc.RdcNumber}</td>
                                    <td className='t-left'>
                                      {rdc.City +
                                        '' +
                                        rdc.State +
                                        ',' +
                                        rdc.AddressLn1 +
                                        ' ' +
                                        rdc.AddressLn2 +
                                        ',' +
                                        rdc.Zip5 +
                                        (rdc.Zip4 ? '-' + rdc.Zip4 : '')}
                                        {rdc.rdcType === 'C' && (<span style={{fontSize: '0.7rem'}}> (Central Shipped from RDC {rdc.csRdc})</span>)}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                      {rdc.quantityAvailable}
                                    </td>
                                    <td style={{textAlign: 'center', marginTop: '0.1rem'}}>
                                      <span className={rdc.maxOrderQuantity>0 ? 'red_color': ''}>{rdc.maxOrderQuantity}</span>
                                    </td>
                                    <td>
                                      <input
                                        type='text'
                                        className='qty-box'
                                        id={rdc.RdcNumber}
                                        value={ utilities.isEmptyOrNullString(rdc.qty) ?'':rdc.qty}
                                        onChange={e => this.onQtyChange(e, index)}
                                      />
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                      <span>{rdc.shippableMessage}</span>
                                    </td>
                                    <td style={{ textAlign: 'center', maxWidth: '9rem' }}>
                                      <span>{rdc?.stockStatus}</span>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                      <span>{!utilities.isEmptyOrNullString(rdc.estimatedArrivalDate) ? rdc.estimatedArrivalDate : '-'}</span>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                      {rdc.whOnOrder ? rdc.whOnOrder : 0}
                                    </td>
                                    <td>
                                      {rdc.etaWithQty ? eta.map(ele=> !utilities.isEmptyOrNullString(ele) && <p className='etaQtyDate'>{ele}</p>) : '-'}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className='AddCartBottom'>
                          {this.props.multiStoreDetails.access !== 1  &&
                          <>
                           {msCheck && !(msCheck > 1) &&
                            <button
                              className ='red ui button pl-item-list-addtocart-btn'
                              onClick={this.handleAddToCart}>
                              Add to Cart
                            </button>
                            }
                          </>
                          }
                        </div>
                      </>
                    </Responsive>
                    <Responsive {...Responsive.onlyMobile}>
                      {this.state.wareHouseList.map((rdc, index) => {
                        return (
                          <RDCCard
                            key={rdc.RdcNumber}
                            rdc={rdc}
                            index={index}
                            onQtyChange={this.onQtyChange}
                            handleAddToCart = {this.handleAddToCart}
                            addToCartSingle={this.addToCartSingle}
                            access= {this.props.multiStoreDetails.access}>
                          </RDCCard>
                        );
                      })}
                    </Responsive>
                  </div>
                  <div id='snackbar'>Product added to cart</div>
                </div>
              )}
            </>
          )}
        </Segment>
      </>
    );
  }
}

const RDCCard = ({ rdc, index, onQtyChange,handleAddToCart, addToCartSingle, access }) => {
  return (
    <table className='rdc-card-table' id={'wh-table-' + rdc.RdcNumber}>
      <tbody>
        <tr>
          <td className='card-field'>RDC#</td>
          <td className='card-value'>{rdc.RdcNumber}</td>
        </tr>
        <tr>
          <td className='card-field'>RDC Location</td>
          <td className='card-value'>
            {rdc.City +
              '' +
              rdc.State +
              ',' +
              rdc.AddressLn1 +
              ' ' +
              rdc.AddressLn2 +
              ',' +
              rdc.Zip5 +
              (rdc.Zip4 ? '-' + rdc.Zip4 : '')}
          </td>
        </tr>
        <tr>
          <td className='card-field'>Qty Available</td>
          <td className='card-value'>{rdc.quantityAvailable}</td>
        </tr>
        <tr>
          <td className='card-field'>Max. Qty</td>
          <td className='card-value'><span className={rdc.maxOrderQuantity>0 ? 'red_color': ''}>{rdc.maxOrderQuantity}</span></td>
        </tr>
        <tr>
          <td className='card-field'>Quantity</td>
          <td className='card-value'>
            <input
              className='qty-box'
              value={isNaN(rdc.qty) || utilities.isEmptyOrNullString(rdc.qty) ?'':rdc.qty}
              onChange={e => onQtyChange(e, index)}
            />
          </td>
        </tr>
        <tr>
          <td className='card-field'>On Order to RDC</td>
          <td className='card-value'>0</td>
        </tr>
        <tr>
          <td className='card-field'>Small Parcel</td>
          <td className='card-value'>
            {rdc.shippableMessage}
          </td>
        </tr>
        <tr>
          <td className='card-field'>Stock Status</td>
          <td className='card-value'>
            {rdc?.stockStatus}
          </td>
        </tr>
        <tr>
          <td className='card-field'>Anticipated Recovery Date</td>
          <td className='card-value'>
            {!utilities.isEmptyOrNullString(rdc.estimatedArrivalDate) ? rdc.estimatedArrivalDate : '-'}
          </td>
        </tr>
        <tr>
          <td className='card-field'>ETA</td>
          <td className='card-value'>
            {rdc.etaWithQty ? rdc.etaWithQty : '-'}
          </td>
        </tr>
        <tr>
          <td className='card-field'></td>
          <td className='card-value'>
           {access !== 1 &&
            <button
              className='red ui button add-to-cart-btn'
              onClick={(e) => handleAddToCart(e,rdc)}>
              Add to Cart
            </button>
            }
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const mapStateToProps = state => {
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    currentCartId: state.cart.orders.currentCart.cartId,
    currentCartType: state.cart.orders.currentCart.cartInfoName,
    whCarts: state.cart.orders.allCarts.filter(cart => cart.cartInfoName === 'REGULAR'),
    storeId: state.SessionReducer.storeId,
    rdc : state.SessionReducer.address ? state.SessionReducer.address.rdc : '',
    userName:state.SessionReducer.userName,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
    getprefer: state.preference.listData,
    groupCollectionId: state.SessionReducer.address ? state.SessionReducer.address.groupCollectionId : ''
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators(
      { addProduct,chooseCart, addManyProducts, createNAddMany,createNAddCart },
      dispatch
    )
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AllWarehouses);
