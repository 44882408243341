import React, { Component } from "react";
import CartProductsTable from "./CartProductsTable";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PdfExport from "../common/PdfExport";
import { utilities, shouldHideCostForPreference, dsRetailerPolicyMessage } from "../../utils/utilities";
import { MaskPrice } from "../common/MaskPrice";
import { getCartInfo, notifyCarts } from "../../views/Cart/actions";
import { bindActionCreators } from "redux";
import ExcelExport from "../common/ExcelExport";
import ReactToPrint from "react-to-print";
import { Responsive } from "semantic-ui-react";
import { isShoppingAreaCart } from "./constants";
import { exportExcel } from "../common/ComponentUtilities";
import { getExcelExport } from "../ReunionEvent/AllReunion/viewCartFields";
import { reunionShoppingMetaId } from "../common/constants";
import { totalCost } from './cartUtils';
import FixtureTable from "../Fixtures/FixtureTable";
import LimitlessTable from "../Limitless/LimitlessTable";
import history from '../../history';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

class OrderConfirmation extends Component {
  constructor(props) {
    super(props);
    const cart = props?.history?.location?.state?.cart;
    const cartName = cart[0]?.cartName;
    const { flowType, fixtures, selectedFixtureIds,selectedLimitlessId,displayItemsLimitless, limitlessPOG } = props?.location?.state;
   
	  const freeFixtures = fixtures?.filter((f) => f.ctbFree) || [];
    this.state = {
      cartName,
      orders: cart,
      fixtures: fixtures || [],
      isFreeFixtures: freeFixtures.length > 0,
	    selectedFixtureIds: selectedFixtureIds || [],
      selectedLimitlessId : selectedLimitlessId || [],
      limitlessPlanogram: limitlessPOG || [],
      displayItemsLimitless : displayItemsLimitless || [],
      cartProducts:
        (this.props.history.location.state &&
          this.props.history.location.state.itemGrouping) ||
        [],
      isLoading: false,
      status: "Submitted",
      flowType,
      isMultiStore: this.props.history?.location?.state?.isMultiStore,
      getPrefer: this?.props?.getprefer?.customerView
        ? this.props.getprefer.customerView
        : "c1",
    };
  }

  componentDidMount() {
    this.props.getCartInfo();
    this.props.notifyCarts();
    let timesRun = 0;
    this.interval = setInterval(()=>{
      timesRun += 1;
      this.props.notifyCarts();
      if(timesRun === 4){
          clearInterval(this.interval);
      }
    }, 30000); 
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const {
      orders,
      status,
      cartProducts,
      cartName,
      getPrefer,
      isMultiStore,
    } = this.state;
    const itemGroupNames = Object.keys(cartProducts);
    const poNum =
      this.props.history.location.state &&
      this.props.history.location.state.poNum;
    const orderTotal =
      this.props.history.location.state &&
      this.props.history.location.state.orderTotal;
    const isDsCart = this.props?.location?.state?.isDsCart ? this.props?.location?.state?.isDsCart : null
    let storeIds = [];
    let shipOrderStatus = [];
    orders.forEach((order) => {
      storeIds.push(order.storeId);
    });
    const isReunionShoppingAreaCart = isShoppingAreaCart(
      orders[0].cartInfoName
    );
    const itemTotalOrder = orders.map(order=> totalCost(order))
    const  getconfirmPrefer = this.props.getprefer && this.props.getprefer.customerView ? this.props.getprefer.customerView
    : 'c1'
     const  hideTotalExtendCost = shouldHideCostForPreference(this.props.viewType,getconfirmPrefer);
    return (
      <div
        className="order-confirmation"
        ref={(node) => (this.componentRef = node)}
      >
        <div id="confirmation_header">
          <div className="info-text row">
            <span className="page-header">
              Order Confirmation
              {orders[0]?.TypeId === 33 ?
              <>
                {" "}
                - {poNum} - {orders[0].DisplayName}
              </> 
            : isMultiStore ? (
                <>
                  {" "}
                  - {cartName} - {orders[0].DisplayName}
                </>
              ) : (
                <>
                  {" "}
                  {cartName === "M4Success" ? "VISMERCH" : cartName}-
                  {orders[0].cartId}/{reunionShoppingMetaId[orders[0]?.shoppingAreaMetaId] ? reunionShoppingMetaId[orders[0]?.shoppingAreaMetaId] : orders[0].DisplayName}/{" "}
                  {poNum[orders[0].storeId]}{" "}
                </>
              )}
            </span>
            <span id="print_img" className='hideTab'>
               <ReactToPrint
                 pageStyle="@page{size:landscape;}"
                 trigger={() => <i className="print icon" id="printicon"></i>}
                 content={() => this.componentRef}
               />
             </span>
          </div>
          <div className="info-text row">
            {!isReunionShoppingAreaCart ? (
              <p className="warehousemsg">
                {this.state.cartName === 'CTBAssort' ?
                <span className="thank-u-span orderStsMsg"> Thank you for your CTB Order.
                 Please note your order will display in Order Status by next day. 
                  To obtain a list of your CTB orders placed, 
	                click on Orders &#62; Order Reports &#62; CTB NW Order Report.
                </span>
              : isDsCart  ? <span className="thank-u-span"> Thank you for placing your order! Please review your order in Order Status Page. Your order will be processed once the credit check is complete and status will be updated accordingly. </span>
              : <span className="thank-u-span">
              Thank you for placing your order! Please note if you submitted
              an event order (i.e. Market, Retail, Monthly), the order will
              not display on the order status page until the next day.
              </span>}
              </p>
            ) : this.state.flowType === 4 || this.state.flowType === 5 ? (
              <p className="ordreMsgissue">
                <span className="orderStsMsg">
                  Thank you for your Event Order.
                  Please note{" "}
                  <span className="dropshipitalics">
                    All Vendor Drop Ship and Drop Ship Discount City{" "}
                  </span>
                  orders{" "}
                  <span className="dropshipitalics"> will not</span> display in
                  the order status page. 
                  <br />
                  It is recommended to download a PDF
                  confirmation to save for your records before leaving this
                  page. Contact Customer Care if you have questions.
                </span>
              </p>
            ) : (
              <span className="orderStsMsg">
                Thank you for your Event Order. Please note your order will not
                display on the order status page until tomorrow.
              </span>
            )}
            <br />
            {!isDsCart && (
              <>
            <span className="disclaimer-span">
              (*Please Note: Due to rules and regulations, submission of an
              order does not guarantee that items will be delivered)
            </span>
            <br />
            <span className={this.state.cartName !== 'CTBAssort'?"orderStsMsg":''}>
              REMINDER: Prices Are Subject to Change.
            </span>
            <br />
            {(this.state.flowType !== 4 && this.state.flowType !== 5 && this.state.cartName !== 'CTBAssort') && <span className="orderStsMsg">
              REMINDER: Please validate that there are no item errors in your cart for the order you just submitted.
            </span>}
            <br />
            </>
            )}
            {/* {isMultiStore && ( */}  
            {/* )} */}            
            <span id="pdf_img">
              <span class="back-to-cart-desktop"> 
                <Link to="/cart" className="back-to-cart">
                  Back to Cart
                </Link>
              </span>
              {!utilities.isMobileTabDevice() ? ( isReunionShoppingAreaCart ? (
                exportExcel({
                  className: "exportPDF exportPdfOrderConfirm",
                  onClick: () =>
                    getExcelExport(
                      orders,
                      getPrefer,
                      poNum,
                      this.props.viewType,
                      this.props?.aliasSKURetailerGrpID,
                      status
                    ),
                })
              ) : (
                <ExcelExport
                  className="exportPDF exportPdfOrderConfirm"
                  fileName={"orderConfirmations"}
                  viewType={this.props.viewType}
                  status={status}
                  order={orders}
                  poNum={poNum}
                  RAMarketFlag={this.props.RAMarketFlag}
                  RAMarketDate={this.props.RAMarketDate}
                  aliasSKURetailerGrpID={this.props?.aliasSKURetailerGrpID}
                  getprefer={getPrefer}
                  cartProducts={cartProducts}
                  isExportFixtures={
                    this.state.cartName === 'CTBAssort' &&
                    (this.state.isFreeFixtures ||
                      this.state.selectedFixtureIds.length > 0)
                  }
                  fixtures={this.state.fixtures}
                  selectedFixtureIds={this.state.selectedFixtureIds}
                  isExportLimitless={
                    this.state.cartName === 'CTBAssort' &&
                    this.state.selectedLimitlessId.length > 0
                  }
                  limitlessPlanogram={this.state.limitlessPlanogram}
                  selectedLimitlessId={this.state.selectedLimitlessId}
                  isDisplayItemLimitless = {this.state.displayItemsLimitless}
                  dsRetailerPo = {this.state?.orders.length > 0 && this.state?.orders[0].TypeId === 33 ? this.props.location.state?.retailerPo : null}
                  shipTo ={this.props?.location?.state?.address ? this.props?.location?.state?.address : null}

                />
              )) : ""}
              <PdfExport
                isRA={orders[0].TypeId !== 4}
                shipByOrder={shipOrderStatus}
                storeIds={storeIds}
                flowType={this.state.flowType}
                order={orders}
                poNum={poNum}
                tableId={"cart-products"}
                orderTotal={orders[0].TypeId === 33 ? orders[0].totalCost :itemTotalOrder}
                shipTo={this.props?.location?.state?.address ? this.props?.location?.state?.address : null}
                dsRetailerPo = {this.state?.orders.length > 0 && this.state?.orders[0].TypeId === 33 ? this.props.location.state?.retailerPo : null}
                viewType={this.props.viewType}
                getprefer={
                  this.props.getprefer && this.props.getprefer.customerView
                    ? this.props.getprefer.customerView
                    : "c1"
                }
                cartProducts={cartProducts}
              />
            </span>
          </div>
        </div>
        {orders.map((order, index) => (
          <>
            {isMultiStore && (
              <>
                <br />
                <div className="cart-accordion accordion-header t-center">
                  <span>
                    {"Store: "}
                    {utilities.getChildStoreDetails(
                      this.props?.multiStoreDetails,
                      order.userId
                    )}
                  </span>
                </div>
              </>
            )}
            <Responsive minWidth={768}>
              <div className={order.TypeId === 33 ? "order-summary ds_summary": "order-summary"}>
                <span className="order-field">Store #:</span>
                <span className="order-field">{order.storeId}</span>
                {order && order.TypeId !== 4 && this.state.flowType !== 2 && order.TypeId !== 33 && (
                  <>
                    <span className="order-field">PO #:</span>
                    <span className="order-field">{poNum[order.storeId]}</span>
                  </>
                )}
                {order && order.TypeId === 33 && (
                   <>
                   <span className="order-field">PO #:</span>
                   <span className="order-field">{poNum}</span>
                 </>
                )}
                  { hideTotalExtendCost ?
                <>
                <span className="order-field">{utilities.getLabelsByType(this.props.viewType,"ORDER_TOTAL", getconfirmPrefer)}:</span>
                <span className="order-field cost_red_color">
                  {parseInt(totalCost(order)) ===0 ? '-':
                  <MaskPrice
                    getprefer={
                      this.props.getprefer && this.props.getprefer.customerView
                        ? this.props.getprefer.customerView
                        : "c1"
                    }
                    field="cart-totals"
                    viewType={this.props.viewType}
                    fieldVal={order.TypeId === 33 ?  order.totalCost : totalCost(order)}
                  />}
                </span></> : "" }
                <br />
                <span className="order-field">Order Date:</span>
                <span className="order-field">
                  {utilities.getDateFromTimeStamp1()}
                </span>
                {order && order.TypeId === 33 && (
                   <>
                   <span className="order-field">Retailer Reference PO #:</span>
                   <span className="order-field">{this.props.location.state.retailerPo}</span>
                 </>
                )}
                <span className="order-field">Source:</span>
                <span>True Value Company</span>
                <br />
              </div>
            </Responsive>
            <Responsive maxWidth={767}>
              <div className={order.TypeId === 33 ? "order-summary ds_summary": "order-summary"}>
                <span className="order-field">Store #:</span>
                <span className="order-field">{order.storeId}</span>
                <br />
                {order && order.TypeId !== 4 && this.state.flowType !== 2 && order.TypeId !== 33 && (
                  <>
                    <span className="order-field">PO #:</span>
                    <span className="order-field">{poNum[order.storeId]}</span>
                  </>
                )}
                {order && order.TypeId === 33 && (
                   <>
                   <span className="order-field">PO #:</span>
                   <span className="order-field">{poNum}</span>
                   <br />
                 </>
                )}
                 {order && order.TypeId === 33 && (
                   <>
                   <span className="order-field">Retailer Reference PO #:</span>
                   <span className="order-field">{this.props.location.state.retailerPo}</span>
                 </>
                )}
                <br />
                { hideTotalExtendCost ?
                <>
                <span className="order-field">{utilities.getLabelsByType(this.props.viewType,"ORDER_TOTAL", getconfirmPrefer)}:</span>
                <span className="order-field cost_red_color">
                {parseInt(totalCost(order)) ===0 ? '-':
                  <MaskPrice
                    getprefer={
                      this.props.getprefer && this.props.getprefer.customerView
                        ? this.props.getprefer.customerView
                        : "c1"
                    }
                    field="cart-totals"
                    viewType={this.props.viewType}
                    fieldVal={order.TypeId === 33 ?  order.totalCost : totalCost(order)}
                  />}
                </span>
                </> : "" }

                <br />
                <span className="order-field">Order Date:</span>
                <span className="order-field">
                  {utilities.getDateFromTimeStamp1()}
                </span>
                <br />
                <span className="order-field">Source:</span>
                <span>True Value Company</span>
                <br />
              </div>
            </Responsive>
            {/* {!isMultiStore && order.items.length>0 && (
              <div className="confirmationbackToCart">
                <Link to="/cart" className="back-to-cart">
                  Back to Cart
                </Link>
              </div>
            )} */}
            {order.items.length>0 && 
            <div className="order-confirmation-table">
              {order.TypeId === 4 ||
              order.TypeId === 44 ||
              order.TypeId === 5 ||
              order.TypeId === 7 ||
              order.TypeId === 10 ||
              order.TypeId === 2 ||
              isReunionShoppingAreaCart ? (
                <CartProductsTable
                  cart={order}
                  products={order.items}
                  isConfirmation={true}
                  shipTo="Store"
                  status={status}
                />
              ) : order.TypeId === 33  ? 
               (
                <CartProductsTable
                  cart={order}
                  products={order.items}
                  isConfirmation={true}
                  shipTo={this.props?.location?.state?.address ? this.props?.location?.state?.address : null}
                  status={status}
                  shippingMethod={'DirectShip'}
                />
               )
              : (
                cartProducts &&
                Object.values(cartProducts[index]).map((order) => {
                  const products = order.final_group;
                  return (
                    <>
                      <CartProductsTable
                        cart={order}
                        products={products}
                        isConfirmation={true}
                        status={status}
                        shipTo={
                          order.shipTo === "Customer"
                            ? order.customerAddress &&
                              order.customerAddress.customerName
                            : "Store"
                        }
                        shippingMethod={order.shipByOrder}
                        subTotal={order.subTotal}
                      />
                    </>
                  );
                })
              )}
            </div>
          }
          </>
        ))}
        {this.state.cartName === "CTBAssort" &&
           (this.state.isFreeFixtures ||
             this.state.selectedFixtureIds.length > 0) && (
             <>
	               <br />
               <div style={{ fontWeight: 600 }}>Fixture Details</div>
               <FixtureTable
                 isReadOnly
                 fixtures={this.state.fixtures}
                 selectedFixtureIds={this.state.selectedFixtureIds}
               />
               <br />
             </>
           )}          
           {this.state.cartName === "CTBAssort" &&
           (this.state.selectedLimitlessId.length > 0) && (
             <>
	               <br />
               <div style={{ fontWeight: 600 }}>Display Items Details</div>
               <LimitlessTable
                 isReadOnly
                 limitlessPlanogram={this.state.limitlessPlanogram}
                 selectedLimitlessId={this.state.selectedLimitlessId}
                 isDisplayItemLimitless = {this.state.displayItemsLimitless}
               />
               <br />
             </>
           )}
           <div id='dsREetailerPolicyPdf' style={{visibility:'hidden', overflow:'hidden', height:'0'}}>
            {dsRetailerPolicyMessage()}
           </div>
        <div className="t-center disclaimer-span">
          If you have any questions, please contact customer service at
          800-621-6025
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const orders = state.cart.orders;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    getprefer: state.preference.listData,
    orders: state.cart.orderHistory,
    storeId: state.SessionReducer.storeId,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    RAMarketFlag: orders.raMarketFlag,
    RAMarketDate: orders.raEventDate,
    aliasSKURetailerGrpID: state.SessionReducer.address
      ? state.SessionReducer.address.aliasSKURetailerGrpID
      : "",
  };
};

const mapDispatchToProps = (dispatch) => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ getCartInfo,notifyCarts  }, dispatch)
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmation);
